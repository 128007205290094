import React from 'react';
import { ActionP0 } from 'helpers/functionTypes';

interface Props {
    icon?: string
    title?: string
    action: ActionP0
    className?: string
}

export default function Button ({ icon, title, action, className }: Props) {
    return (
        <button className={`btn shadow-sm ml-2 ${className || 'btn-primary'}`} onClick={action}>
            { icon ? <i className={`${icon}${!title ? ' align-middle' : ''}`}>&nbsp;</i> : <></> }
            { title }
        </button>
    );
}

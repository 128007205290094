import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import { client } from 'api';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { ServiceData } from 'models/ServiceData';
import { PricingLevelModel, ServiceLineModel } from 'api/api';
import { toLongMoney } from 'helpers/general';

export interface ExtendedService extends ServiceData {
    isSelected: boolean,
    isGlobal: boolean,
    isDisabled: boolean,
    price?: string
}

export interface AdditionalServicesSectionState {
    data: ExtendedService[]
}

const initialState: AdditionalServicesSectionState = {
    data: []
};

export const additionalServicesSectionSlice = createSlice({
    name: 'additionalServicesSection',
    initialState,
    reducers: {
        setServices: (state, action: PayloadAction<ExtendedService[]>) => {
            state.data = action.payload;
        },
        reset: state => {
            state.data = [];
        }
    }
});

export const initialize = (preselectedServices: ServiceLineModel[], priceLevel: PricingLevelModel | undefined, quantity: number): AppThunk => async (dispatch, getState) => {
    const actions = additionalServicesSectionSlice.actions;

    dispatch(clearError());
    dispatch(showLoading());

    try {
        const services = await client().service_Get();
        const serviceData: ExtendedService[] = services
            .filter(service => service.isAdditionalService)
            .map(service => {
                const current = preselectedServices.find(s => s.serviceId === service.id);
                return {
                    id: service.id || 0,
                    attachedServiceId: current?.id,
                    name: service.name || '',
                    description: current?.description || service.name || '',
                    sku: service.sku || '',
                    isSelected: !!(current),
                    isGlobal: service.isGlobalService || false,
                    isDisabled: current?.isAutomatic || false
                };
            });
        if (priceLevel) {
            for (let i = 0; i < serviceData.length; i++) {
                try {
                    const response = await client().service_GetPrice(serviceData[i].id, priceLevel.levelType, quantity);
                    if (response.priceNet) {
                        serviceData[i].price = '£' + toLongMoney(response.priceNet);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
        dispatch(actions.setServices(serviceData));
    } catch (error) {
        dispatch(showError(error));
    }

    dispatch(hideLoading());
};

const updateService = (index: number, serviceCallback: (original: ExtendedService) => ExtendedService): AppThunk =>
    async (dispatch, getState) => {
        const actions = additionalServicesSectionSlice.actions;

        const currentServices = getState().additionalServicesSection.data;
        const services = Object.assign([], currentServices, { [index]: serviceCallback(currentServices[index]) });
        dispatch(actions.setServices(services));
    };

export const selectService = (index: number): AppThunk => async (dispatch, getState) => {
    dispatch(updateService(index, s => ({ ...s, isSelected: true })));
};

export const unselectService = (index: number): AppThunk => async (dispatch, getState) => {
    dispatch(updateService(index, s => ({ ...s, isSelected: false })));
};

export const updateDesription = (index: number, description: string): AppThunk => async (dispatch, getState) => {
    dispatch(updateService(index, s => ({ ...s, description })));
};

export const { reset } = additionalServicesSectionSlice.actions;

export default additionalServicesSectionSlice.reducer;

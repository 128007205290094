import { ActionP0, ActionP2 } from 'helpers/functionTypes';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import TagPopup from './TagPopup';

interface Props {
    initialValue?: string | null
    initialTitle?: string | null
    onAccept: ActionP2<string, string>
    onDelete?: ActionP0 | null,
    buttonClassName?: string
    buttonIconClassName: string
}

export default function TagButton ({ initialValue = null, initialTitle = null, onAccept, onDelete = null, buttonClassName, buttonIconClassName }: Props) {
    return (
        <OverlayTrigger
            trigger={'click'}
            rootClose
            placement="bottom"
            overlay={
                <Popover id="new-tag">
                    <Popover.Content>
                        <TagPopup
                            initialTitle={initialTitle}
                            initialValue={initialValue}
                            onAccept={(title, value) => { onAccept(title, value); document.body.click(); }}
                            onDelete={!onDelete
                                ? null
                                : () => {
                                    if (onDelete) {
                                        onDelete();
                                    }
                                    document.body.click();
                                }} />
                    </Popover.Content>
                </Popover>
            }>

            <button className={buttonClassName}
                type="button">
                <i className={buttonIconClassName}></i>
            </button>
        </OverlayTrigger>
    );
}

import { ActionP0, ActionP1 } from 'helpers/functionTypes';
import { getJoiValidationSchema, getJoiResolver } from 'helpers/validation';
import Joi from 'joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
    initialContent: string
    completeTrigger: ActionP1<ActionP0>
    completeCallback: ActionP1<string>
}
const validateContent = (value: string, helpers: Joi.CustomHelpers) => {
    if (value.match(/{{.*}}/)) {
        throw new Error('Content should not contain any {{ }}');
    }

    return value;
};

const schema = getJoiValidationSchema({
    content: Joi.string().required().custom(validateContent, "Content shouldn't contain any '{{ }}'")
});

interface FormFields {
    content: string
}

export default function Content ({ initialContent, completeTrigger, completeCallback }: Props) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm<FormFields>({
        defaultValues: { content: initialContent },
        resolver: getJoiResolver(schema)
    });

    useEffect(() => {
        const onSubmit = (data: FormFields) => {
            completeCallback(data.content);
        };

        completeTrigger(() => {
            handleSubmit(onSubmit)();
        });
    });

    useEffect(() => {
        reset({ ...{ content: initialContent } });
    }, [initialContent]);

    return (
        <form>
            <div className="form-group">
                {/* <label className="form-label">How many do you need?</label> */}
                <textarea
                    className={`form-control mh-100 ${(errors.content) ? 'is-invalid' : ''}`}
                    rows={15}
                    {...register('content')}>
                </textarea>
                {errors.content && <div className="invalid-feedback">{errors.content.message}</div>}
            </div>
        </form>
    );
}

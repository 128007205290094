/* eslint-disable react/jsx-key */
import Card from 'components/elements/Card';
import { useEffect, useMemo } from 'react';
import Table, { TableProps } from 'components/elements/Table';
import Page from 'components/Page';
import { useAppDispatch, useAppSelector } from 'hooks';
import LoadingContainer from 'components/elements/LoadingContainer';
import { initialize, reset, openAccount } from './accountsListSlice';
import { TableData } from './models';
import Button from 'components/elements/page-actions/Button';
import { push } from 'connected-react-router';

export default function AccountsList () {
    const accounts = useAppSelector(state => state.accountsList.accounts);
    const dispatch = useAppDispatch();

    const accountNameRender = (data: any, type: any, row: any, meta: any): string => {
        return (type === 'display' && data && row.reseller)
            ? `${data} <span class="badge badge-primary ml-1 ml-1">Reseller</span>`
            : data;
    };
    const props = useMemo<TableProps>(() => ({
        onRowClicked: (data) => {
            dispatch(openAccount((data as TableData).id));
        },
        columns: [
            { title: 'Account', field: 'name', render: accountNameRender },
            { title: 'Contact', field: 'contact' }
        ]
    }), []);

    useEffect(() => {
        dispatch(initialize());
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <Page title="Accounts" actions={[
            <Button key="create_account" icon="fas fa-plus" title="Add Account"
                action={() => dispatch(push('/accounts/create'))} />
        ]}>
            <div className="row">
                <div className="col-12">
                    <Card title={null}>
                        <LoadingContainer>
                            <Table tableProps={props} data={accounts} />
                        </LoadingContainer>
                    </Card >
                </div>
            </div>
        </Page >);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'api';
import { AppThunk } from 'helpers/thunkActionTypes';
import Tag from 'models/Tag';
import { RenderRequestModel, FileResponse, PrintType, ProductModel, ProcessType } from 'api/api';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import ContentSize from 'models/ContentSize';
import { extractContentForPreview } from 'helpers/tagifyHelper';
import RectData from 'models/RectData';

export interface EditContentSectionState {
    tags: Tag[],
    product?: ProductModel | undefined
    preview?: FileResponse | undefined
    contentSize?: ContentSize | undefined,
    contentRect: RectData | undefined
    additionalItemsRect: RectData[]
}

interface EditContentInitialParams {
    product: ProductModel
    contentSize: ContentSize
    contentRect: RectData | undefined
    additionalItemsRect: RectData[]
}

const initialState: EditContentSectionState = {
    tags: [
        { value: 'firstName', title: 'John' },
        { value: 'lastName', title: 'Smith' },
        { value: 'company', title: 'Company' },
        { value: 'AddressLine1', title: '2 St Andrews Road' },
        { value: 'AddressLine2', title: 'Address line 2' },
        { value: 'City', title: 'Didcot' },
        { value: 'County', title: 'Oxon' },
        { value: 'PostCode', title: 'OX11 8EW' },
        { value: 'Country', title: 'United Kingdom' }
    ],
    contentRect: undefined,
    additionalItemsRect: []
};

export const editContentSectionSlice = createSlice({
    name: 'editContentSection',
    initialState,
    reducers: {
        initialize: (state, action: PayloadAction<EditContentInitialParams>) => {
            if (state.product?.sku !== action.payload.product.sku ||
                state.contentSize?.sizeId !== action.payload.contentSize.sizeId) {
                state.product = undefined;
            }
            state.contentRect = action.payload.contentRect;
            state.additionalItemsRect = action.payload.additionalItemsRect;

            state.product = action.payload.product;
            state.contentSize = action.payload.contentSize;
        },
        reset: (state) => {
            state.preview = undefined;
        },
        updateTags: (state, action: PayloadAction<Tag[]>) => {
            state.tags = action.payload;
        },
        updatePreview: (state, action: PayloadAction<FileResponse>) => {
            state.preview = action.payload;
        }
    }
});

const prepareForPreview = (content: string) => {
    return extractContentForPreview(content);
};

export const initialize = (initialParams: EditContentInitialParams, content: string | undefined): AppThunk => async (dispatch, getState) => {
    const actions = editContentSectionSlice.actions;
    
    await dispatch(actions.initialize(initialParams));
    if (content) {
        await dispatch(requestPreview(content));
    }
};

export const requestPreview = (content: string): AppThunk => async (dispatch, getState) => {
    const state = getState().editContentSection;
    const contentRect = state.contentRect;
    // const additionalItemsRect = state.additionalItemsRect;
    const size = state.contentSize!;
    const model: RenderRequestModel = {
        width: size.width,
        height: size.height,
        marginX: size.marginX,
        marginY: size.marginY,
        processType: ProcessType.PngConvert,
        areas: [{
            type: PrintType.Write,
            content: prepareForPreview(content),
            x: contentRect ? contentRect.x : size.marginX,
            y: contentRect ? contentRect.y : size.marginY,
            length: contentRect ? contentRect.width : size.width - (size.marginX * 2),
            height: contentRect ? contentRect.height : size.height - (size.marginY * 2)
        }, ...state.additionalItemsRect.map(item => {
            return {
                type: PrintType.Print,
                content: item.path,
                x: item.x,
                y: item.y,
                length: item.width,
                height: item.height
            };
        })]
    };

    dispatch(showLoading());
    dispatch(clearError());
    try {
        let preview : FileResponse | null = null;
        let error : Error | null = null;
        
        try {
            model.failIfNotFit = true;
            preview = await client().render_Post(model);
        } catch (e) {
            error = e;
            model.failIfNotFit = false;
            preview = await client().render_Post(model);
        }
        
        if (error) {
            dispatch(showError(error));
        }
        dispatch(editContentSectionSlice.actions.updatePreview(preview));
        dispatch(hideLoading());
    } catch (error) {
        dispatch(showError(error));
        dispatch(hideLoading());
    }
};

let previewTimer: any;

export const triggerDelayedPreview = (content: string): AppThunk => async (dispatch, getState) => {
    clearTimeout(previewTimer);
    previewTimer = setTimeout(() => dispatch(requestPreview(content)), 3000);
};

export const cancelDelayedPreview = (): AppThunk => async (dispatch, getState) => {
    clearTimeout(previewTimer);
};

// Action creators are generated for each case reducer function
export const { updateTags, reset } = editContentSectionSlice.actions;

export default editContentSectionSlice.reducer;

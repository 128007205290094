import { ActionP1 } from 'helpers/functionTypes';
import ContentSize from 'models/ContentSize';
import style from './StationeryChoice.module.css';

interface Props { 
    onClick: ActionP1<ContentSize>, 
    variant: ContentSize,
    baseSize: number, 
    children: JSX.Element }

export default function ScaledPage ({ onClick, variant, baseSize, children }: Props) {
    return (
        <div className={style.pageWrapper}
            onClick={() => { onClick(variant); }}
            style={{
                width: `${(variant.width / baseSize) * 100}%`
            }}>
            <div className={style.page} style={{
                width: '100%',
                paddingTop: `${(variant.height / variant.width) * 100}%`
            }}>
                {children}
            </div>
        </div>
    );
}

interface Props {
    totalSize: number
    currentSize: number
}

export default function ProgressBar ({ totalSize, currentSize }: Props) {
    return (
        <div className="progress" style={{ height: 32 }}>
            <div className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${currentSize / totalSize * 100}%`, fontSize: '0.875rem' }}
                aria-valuenow={currentSize / totalSize * 100}
                aria-valuemin={0} aria-valuemax={100}>{currentSize}/{totalSize}</div>
        </div>
    );
}

import React from 'react';
import SideMenu from 'components/SideMenu';
import HeaderMenu from 'components/HeaderMenu';

interface PageProps {
    children: any
}

export default function RootPage ({ children }: PageProps) {
    return (
        <div className="wrapper">
            <SideMenu />
            <div className="main">
                <HeaderMenu />
                <main className="content">
                    {children}
                </main>
            </div>
        </div>
    );
}

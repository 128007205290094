import { ProductLineModel, ProductSizeModel } from 'api/api';
import ContentSize from 'models/ContentSize';

export const readContentFromQuote = (index: number | null, productLines?: ProductLineModel[]) : string => {
    if (index && productLines && (productLines[index].writingContent?.length || 0) > 0) {
        return productLines[index].writingContent![0].text || '';
    }
    return '';
};

export const toContentSize = (size: ProductSizeModel, isLandscape: boolean | null): ContentSize => {
    if (isLandscape === null) {
        return new ContentSize(size.id!, size.name!, size.name!, size.width!, size.height!, size.marginx!, size.marginy!);
    }
    if (isLandscape) {
        return new ContentSize(
            size.id!,
            size.name!,
            `${size.name} Landscape`,
            size.height! < size.width! ? size.width! : size.height!,
            size.height! < size.width! ? size.height! : size.width!,
            size.height! > size.width! ? size.marginx! : size.marginy!,
            size.height! > size.width! ? size.marginy! : size.marginx!);
    } else {
        return new ContentSize(
            size.id!,
            size.name!,
            `${size.name} Portrait`,
            size.height! > size.width! ? size.width! : size.height!,
            size.height! > size.width! ? size.height! : size.width!,
            size.height! > size.width! ? size.marginx! : size.marginy!,
            size.height! > size.width! ? size.marginy! : size.marginx!);
    }
};

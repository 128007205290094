import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'api';
import { AccountAddModel, AccountModel, AccountUpdateModel } from 'api/api';
import { push } from 'connected-react-router';
import { AppThunk } from 'helpers/thunkActionTypes';
import { getJoiValidationSchema } from 'helpers/validation';
import Joi from 'joi';
import { batch } from 'react-redux';
import { clearError, hideLoading, showError, showLoading } from 'redux/ui';

export interface AccountEditState {
    initialized: boolean
    account?: AccountModel
    initialData: FormFields
}

export interface FormFields {
    name?: string
    firstName?: string
    lastName?: string
    email?: string
    resellerId?: number
    enforceBalance?: boolean
    affiliateComission?: number
    isTest?: boolean

    billingFirstName?: string
    billingLastName?: string
    billingLine1?: string
    billingLine2?: string
    billingTown?: string
    billingPostCode?: string
    billingCountryCode?: string
}

export const formSchema = getJoiValidationSchema({
    name: Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string().email({ tlds: { allow: false } }).required(),
    resellerId: Joi.number().required(),
    enforceBalance: Joi.bool().required(),
    affiliateComission: Joi.number().required(),
    isTest: Joi.bool().required(),

    billingFirstName: Joi.string().required(),
    billingLastName: Joi.string().required(),
    billingLine1: Joi.string().required(),
    billingLine2: Joi.string().required(),
    billingTown: Joi.string().required(),
    billingPostCode: Joi.string().required(),
    billingCountryCode: Joi.string().required()
});

const initialState: AccountEditState = {
    initialized: false,
    initialData: {}
};

export const accountEditSlice = createSlice({
    name: 'accountEdit',
    initialState,
    reducers: {
        reset: () => initialState,
        setAccount: (state, action: PayloadAction<AccountModel>) => {
            state.account = action.payload;
            state.initialData = toFormModel(action.payload);
        },
        setInitialized: (state) => {
            state.initialized = true;
        },
        setInitialData: (state, action: PayloadAction<FormFields>) => {
            state.initialData = action.payload;
        }
    }
});

export const initialize = (accountId: string | undefined): AppThunk => async (dispatch, getState) => {
    const actions = accountEditSlice.actions;

    let account: AccountModel;

    dispatch(clearError());
    dispatch(showLoading());
    try {
        if (accountId) {
            account = await client().account_Get(parseInt(accountId));
        }
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());

    batch(() => {
        if (account) {
            dispatch(actions.setAccount(account));
        }
        dispatch(actions.setInitialized());
    });
};

export const save = (fields: FormFields): AppThunk => async (dispatch, getState) => {
    const account = getState().accountEdit.account;
    dispatch(clearError());
    dispatch(showLoading());

    try {
        if (account?.id) {
            await client().account_Update(toUpdateModel(fields, account.id));        
        } else {
            await client().account_Add(toAddModel(fields));
        }
        dispatch(push('/accounts'));
    } catch (error) {
        await dispatch(showError(error));
        dispatch(hideLoading());
    }
};

const toFormModel = (account: AccountModel): FormFields => {
    return {
        name: account.name,
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        resellerId: account.reseller?.id,
        enforceBalance: account.enforceBalance,
        affiliateComission: account.affiliateComission,
        isTest: account.isTest,

        billingFirstName: account.billingAddress?.firstName,
        billingLastName: account.billingAddress?.lastName,
        billingLine1: account.billingAddress?.line1,
        billingLine2: account.billingAddress?.line2,
        billingTown: account.billingAddress?.town,
        billingPostCode: account.billingAddress?.postCode,
        billingCountryCode: account.billingAddress?.countryCode
    };
};

const toAddModel = (formData: FormFields): AccountAddModel => {
    return {
        name: formData.name,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        resellerId: formData.resellerId,
        enforceBalance: formData.enforceBalance,
        affiliateComission: formData.affiliateComission,
        isTest: formData.isTest,
        billingAddress: {
            firstName: formData.billingFirstName,
            lastName: formData.billingLastName,
            line1: formData.billingLine1,
            line2: formData.billingLine2,
            town: formData.billingTown,
            postCode: formData.billingPostCode,
            countryCode: formData.billingCountryCode
        }
    };
};

const toUpdateModel = (formData: FormFields, accountId: number): AccountUpdateModel => {
    return { ...toAddModel(formData), id: accountId };
};

export const { reset } = accountEditSlice.actions;

export default accountEditSlice.reducer;

import { useCallback, useEffect, useReducer, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { initialize, resyncContent, updateAdditionalItems, importAdditionalItem, reset } from './contentPreviewSlice';
import { ActionP0, ActionP1, ActionP2 } from 'helpers/functionTypes';
import DropZone from './DropZone';
import AlignmentBlock from './AlignmentBlock';
import RectData from 'models/RectData';
import ContentBlock from './ContentBlock';
import { ProductModel } from 'api/api';
import ContentSize from 'models/ContentSize';
import style from './ContentPreview.module.css';
import ImageRectData from './ImageRectData';
import { Alignment } from './models';

interface Props {
    contentText: string
    content: RectData | undefined
    product: ProductModel
    size: ContentSize
    additionalItems: RectData[]
    completeTrigger: ActionP1<ActionP0>
    backTrigger: ActionP1<ActionP0>
    syncCallback: ActionP2<RectData | null, RectData[]>
    completeCallback: ActionP2<RectData | null, RectData[]>
    backCallback: ActionP2<RectData | null, RectData[]>
}

export default function ContentPreview (props: Props) {
    const dispatch = useAppDispatch();
    const { content, additionalItems, initialized, allowFileUpload } = useAppSelector(state => state.contentPreviewSection);
    const [selectedId, selectShape] = useState<string | null>(null);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [container, setcontainer] = useState<HTMLElement>();

    const handleRect = useCallback((node) => {
        setcontainer(node);
    }, []);

    const width = props.size?.width || 0;
    const height = props.size?.height || 0;
    const marginX = props.size?.marginX || 0;
    const marginY = props.size?.marginY || 0;

    function updatePreviewSize () {
        forceUpdate();
    };

    useEffect(() => {
        props.completeTrigger(() => {
            props.completeCallback(content, additionalItems);
        });
        props.backTrigger(() => {
            props.backCallback(content, additionalItems);
        });
        window.addEventListener('resize', updatePreviewSize);

        const interval = setInterval(() => syncData(), 6000000 * 1000);

        return () => {
            window.removeEventListener('resize', updatePreviewSize);
            clearInterval(interval);
        };
    });

    useEffect(() => {
        dispatch(initialize(props));
        return () => {
            dispatch(reset());
        };
    }, []);

    const syncData = () => {
        props.syncCallback(content, additionalItems);
    };

    const getPreviewWidth = () => {
        if (!container) {
            return 200;
        }

        const totalHeight = window.innerHeight;
        const top = container.getBoundingClientRect().top;
        const constant = 148; // bottom padding to window bottom

        return Math.min((container.offsetWidth) * 0.5, (totalHeight - top - constant) * width / height);
    };

    const getPreviewHeight = () => {
        return getPreviewWidth() / width * height;
    };

    const onContentChanged = ({ x, y, width }: RectData, horizontal?: Alignment, vertical?: Alignment) => {
        dispatch(resyncContent({ x, y, width }, horizontal, vertical));
    };

    const onAdditionalItemChanged = (data: ImageRectData) => {
        const newData = (additionalItems.find(i => i.id === data.id))
            ? additionalItems.map(i => i.id === data.id ? data : i)
            : [...additionalItems, data];
        dispatch(updateAdditionalItems(newData));
    };

    const removeSelected = () => {
        dispatch(
            updateAdditionalItems(additionalItems.filter(item => item.id !== selectedId))
        );
        selectShape(null);
    };

    const alignElement = (horizontal: Alignment, vertical: Alignment) => {
        if (selectedId === content?.id) {
            onContentChanged({
                ...content!,
                ...resolveNewPosition(content!, horizontal, vertical)
            }, horizontal, vertical);
        } else {
            const item = additionalItems.find(item => item.id === selectedId);
            if (!item) { return; }
            dispatch(updateAdditionalItems([
                ...additionalItems.filter(i => i !== item),
                {
                    ...item,
                    ...resolveNewPosition(item!, horizontal, vertical)
                }
            ]));
        }
    };

    const resolveNewPosition = (rect: RectData, horizontal: Alignment, vertical: Alignment) => {
        return {
            x: horizontal === 'start'
                ? marginX
                : (horizontal === 'end'
                    ? (width - marginX * 2 - rect.width!) + marginX
                    : (width - marginX * 2 - rect.width!) / 2 + marginX
                ),
            y: vertical === 'start'
                ? marginY
                : (vertical === 'end'
                    ? (height - marginY * 2 - rect.height!) + marginY
                    : (height - marginY * 2 - rect.height!) / 2 + marginY
                )
        };
    };

    return (
        <div className="d-flex flex-grow-1 w-100" ref={handleRect}>
            <div className={`d-flex justify-content-center ${style.content}`}>
                {initialized &&
                    <ContentBlock
                        width={getPreviewWidth()}
                        height={getPreviewHeight()}
                        scale={getPreviewWidth() / width}
                        pageParams={props.size}
                        content={content}
                        additionalItems={additionalItems}
                        onContentChanged={onContentChanged}
                        onAdditionalItemChanged={onAdditionalItemChanged}
                        onSelectedChanged={selectShape}
                    />}
            </div>
            <div className={style.controls}>
                {allowFileUpload &&
                    <DropZone onDropped={(file) => {
                        dispatch(importAdditionalItem(file, '', width - marginX * 2, marginX, marginY));
                    }} />
                }
                <AlignmentBlock
                    show={!!selectedId}
                    allowRemove={!!(selectedId && selectedId !== content?.id)}
                    onAligned={alignElement}
                    onRemove={removeSelected} />
            </div>

        </div>
    );
}

import { InteractionRequiredAuthError, IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import axios from 'axios';
import { Client } from './api';
import { BackendClient } from './BackendClient';

const axiosClient = axios.create({

});

let customHeaders: { [key: string]: string } = {};

export function initializeAxiosIntercepters (instance: IPublicClientApplication, authRedirectRequest: RedirectRequest) {
    // Add a request interceptor
    axiosClient.interceptors.request.use(async function (config) {
        // Do something before request is sent
        let token: string | null = null;
        try {
            const result = await instance.acquireTokenSilent({ ...authRedirectRequest });
            token = result.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                instance.acquireTokenRedirect({ ...authRedirectRequest });
            }
        }
        if (token) {
            config.headers = { ...config.headers, authorization: 'Bearer ' + token };
        }
        config.headers = { ...config.headers, ...customHeaders };
        config.transformResponse = (data) => data;
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axiosClient.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        if (error.response && error.response.status === 401) {
            // TODO: throw out the user 
        }

        return Promise.reject(error);
    });
}

export function client (): Client {
    return new Client(`${process.env.REACT_APP_API_URL}/api/handle`, axiosClient);
}

export function backend (): BackendClient {
    return new BackendClient(`${process.env.REACT_APP_API_URL}`, axiosClient);
}

export function withHeaders<T> (headers: { [key: string]: string }, promise: Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
        customHeaders = headers;
        promise.then(data => {
            resolve(data);
            customHeaders = {};
        }, error => {
            reject(error);
            customHeaders = {};
        });
    });
}

import { ProductLineModel } from 'api/api';
import { ActionP1 } from 'helpers/functionTypes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SendingOptions } from 'models/types';
import { useEffect } from 'react';
import { initialize } from './sendingChoiceSlice';
import style from './SendingChoice.module.css';
import { isNumber } from 'helpers/general';

interface Props {
    quoteId: number
    currentProducts: ProductLineModel[] | undefined,
    completeCallback: ActionP1<SendingOptions>
    removeProduct: ActionP1<number>
}

export default function SendingChoice ({ quoteId, currentProducts, completeCallback, removeProduct }: Props) {
    const dispatch = useAppDispatch();
    const { products, envelopes, handwrittenLetterButtonVisible } = useAppSelector(selector => selector.sendingChoice);

    useEffect(() => {
        dispatch(initialize(currentProducts));
    }, [currentProducts]);

    const tryToRemoveProduct = (index: number | null) => {
        if (isNumber(index) && window.confirm('Are you sure you wish to delete this item?')) {
            removeProduct(index!);
        }
    };

    return (
        <>
            <div className="card-title mb-3">What would you like to send?</div>
            {products.length > 0 &&
                <div className="ml-2">
                    <h5 className="mb-2">Handwritten letters</h5>
                    {products.map((product) =>
                        <div key={product.actualIndex} className="mb-0">
                            <div className={`d-inline ${style.product}`}>
                                <a href={`/quote-wizard/write-letter/editContent/${quoteId}/${product.actualIndex}`} className={style.link}>
                                    {product.title}
                                </a>
                                <a href={`/quote-wizard/write-letter/editContent/${quoteId}/${product.actualIndex}`}>
                                    <i className={`ml-2 fas fa-pencil-alt ${style.editIcon}`}></i>
                                </a>
                                <i onClick={() => tryToRemoveProduct(product.actualIndex)}
                                    className={`ml-2 fas fa-trash-alt ${style.deleteIcon}`} />
                            </div>
                        </div>
                    )}
                    <div className="mt-2">
                        <a href={`/quote-wizard/write-letter/content/${quoteId}`}>
                            <em>+ Add a letter</em>
                        </a>
                    </div>
                </div>
            }
            {envelopes.length > 0 &&
                <div className="ml-2 mt-4">
                    <h5 className="mb-2">Handwritten envelopes</h5>
                    {envelopes.map((envelope) =>
                        <div key={envelope.actualIndex} className="mb-0">
                            <div className={`d-inline ${style.product}`}>
                                <a href={`quote-wizard/write-envelope/envelopeChoice/${quoteId}/${envelope.actualIndex}`} className={style.link}>
                                    {envelope.title}
                                </a>
                                <a href={`quote-wizard/write-envelope/envelopeChoice/${quoteId}/${envelope.actualIndex}`}>
                                    <i className={`ml-2 fas fa-pencil-alt ${style.editIcon}`}></i>
                                </a>
                                <i onClick={() => tryToRemoveProduct(envelope.actualIndex)}
                                    className={`ml-2 fas fa-trash-alt ${style.deleteIcon}`} />
                            </div>
                        </div>
                    )}
                </div>
            }
            {!!((products.length || envelopes.length) && !(products.length && envelopes.length)) &&
                <h5 className="card-title mb-3 mt-4">What would you like to add:</h5>
            }
            <div className="row">
                {handwrittenLetterButtonVisible && <div className="col-6">
                    <a onClick={() => completeCallback('write-letter')} className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0 text-left"><i className="mr-2 fas fa-fw fa-robot"></i>Handwritten letters</h5>
                        </div>
                    </a>
                </div>}

                {/* <div className="col-6">
                <a onClick={() => completeCallback('print-letter')} className="card position-relative">
                    <div className="card-header">
                        <h5 className="card-title mb-0 text-left"><i className="mr-2 fas fa-fw fa-print"></i>Printed letters</h5>
                    </div>
                    <ElementBlocker />
                </a>
            </div>

            <div className="col-6">
                <a onClick={() => completeCallback('custom-insert')} className="card position-relative">
                    <div className="card-header">
                        <h5 className="card-title mb-0 text-left"><i className="mr-2 fas fa-fw fa-paperclip"></i>Inserts</h5>
                    </div>
                    <ElementBlocker />
                </a>
            </div> */}

                {
                    (envelopes.length === 0) && <div className="col-6">
                        <a onClick={() => completeCallback('write-envelope')} className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0 text-left"><i className="mr-2 fas fa-fw fa-robot"></i>Handwritten envelopes</h5>
                            </div>
                        </a>
                    </div>
                }

            </div>
        </>
    );
}

import { ActionP0, ActionP2 } from 'helpers/functionTypes';
import { getJoiResolver, getJoiValidationSchema } from 'helpers/validation';
import Joi from 'joi';
import { useForm } from 'react-hook-form';

interface Props {
    initialValue?: string | null
    initialTitle?: string | null
    onAccept: ActionP2<string, string>
    onDelete?: ActionP0 | null
}

const schema = getJoiValidationSchema({
    value: Joi
        .string()
        .required()
        .pattern(/[a-zA-Z_]+/i)
        .message('Only letters and _ are allowed'),
    title: Joi
        .string()
        .required()
});

interface FormFields {
    value: string
    title: string
}

export default function TagPopup ({ initialValue = null, initialTitle = null, onAccept, onDelete }: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<FormFields>({
        defaultValues: {
            value: initialValue || '',
            title: initialTitle || ''
        },
        resolver: getJoiResolver(schema)
    });

    return (
        <form>
            <input id="edit-tag-value"
                type="text"
                className={`form-control form-control-sm mb-1 ${(errors.value) ? 'is-invalid' : ''}`}
                placeholder="Key"
                maxLength={50}
                {...register('value')} />
            {errors.value && <div className="invalid-feedback">{errors.value.message}</div>}
            <input id="edit-tag-title"
                type="text"
                className={`form-control form-control-sm mb-1 ${(errors.title) ? 'is-invalid' : ''}`}
                placeholder="Value"
                maxLength={50}
                {...register('title')} />
            {errors.title && <div className="invalid-feedback">{errors.title.message}</div>}
            <div className="text-right">
                <span>
                    <button id="save-new-tag-label"
                        className="btn btn-sm btn-outline-success"
                        onClick={() => { handleSubmit(data => { onAccept(data.title, data.value); })(); }}
                        type="button">
                        <i className="fas fa-check"></i>
                    </button>
                </span>
                {onDelete &&
                    <span className="ml-1">
                        <button id="delete-tag"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => onDelete()}
                            type="button">
                            <i className="fas fa-fw fa-trash-alt"></i>
                        </button>
                    </span>
                }
            </div>
        </form>
    );
}

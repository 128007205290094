import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import { client } from 'api';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { ProductLineModel, ProductModel } from 'api/api';

export interface SelectEnvelopeSectionState {
    data: ProductModel[]
    initialized: boolean
}

const initialState: SelectEnvelopeSectionState = {
    data: [],
    initialized: false
};

export const selectEnvelopeSectionSlice = createSlice({
    name: 'selectEnvelopeSection',
    initialState,
    reducers: {
        setEnvelopes: (state, action: PayloadAction<ProductModel[]>) => {
            state.data = action.payload;
        },
        reset: (state) => {
            state.data = [];
        },
        setInitialized: (state) => {
            state.initialized = true;
        }
    }
});

export const initialize = (products: ProductLineModel[]): AppThunk => async (dispatch, getState) => {
    const actions = selectEnvelopeSectionSlice.actions;

    dispatch(clearError());
    dispatch(showLoading());

    try {
        let envelopes : ProductModel[] = [];
        const nonCarriers = products.filter(product => product.id && !product.isCarrier);
        if (nonCarriers.length > 0) {
            const productList : ProductModel[][] = [];
            for (let i = 0; i < nonCarriers.length; i++) {
                productList.push(await client().product_GetCarriers(nonCarriers[i].productId!));
            };
            envelopes = productList.slice(1).reduce((existinProducts, currentProducts) => {
                return existinProducts.filter(item => (currentProducts || []).find(current => current.id === item.id));
            }, [...productList[0]]);
        } else {
            envelopes = await client().product_GetAll(true);
        }
        dispatch(actions.setEnvelopes(envelopes));
    } catch (error) {
        dispatch(showError(error));
    }

    dispatch(actions.setInitialized());
    dispatch(hideLoading());
};

export const { reset } = selectEnvelopeSectionSlice.actions;

export default selectEnvelopeSectionSlice.reducer;

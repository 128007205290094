export type DeliveryType = 'Return' | 'MailOut'
export type CarrierType = 'Letter'

export interface Row {
    id: number,
    title: string,
    value: string,
    isReadOnly?: boolean
}

export interface Product {
    totalPrice: string
    title: string
    previewLink?: string
    originalIndex?: number
    parentLineId?: number | undefined,
    parts: Row[]
}

export interface Products {
    totalPrice: string
    data: Product[]
}

/* eslint-disable no-unused-vars */
export enum QuoteStatus {
    Draft = 'Draft',
    Prepared = 'Prepared',
    Sent = 'Sent',
    Expired = 'Expired',
    Ordered = 'Ordered',
    Deleted = 'Deleted'
}
/* eslint-enable no-unused-vars */

export interface QuoteDetailsModel {
    id: number
    quoteNo: string
    name?: string | undefined
    companyName?: string | undefined
    firstName: string
    lastName: string
    email: string
    quantity: number
    deliveryType: DeliveryType
    carrierType: CarrierType
    totalPrice: string
    pricePerUnit: string
    products: Products
    carrier: Products
    services: Product
    additionalCharges: Product
    notes?: string | undefined,
    status: QuoteStatus, 
    statusColor: string,
    pricingLevel: string
}

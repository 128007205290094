import Page from 'components/RootPage';
import React, { useEffect, useState } from 'react';
import loadTheme from 'themeLoader';
import { Redirect, Route, Switch } from 'react-router';
import QuoteWizard from 'components/pages/QuoteWizard';
import Home from 'components/pages/Home';
import AccountsList from 'components/pages/AccountsList';
import QuotesList from 'components/pages/QuotesList';
import QuoteDetails from 'components/pages/QuoteDetails';
import { initializeAxiosIntercepters } from 'api';
import { notifyThemeLoaded } from 'redux/ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'redux/store';
import QuoteEdit from './pages/QuoteEdit';
import ProtectedRoute from 'components/elements/ProtectedRoute';
import { initialize } from 'redux/user';
import { MsalProvider } from '@azure/msal-react';
import Loader from './quoteWizard/ui-components/Loader';
import AccountEdit from './pages/AccountEdit';

export default function App () {
    const dispatch = useAppDispatch();
    const { authClientApp, initialized, authRedirectRequest } = useAppSelector(state => state.user);
    const [appInitialized, setAppInitialized] = useState(false);

    useEffect(() => {
        dispatch(initialize());
        loadTheme(() => {
            dispatch(notifyThemeLoaded());
        });
    }, []);

    useEffect(() => {
        if (authClientApp && initialized && authRedirectRequest) {
            initializeAxiosIntercepters(authClientApp, authRedirectRequest);
            setAppInitialized(true);
        }
    }, [authClientApp, initialized]);

    return (
        <ConnectedRouter history={history}>
            {appInitialized
                ? <MsalProvider instance={authClientApp!}>
                    <Page>
                        <Switch>
                            <ProtectedRoute exact path="/">
                                <Home />
                            </ProtectedRoute>
                            <Route exact path="/logout">
                                <>Please, log in to continue</>
                            </Route>
                            <ProtectedRoute exact path={'/accounts'}>
                                <AccountsList />
                            </ProtectedRoute>
                            <ProtectedRoute exact path={[
                                '/accounts/create',
                                '/accounts/:accountId([0-9]+)'
                            ]}>
                                <AccountEdit />
                            </ProtectedRoute>
                            <ProtectedRoute exact path={'/quotes'}>
                                <QuotesList />
                            </ProtectedRoute>
                            <ProtectedRoute exact path={[
                                '/quotes/:quoteId([0-9]+)/:step([a-zA-Z-_]+)',
                                '/quotes/:quoteId([0-9]+)/:step([a-zA-Z-_]+)/:productIndex([0-9]+)'
                            ]}>
                                <QuoteEdit />
                            </ProtectedRoute>
                            <ProtectedRoute exact path={'/quotes/:quoteId([0-9]+)'}>
                                <QuoteDetails />
                            </ProtectedRoute>
                            <ProtectedRoute exact path={[
                                '/quote-wizard',
                                '/quote-wizard/:quoteId([0-9]+)',
                                '/quote-wizard/:step([a-zA-Z-_]+)',
                                '/quote-wizard/:step([a-zA-Z-_]+)/:quoteId([0-9]+)',
                                '/quote-wizard/:sendingOption([a-zA-Z-_]+)/:step([a-zA-Z-_]+)/:quoteId([0-9]+)',
                                '/quote-wizard/:sendingOption([a-zA-Z-_]+)/:step([a-zA-Z-_]+)/:quoteId([0-9]+)/:productIndex([0-9]+)']}>
                                {/* <AuthGuard> */}
                                <QuoteWizard />
                                {/* </AuthGuard> */}
                            </ProtectedRoute>
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </Page>
                </MsalProvider>
                : <Loader />
            }

        </ConnectedRouter>
    );
};

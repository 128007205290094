import { useAppSelector } from 'hooks';

interface Props {
    isInitialized?: boolean
    children: any
}

export default function LoadingContainer ({ isInitialized = true, children }: Props): JSX.Element {
    const { errorMessage, isLoading } = useAppSelector(state => state.ui);

    return (
        (isLoading || !isInitialized || errorMessage)
            ? <div className="d-flex justify-content-center align-items-center h-100">
                {(isLoading || !isInitialized) && <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem' }}>
                    <span className="sr-only">Loading...</span>
                </div>}
                {!!errorMessage && <div className="text-danger">
                    {errorMessage}
                </div>}
            </div>
            : <>{children}</>
    );
}

import { AnyAction, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { client, withHeaders } from 'api';
import { ApiException, FileParameter, FileResponse, PrintType, ProcessType, ProductModel, RenderRequestModel } from 'api/api';
import { AppThunk } from 'helpers/thunkActionTypes';
import ContentSize from 'models/ContentSize';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { v4 as uuidv4 } from 'uuid';
import RectData from 'models/RectData';
import ImageRectData from './ImageRectData';
import { batch } from 'react-redux';
import { RootStore } from 'redux/store';
import { Rect, Alignment } from './models';

export interface ContentPreviewSectionState {
    initialized: boolean;
    contentText?: string | undefined,
    contentFile?: Blob | undefined,
    product?: ProductModel | undefined
    size?: ContentSize | undefined
    content: ImageRectData | null
    additionalItems: ImageRectData[]
    allowFileUpload: boolean
}

export interface InitialData {
    contentText: string
    product: ProductModel
    size: ContentSize
    content: RectData | undefined
    additionalItems: RectData[]
}

const initialState: ContentPreviewSectionState = {
    initialized: false,
    content: null,
    additionalItems: [],
    allowFileUpload: false
};

export const contentPreviewSectionSlice = createSlice({
    name: 'contentPreviewSection',
    initialState,
    reducers: {
        initialize: (state, action: PayloadAction<InitialData>) => {
            state.contentText = action.payload.contentText;
            state.product = action.payload.product;
            state.size = action.payload.size;
            state.initialized = true;
            state.allowFileUpload = action.payload.product?.isPrintable || false;
        },
        reset: state => {
            state.contentFile = undefined;
            state.content = null;
            state.additionalItems = [];
            state.initialized = false;
        },
        updateContentFile: (state, action: PayloadAction<Blob>) => {
            state.contentFile = action.payload;
        },
        updateContent: (state, action: PayloadAction<ImageRectData>) => {
            state.content = roundRectData(action.payload);
        },
        updateAdditionalItems: (state, action: PayloadAction<ImageRectData[]>) => {
            state.additionalItems = roundRectDataArray(action.payload);
        },
        addAdditionalItem: (state, action: PayloadAction<ImageRectData>) => {
            state.additionalItems = [...state.additionalItems, action.payload];
        }
    }
});

const roundRectDataArray = (data: ImageRectData[]) => {
    return data.map(item => roundRectData(item));
};

const roundRectData = (data: ImageRectData): ImageRectData => {
    return {
        id: data.id,
        x: Math.round(data.x),
        y: Math.round(data.y),
        width: Math.round(data.width),
        height: Math.round(data.height),
        path: data.path,
        imageURL: data.imageURL
    };
};

export const initialize = (data: InitialData): AppThunk => async (dispatch, getState) => {
    const actions = contentPreviewSectionSlice.actions;
    dispatch(actions.initialize(data));

    const state = getState().contentPreviewSection;
    const screenRect: Rect = { x: 0, y: 0, width: state.size!.width };

    const content = data.content ? { ...data.content, imageURL: '' } : null;
    if (content) {
        dispatch(updateContent(content));
    }

    await dispatch(resyncContent(content ? { ...content } : screenRect));

    dispatch(showLoading());
    batch(() => {
        data.additionalItems.forEach(async item => {
            await dispatch(importAdditionalItem(null, item.path, item.width, item.x, item.y, true));
        });
    });
    dispatch(hideLoading());
};

export const resyncContent = (size: Rect, horizontal?: Alignment, vertical?: Alignment): AppThunk => async (dispatch, getState) => {
    const actions = contentPreviewSectionSlice.actions;
    const state = getState().contentPreviewSection;

    const screenSize = state.size!;

    const old = state.content;

    if (state.content) {
        // we have to call theese as seperate updates, other wise, if old value is used, UI content won't update,
        // because value hasn't changed
        await dispatch(actions.updateContent({ ...state.content!, x: size.x, y: size.y, width: size.width }));
    }

    if (!old?.width || !old.height || !state.contentFile || old?.width !== size.width) {
        dispatch(showLoading());
        dispatch(clearError());

        try {
            const rect = getState().contentPreviewSection.content;
            const actualHeight = await requestContent(rect, state.contentText, state.size!, dispatch);

            const x = Math.round(rect?.x || screenSize.marginX);
            const y = Math.round(rect?.y || screenSize.marginY);
            const width = Math.round(rect?.width || screenSize.width - screenSize.marginX * 2);
            const height = Math.round(actualHeight || screenSize.height - screenSize.marginY * 2);

            await dispatch(loadContent({ x, y, width, height }, vertical, screenSize));
        } catch (error) {
            dispatch(showError(error));
            if (old) {
                dispatch(actions.updateContent(old));
            }
        }
        dispatch(hideLoading());
    }
};

export const updateAdditionalItems = (data: ImageRectData[]): AppThunk => async (dispatch, getState) => {
    const actions = contentPreviewSectionSlice.actions;
    dispatch(actions.updateAdditionalItems(data));
};

const requestContent = async (
    size: Rect | null,
    contentText: string | undefined,
    screenSize: ContentSize,
    dispatch: ThunkDispatch<RootStore, unknown, AnyAction>
) => {
    const actions = contentPreviewSectionSlice.actions;
    const width = size ? size.width : screenSize.width - screenSize.marginX * 2;
    const model: RenderRequestModel = {
        width: width + screenSize.marginX * 2,
        height: screenSize.height,
        marginX: screenSize.marginX,
        marginY: screenSize.marginY,
        processType: ProcessType.PngConvert,
        trimBottom: true,
        areas: [{
            type: PrintType.Write,
            content: contentText,
            x: screenSize.marginX,
            y: screenSize.marginY,
            length: width,
            height: screenSize.height - screenSize.marginY * 2
        }]
    };

    let preview: FileResponse | null = null;
    let error: Error | null = null;

    try {
        model.failIfNotFit = true;
        preview = await client().render_Post(model);
    } catch (e) {
        error = e;
        model.failIfNotFit = false;
        preview = await client().render_Post(model);
    }

    if (error) {
        dispatch(showError(error));
    }

    const actualHeight = await new Promise<number>((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            resolve(image.height / image.width * width);
        };
        image.onerror = (e) => reject(e);
        image.src = URL.createObjectURL(preview!.data);
    });

    dispatch(actions.updateContentFile(preview.data));

    return actualHeight;
};

export const loadContent = (rect: Rect, vertical: Alignment | undefined, screenSize: ContentSize): AppThunk => async (dispatch, getState) => {
    const state = getState().contentPreviewSection;

    if (!state.contentFile) {
        return; // no preview passed from preveious step
    }

    let y = rect.y;
    // Fix y position, if returned image was actualiy higher, than we expected
    if (!vertical || vertical === 'start') {
        if (y + rect.height! > screenSize.height - screenSize.marginY) {
            y = screenSize.height - screenSize.marginY - rect.height!;
        }
    } else if (vertical === 'center') {
        y = (screenSize.height - rect.height!) / 2;
    } else if (vertical === 'end') {
        y = screenSize.height - screenSize.marginY - rect.height!;
    }

    const content: ImageRectData = {
        id: 'content',
        x: rect.x,
        y: y,
        width: rect.width,
        height: rect.height!,
        path: '',
        imageURL: URL.createObjectURL(state.contentFile!)
    };

    dispatch(contentPreviewSectionSlice.actions.updateContent(content));
};

export const importAdditionalItem = (file: File | null, path: string, width: number, x: number, y: number, silent: boolean = false): AppThunk => async (dispatch, getState) => {
    try {
        let actualFile: Blob | null = file;

        // Download file if one is not set yet, but we have it's path
        if (actualFile === null && path) {
            const downloadResponse = await client().binary_Download(path);
            actualFile = downloadResponse.data;
        }

        // Upload file and download it's latest version if new file was added
        if (file !== null && !path) {
            const params: FileParameter = {
                data: file,
                fileName: file.name
            };
            dispatch(clearError());
            if (!silent) {
                dispatch(showLoading());
            }
            // Update path value for further actions
            path = await uploadBinary(params);
            const uploadResponse = await client().binary_Download(path);
            actualFile = uploadResponse.data;
        }

        // Extract new info of file
        const info = await new Promise<{ w: number, h: number, f: string }>((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                resolve({ w: image.width, h: image.height, f: image.src });
            };
            image.onerror = (e) => reject(e);
            image.src = URL.createObjectURL(actualFile);
        });

        const additionalItem: ImageRectData = {
            id: uuidv4(),
            x: x,
            y: y,
            width: width,
            height: width / info.w * info.h,
            path: path,
            imageURL: info.f
        };

        dispatch(contentPreviewSectionSlice.actions.addAdditionalItem(additionalItem));
    } catch (error) {
        dispatch(showError(error));
    }
    if (!silent) {
        dispatch(hideLoading());
    }
};

const uploadBinary = async (params: FileParameter) => {
    try {
        return await withHeaders(
            { /* 'X-Content-Validate': 'IMG200DPI' */ },
            client().binary_Upload(params)
        );
    } catch (e) {
        if (e instanceof ApiException) {
            const error = JSON.parse((e as ApiException).response);
            if (Array.isArray(error)) {
                throw new Error(error[0]);
            }
        }
        throw e;
    }
};

// Action creators are generated for each case reducer function
export const { updateContent, reset } = contentPreviewSectionSlice.actions;

export default contentPreviewSectionSlice.reducer;

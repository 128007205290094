import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { logIn, logOut } from 'redux/user';
import { useMsal } from '@azure/msal-react';
import { toggleMenu } from 'redux/ui';

export default function HeaderMenu () {
    const { hasUser, user } = useAppSelector(state => state.user);
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a className="sidebar-toggle" onClick={() => dispatch(toggleMenu())}>
                <i className="hamburger align-self-center"></i>
            </a>

            {hasUser
                ? <div className="navbar-collapse collapse">
                    <ul className="navbar-nav navbar-align">

                        <li className="nav-item dropdown">
                            <a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-toggle="dropdown">
                                <i className="align-middle" data-feather="settings"></i>
                            </a>

                            <a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-toggle="dropdown">
                                {/* <img src="img/avatars/avatar.jpg" className="avatar img-fluid rounded-circle mr-1" alt="Chris Wood" /> */}
                                <span className="text-dark">{user?.name}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                {/* <a className="dropdown-item" href="pages-profile.html"><i className="align-middle mr-1" data-feather="user"></i> Profile</a>
                                    <a className="dropdown-item" href="#"><i className="align-middle mr-1" data-feather="pie-chart"></i> Analytics</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="pages-settings.html">Settings & Privacy</a>
                                    <a className="dropdown-item" href="#">Help</a> */}
                                <a onClick={() => dispatch(logOut(instance))} className="dropdown-item" href="#">Sign out</a>
                            </div>
                        </li>
                    </ul>
                </div>
                : <button onClick={() => dispatch(logIn(instance))} className="btn btn-primary ml-auto">Sign in</button>
            }
        </nav>
    );
}

import { ActionP0 } from 'helpers/functionTypes';

interface Props {
    completeCallback: ActionP0
}

export default function FinalStep ({ completeCallback }: Props) {
    return (<>
        <div className="row">
            <div className="col-12">Your quote was completed.</div>
        </div>

        <div className="d-flex justify-content-end align-items-center mt-5">
            <a onClick={() => completeCallback()} className="btn btn-success">Go to details</a>
        </div>
    </>);
}

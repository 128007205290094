import { FormFields as ProspectFormFields } from './sections/Prospect';
import StepFooter from './ui-components/StepFooter';
import Loader from './ui-components/Loader';
import { ActionP0, FunctionP2 } from 'helpers/functionTypes';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
    Prospect,
    Delivery,
    Quantity,
    SendingChoice,
    Content,
    StationeryChoice,
    StationeryProvided,
    ProductChoice,
    EditContent,
    ContentPreview,
    EnvelopeChoice,
    AdditionalServices,
    Notes,
    FinalStep
} from './sections';
import {
    initialize,
    updateProspectData,
    updateDeliveryType,
    goToPreviousStep,
    updateQuantity,
    updateSendingChoice,
    updateContentSize,
    updateStationeryProvider,
    updateProduct,
    updateContentPlacement,
    updateEnvelopeOption,
    updateAdditionalServices,
    updateNotes,
    STEPS,
    setInitialContent,
    updateContent,
    finishQuote,
    QuoteWizardState,
    removeProduct,
    updateContentPlacementAndGoBack,
    fullReset
} from './redux';
import { FieldModel, PricingLevelModel, ProductLineModel, ProductModel, QuoteModel, ServiceLineModel } from 'api/api';
import { useEffect } from 'react';
import { RootStoreDispatch } from 'redux/store';
import ContentSize from 'models/ContentSize';
import { extractContentForPreview, importFieldsToContent } from 'helpers/tagifyHelper';
import RectData from 'models/RectData';
import { useLocation } from 'react-router';
import { isNumber } from 'helpers/general';

export type Callback = 'quoteDetails'

function renderProspect (quote: QuoteModel, prospect: ProspectFormFields | undefined, callback: Callback | undefined, dispatch: RootStoreDispatch): JSX.Element {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };

    const initialData = prospect
        ? { ...prospect } as ProspectFormFields
        : {
            name: quote.name,
            companyName: quote.companyName,
            firstName: quote.firstName,
            lastName: quote.lastName,
            email: quote.email,
            priceLevel: quote.priceLevel?.id
        } as ProspectFormFields;

    return (
        <>
            <Prospect initialData={initialData} completeCallback={(data) => { dispatch(updateProspectData(data)); }} completeTrigger={completeTrigger} />
            <StepFooter
                showPreviousLink={true}
                previousStepText="Cancel"
                previousAction={() => dispatch(goToPreviousStep())}
                nextStepText={callback === 'quoteDetails' ? 'Apply' : undefined}
                nextAction={() => nextClicked()} />
        </>
    );
}

function renderDelivery (dispatch: RootStoreDispatch): JSX.Element {
    return (
        <>
            <Delivery completeCallback={(type) => { dispatch(updateDeliveryType(type)); }} />
            <StepFooter previousAction={() => dispatch(goToPreviousStep())}
                showNextLink={false} />
        </>
    );
}

function renderQuantity (quote: QuoteModel, callback: Callback | undefined, dispatch: RootStoreDispatch): JSX.Element {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };

    return (
        <>
            <Quantity
                initialQuantity={quote.quantity}
                completeCallback={(quantity) => { dispatch(updateQuantity(quantity)); }}
                completeTrigger={completeTrigger} />
            <StepFooter
                previousStepText={callback === 'quoteDetails' ? 'Cancel' : undefined}
                previousAction={() => dispatch(goToPreviousStep())}
                nextStepText={callback === 'quoteDetails' ? 'Apply' : undefined}
                nextAction={() => nextClicked()} />
        </>
    );
}

function renderSendingChoice (quote: QuoteModel, productLines: ProductLineModel[] | undefined, dispatch: RootStoreDispatch): JSX.Element {
    return (
        <>
            <SendingChoice
                quoteId={quote.id || 0}
                currentProducts={productLines}
                completeCallback={(option) => { dispatch(updateSendingChoice(option)); }}
                removeProduct={(index) => dispatch(removeProduct(index))} />
            <StepFooter
                showPreviousLink={false}
                nextAction={() => dispatch(updateSendingChoice(null))} />
        </>
    );
}

function renderContent (initialContent: string, dispatch: RootStoreDispatch): JSX.Element {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };

    return (
        <>
            <Content initialContent={initialContent}
                completeCallback={(content) => { dispatch(setInitialContent(content)); }}
                completeTrigger={completeTrigger} />
            <StepFooter previousAction={() => dispatch(goToPreviousStep())}
                nextAction={() => nextClicked()}
                previousStepText='Cancel' />
        </>
    );
}

function renderStationeryChoice (content: string | undefined, dispatch: RootStoreDispatch): JSX.Element {
    return (<>
        {(content)
            ? <>
                <StationeryChoice
                    content={content}
                    completeCallback={(variant) => { dispatch(updateContentSize(variant)); }} />

                <StepFooter previousAction={() => dispatch(goToPreviousStep())}
                    showNextLink={false} />
            </>
            : <div>No Content</div>}
    </>
    );
}

function renderStationeryProvided (dispatch: RootStoreDispatch): JSX.Element {
    return (
        <>
            <StationeryProvided completeCallback={(option) => { dispatch(updateStationeryProvider(option)); }} />
            <StepFooter previousAction={() => dispatch(goToPreviousStep())}
                showNextLink={false} />
        </>
    );
}

function renderProductChoice (size: ContentSize | undefined, dispatch: RootStoreDispatch): JSX.Element {
    return (
        <>
            <ProductChoice contentSize={size}
                completeCallback={(product) => dispatch(updateProduct(product))} />
            <StepFooter previousAction={() => dispatch(goToPreviousStep())}
                showNextLink={false} />
        </>
    );
}

function renderEditContent (
    content: string,
    tags: FieldModel[],
    contentSize: ContentSize | undefined,
    product: ProductModel | undefined,
    contentRect: RectData | undefined,
    additionalItemsRect: RectData[],
    productLineIndex: number | null,
    callback: Callback | undefined,
    dispatch: RootStoreDispatch
): JSX.Element {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };
    const finalContent = importFieldsToContent(content, tags);

    return (
        <>
            {(contentSize && product) &&
                <>
                    <EditContent initialContent={finalContent}
                        contentSize={contentSize}
                        product={product}
                        contentRect={contentRect}
                        additionalItemsRect={additionalItemsRect}
                        syncCallback={(newContent) => { dispatch(updateContent(newContent, true)); }}
                        completeCallback={(newContent) => { dispatch(updateContent(newContent, false)); }}
                        completeTrigger={completeTrigger} />
                    <StepFooter
                        showPreviousLink={!isNumber(productLineIndex) || callback === 'quoteDetails'}
                        previousStepText={callback === 'quoteDetails' ? 'Cancel' : undefined}
                        previousAction={() => dispatch(goToPreviousStep())}
                        nextAction={() => nextClicked()}
                        showLoader={false} />
                </>
            }
            {!(contentSize) && <div className="col-12">No valid content size</div>}
            {!(product) && <div className="col-12">No valid product</div>}
        </>
    );
}

function renderContentPreview (
    contentPlacement: RectData | undefined,
    additionalItemsPlacement: RectData[],
    contentText: string,
    tags: FieldModel[],
    product: ProductModel | undefined,
    size: ContentSize | undefined,
    callback: Callback | undefined,
    dispatch: RootStoreDispatch
) {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };
    let backClicked: ActionP0;
    const backAndSaveTrigger = (action: ActionP0) => {
        backClicked = action;
    };

    const content = extractContentForPreview(importFieldsToContent(contentText, tags)) || '';

    return (
        <>
            {(product && size) &&
                <div className="h-100 d-flex flex-column">
                    <ContentPreview
                        product={product}
                        size={size}
                        contentText={content}
                        content={contentPlacement}
                        additionalItems={additionalItemsPlacement}
                        syncCallback={(content, graphics) => { dispatch(updateContentPlacement(content, graphics, false)); }}
                        completeCallback={(content, graphics) => { dispatch(updateContentPlacement(content, graphics, true)); }}
                        backCallback={(content, graphics) => { dispatch(updateContentPlacementAndGoBack(content, graphics)); }}
                        completeTrigger={completeTrigger}
                        backTrigger={backAndSaveTrigger} />
                    <StepFooter
                        showLoader={false}
                        previousAction={() => backClicked()}
                        nextStepText={callback === 'quoteDetails' ? 'Apply' : undefined}
                        nextAction={() => nextClicked()} />
                </div>
            }
            {!(product) && <div className="col-12">No valid product</div>}
            {!(size) && <div className="col-12">No valid content size</div>}
        </>
    );
}

function renderEnvelopeChoice (products: ProductLineModel[], dispatch: RootStoreDispatch) {
    return (
        <>
            <EnvelopeChoice
                products={products}
                completeCallback={(option) => { dispatch(updateEnvelopeOption(option)); }} />
            <StepFooter previousAction={() => dispatch(goToPreviousStep())}
                showNextLink={false}
                previousStepText='Cancel' />
        </>
    );
}

function renderAdditionalServices (selectedSerivces: ServiceLineModel[] | undefined, priceLevel: PricingLevelModel | undefined, quantity: number, callback: Callback | undefined, dispatch: RootStoreDispatch) {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };

    return (
        <>
            <AdditionalServices
                selectedServices={selectedSerivces || []}
                priceLevel={priceLevel}
                quantity={quantity}
                completeCallback={services => { dispatch(updateAdditionalServices(services)); }}
                completeTrigger={completeTrigger} />
            <StepFooter
                previousAction={() => dispatch(goToPreviousStep())}
                previousStepText={callback === 'quoteDetails' ? 'Cancel' : undefined}
                nextAction={() => nextClicked()}
                nextStepText={callback === 'quoteDetails' ? 'Apply' : undefined} />
        </>
    );
}

function renderNotes (notes: string | undefined, callback: Callback | undefined, dispatch: RootStoreDispatch): JSX.Element {
    let nextClicked: ActionP0;
    const completeTrigger = (action: ActionP0) => {
        nextClicked = action;
    };

    return (
        <>
            <Notes initialNote={notes || ''}
                completeCallback={(newNote) => { dispatch(updateNotes(newNote)); }}
                completeTrigger={completeTrigger} />
            <StepFooter
                previousAction={() => dispatch(goToPreviousStep())}
                previousStepText={callback === 'quoteDetails' ? 'Cancel' : undefined}
                nextAction={() => nextClicked()}
                nextStepText={callback === 'quoteDetails' ? 'Apply' : undefined} />
        </>
    );
}

function renderFinalStep (dispatch: RootStoreDispatch): JSX.Element {
    return (
        <FinalStep completeCallback={() => dispatch(finishQuote())} />
    );
}

interface Props {
    urlResolver: FunctionP2<QuoteWizardState, number | undefined, string>
    quoteId?: string
    step?: string
    sendingOption?: string
    productIndex?: string
    callback?: Callback
}

export default function QuoteWizardPath ({ urlResolver, quoteId, step, sendingOption, productIndex, callback }: Props): JSX.Element {
    const dispatch = useAppDispatch();

    const location = useLocation();

    useEffect(() => {
        return () => {
            dispatch(fullReset());
        };
    }, []);

    useEffect(() => {
        dispatch(initialize(urlResolver, quoteId, step, sendingOption, productIndex, callback));
    }, [location.pathname]);

    const state = useAppSelector(s => s.quoteWizard);
    const quote = useAppSelector(s => s.quote.quote);

    if (state.initialized && state.currentStep) {
        switch (state.currentStep.key) {
            case STEPS.prospect.key:
                return renderProspect(quote, state.prospect, callback, dispatch);
            case STEPS.delivery.key:
                return renderDelivery(dispatch);
            case STEPS.quantity.key:
                return renderQuantity(quote, callback, dispatch);
            case STEPS.sendingChoice.key:
                return renderSendingChoice(quote, quote.productLines, dispatch);
            case STEPS.content.key:
                return renderContent(state.content, dispatch);
            case STEPS.stationeryChoice.key:
                return renderStationeryChoice(state.content, dispatch);
            case STEPS.stationeryProvided.key:
                return renderStationeryProvided(dispatch);
            case STEPS.productChoice.key:
                return renderProductChoice(state.contentSize, dispatch);
            case STEPS.editContent.key:
                return renderEditContent(
                    state.content,
                    state.tags,
                    state.contentSize,
                    state.product,
                    state.contentPlacement,
                    state.graphicsPlacement,
                    state.productLineIndex,
                    callback,
                    dispatch
                );
            case STEPS.contentPreview.key:
                return renderContentPreview(
                    state.contentPlacement,
                    state.graphicsPlacement,
                    state.content,
                    state.tags,
                    state.product,
                    state.contentSize,
                    callback,
                    dispatch);
            case STEPS.envelopeChoice.key:
                return renderEnvelopeChoice(
                    quote.productLines || [],
                    dispatch);
            case STEPS.additionalServices.key:
                return renderAdditionalServices(
                    quote.serviceLines,
                    quote.priceLevel,
                    quote.quantity || 0,
                    callback,
                    dispatch
                );
            case STEPS.notes.key:
                return renderNotes(
                    quote.notes,
                    callback,
                    dispatch);
            case STEPS.finalStep.key:
                return renderFinalStep(dispatch);
            default:
                return <></>;
        }
    } else {
        return (<Loader />);
    }
}

import { ActionP1 } from 'helpers/functionTypes';
import { ProductLineModel, ProductModel } from 'api/api';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { initialize, reset } from './envelopeChoiceSlice';

interface Props {
    products: ProductLineModel[],
    completeCallback: ActionP1<ProductModel>
}

export default function EnvelopeChoice ({ products, completeCallback }: Props) {
    const dispatch = useAppDispatch();
    const { data, initialized } = useAppSelector(state => state.selectEnvelopeSection);

    useEffect(() => {
        dispatch(initialize(products));
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <div className="row">
            {initialized &&
                <>
                    {(data).map(envelope => (
                        <div key={envelope.id} className="col-6">
                            <a onClick={() => completeCallback(envelope)} className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0 text-left">{envelope.name}</h5>
                                </div>
                            </a>
                        </div>
                    ))}

                    {!(data.length) &&
                        <div className="col-12">No envelopes found</div>
                    }

                    {/* 
                    <div className="col-6">
                        <a onClick={() => completeCallback(null)} className="card position-relative">
                            <div className="card-header">
                                <h5 className="card-title mb-0 text-left">I will supply my own</h5>
                            </div>
                            <ElementBlocker />
                        </a>
                    </div> 
                    */}
                </>}
        </div>
    );
}

import React from 'react';
import { ActionP0 } from 'helpers/functionTypes';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    icon?: string
    title?: string
    items: ItemGroup[]
    buttonClassName?: string
}

export interface ItemGroup {
    title?: string
    items: Item[]
}

export interface Item {
    action: ActionP0
    title: string
    enabled?: boolean
}

export default function DropDown ({ icon, title, items, buttonClassName }: Props) {
    const uuid = uuidv4();

    return (
        <div className="d-inline">
            <button type="button" className={`btn ml-2 ${buttonClassName || 'btn-primary'}`} id={uuid}
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className={`align-middle mt-n1 ${icon}`}></i> {title}</button>
            <div className="dropdown-menu dropdown-menu-right">
                {items.map((item, index) =>
                    <div key={index}>
                        {item.title ? <h6 className="dropdown-header">{item.title}</h6> : <></>}
                        {(item.items || []).map((subItem) => (
                            <a className={`dropdown-item ${subItem.enabled === false ? 'disabled' : ''}`}
                                key={subItem.title}
                                onClick={() => subItem.enabled !== false && subItem.action()}
                            >{subItem.title}</a>
                        ))}
                        {index < items.length - 1 ? <div className="dropdown-divider"></div> : <></>}
                    </div>
                )}
            </div>
        </div>
    );
}

import { goToStep, handleBackWithCallback, quoteWizardSlice, STEPS } from '../index';
import { AppThunk } from 'helpers/thunkActionTypes';
import { batch } from 'react-redux';

const goToPreviousStep = (): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    const { currentStep, callback } = getState().quoteWizard;
    switch (currentStep!.key) {
        case STEPS.prospect.key:
            dispatch(handleBackWithCallback());
            break;
        case STEPS.delivery.key:
            dispatch(goToStep(STEPS.prospect));
            break;
        case STEPS.quantity.key:
            batch(async () => {
                const handled = callback && await dispatch(handleBackWithCallback());
                if (!handled) {
                    dispatch(goToStep(STEPS.delivery));
                }
            });
            break;
        case STEPS.sendingChoice.key:
            dispatch(goToStep(STEPS.quantity));
            break;
        case STEPS.content.key:
            batch(async () => {
                dispatch(quoteActions.reset());
                const handled = callback && await dispatch(handleBackWithCallback());
                if (!handled) {
                    dispatch(goToStep(STEPS.sendingChoice));
                }
            });
            break;
        case STEPS.stationeryChoice.key:
            dispatch(goToStep(STEPS.content));
            break;
        case STEPS.stationeryProvided.key:
            dispatch(goToStep(STEPS.stationeryChoice));
            break;
        case STEPS.productChoice.key:
            dispatch(goToStep(STEPS.stationeryProvided));
            break;
        case STEPS.editContent.key:
            batch(async () => {
                dispatch(quoteActions.reset());
                const handled = callback && await dispatch(handleBackWithCallback());
                if (!handled) {
                    dispatch(goToStep(STEPS.productChoice));
                }
            });
            break;
        case STEPS.contentPreview.key:
            dispatch(goToStep(STEPS.editContent));
            break;
        case STEPS.envelopeChoice.key:
            batch(async () => {
                dispatch(quoteActions.reset());
                const handled = callback && await dispatch(handleBackWithCallback());
                if (!handled) {
                    dispatch(goToStep(STEPS.sendingChoice));
                }
            });
            break;
        case STEPS.additionalServices.key:
            batch(async () => {
                const handled = callback && await dispatch(handleBackWithCallback());
                if (!handled) {
                    dispatch(goToStep(STEPS.sendingChoice));
                }
            });
            break;
        case STEPS.notes.key:
            batch(async () => {
                dispatch(quoteActions.reset());
                const handled = callback && await dispatch(handleBackWithCallback());
                if (!handled) {
                    dispatch(goToStep(STEPS.additionalServices));
                }
            });
            break;
    }
};

export default goToPreviousStep;

import { useThemeLoaded } from 'themeLoader';
import Card from 'components/elements/Card';
import Page from 'components/Page';
import { useAppSelector } from 'hooks';
import QuoteWizardPath from 'components/quoteWizard/QuoteWizardPath';
import { QuoteWizardState } from 'components/quoteWizard/redux';
import { useRouteMatch } from 'react-router';

export default function QuoteWizard () {
    useThemeLoaded(() => {
        window.feather.replace();
    });

    const { currentStep, initialized } = useAppSelector(state => state.quoteWizard);

    const urlResolver = (state: QuoteWizardState, quoteId: number | undefined) => {
        const { sendingOption, productLineIndex, currentStep } = state;
        const linkParts = [
            'quote-wizard',
            sendingOption ? `${sendingOption}` : null,
            currentStep!.key,
            quoteId,
            productLineIndex
        ].filter(line => line || line === 0);
        return `/${linkParts.join('/')}`;
    };

    const { params } = useRouteMatch<{
        quoteId?: string,
        step?: string,
        sendingOption?: string,
        productIndex?: string
    }>();

    return (
        <Page title="Quote">
            <div className="row">
                <div className="col-12">
                    <Card title={(initialized && currentStep) ? currentStep.title : ''}>
                        <QuoteWizardPath {...params} urlResolver={urlResolver} />
                    </Card>
                </div>
            </div>
        </Page>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, IPublicClientApplication, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import axios from 'axios';
import { showError } from 'redux/ui';
import { batch } from 'react-redux';
import { push } from 'connected-react-router';

export interface UserState {
    user: User | null
    hasUser: boolean
    initialized: boolean
    authClientApp: PublicClientApplication | null
    authRedirectRequest: RedirectRequest | null
}

export interface User {
    name: string,
    username: string
}

const initialState: UserState = {
    user: null,
    hasUser: false,
    initialized: false,
    authClientApp: null,
    authRedirectRequest: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setInitialized: state => {
            state.initialized = true;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.hasUser = true;
        },

        clearUser: state => {
            state.user = null;
            state.hasUser = false;
        },
        setAuthClientApp: (state, action: PayloadAction<PublicClientApplication>) => {
            state.authClientApp = action.payload;
        },
        setAuthRedirectRequest: (state, action: PayloadAction<RedirectRequest>) => {
            state.authRedirectRequest = action.payload;
        }
    }
});

export const initialize = (): AppThunk => async (dispatch, getState) => {
    const actions = userSlice.actions;

    await dispatch(requestConfiguration());

    const instance = getState().user.authClientApp;
    if (!instance) {
        dispatch(showError(new Error('Cannot get network configuration')));
        return;
    }

    const setUser = async (info: AccountInfo | null) => {
        instance.setActiveAccount(info);
        if (info) {
            await dispatch(actions.setUser({
                name: info.name || '',
                username: info.username
            }));
        } else {
            await dispatch(actions.clearUser());
        }
    };

    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
        await setUser(accounts[0]);
    }

    instance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            setUser(account!);
        }
    });

    instance.handleRedirectPromise().then(authResult => {
        // Azure has been loaded, let's check, if we have a user.
        // If we don't have it - let's trigger log in routine
        const hasUser = getState().user.hasUser;
        const currentPath = getState().router.location.pathname;
        if (!hasUser && currentPath !== '/logout') {
            dispatch(logIn(instance));
        } else {
            dispatch(actions.setInitialized());
        }
    }).catch(err => {
        dispatch(showError(err));
    });
};

const requestConfiguration = (): AppThunk => async (dispatch, getState) => {
    const actions = userSlice.actions;
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/config`);
        const { authority, clientId, redirectUri, scopes } = response.data.authSettings;

        const msalConfig = {
            auth: {
                clientId: clientId,
                authority: authority,
                redirectUri: redirectUri
            },
            cache: {
                cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
            }
        };
        batch(() => {
            dispatch(actions.setAuthClientApp(new PublicClientApplication({ ...msalConfig })));
            dispatch(actions.setAuthRedirectRequest({ scopes: scopes }));
        });
    } catch (e) {
        console.error(e);
    }
};

export const logIn = (instance: IPublicClientApplication): AppThunk => async (dispatch, getState) => {
    const loginRequest = getState().user.authRedirectRequest;
    const currentPath = getState().router.location.pathname;
    try {
        if (loginRequest) {
            await instance.loginRedirect({
                ...loginRequest,
                redirectUri: currentPath === '/logout' ? getDomain() + '/' : undefined
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const logOut = (instance: IPublicClientApplication): AppThunk => async (dispatch, getState) => {
    try {
        // Have to redirect to root before log out, because otherwise log out action simply moves to
        // root and cancels the request to MS Log off link. Couldn't find actual reason for that
        await dispatch(push('/'));
        await instance.logoutRedirect({ postLogoutRedirectUri: getDomain() + '/logout' });
    } catch (e) {
        console.log(e);
    }
};

const getDomain = () : string => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
};

export default userSlice.reducer;

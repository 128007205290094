import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { ProductLineModel, ProductLineUpdateModel, ProductModel, QuoteModel, QuoteUpdateModel, ServiceLineModel, ServiceLineUpdateModel, ServiceType } from 'api/api';
import { client } from 'api';
import { AppThunk } from 'helpers/thunkActionTypes';
import { quoteSlice } from '.';

export const syncQuote = (quote: QuoteModel, products: ProductModel[]): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const actions = quoteSlice.actions;

    const user = getState().user.user?.name;

    dispatch(showLoading());
    dispatch(clearError());
    try {
        const quoteModel: QuoteUpdateModel = {
            id: quote.id,
            quoteNo: quote.quoteNo,
            createdBy: quote.createdBy || user || '',
            modifiedBy: user || '',
            companyName: quote.companyName,
            name: quote.name,
            content: quote.content,
            firstName: quote.firstName,
            lastName: quote.lastName,
            email: quote.email,
            levelId: quote.priceLevel?.id,
            quantity: quote.quantity || undefined,
            deliveryType: quote.deliveryType,
            notes: quote.notes,
            productLines: toProductUpdateModel(quote.productLines, products),
            serviceLines: toServiceUpdateModel(quote.serviceLines)
        };

        const response = await client().quote_Post(quoteModel);
        await dispatch(actions.setQuote(response));
        dispatch(hideLoading());
        return true;
    } catch (error) {
        dispatch(showError(error));
        dispatch(hideLoading());
        return false;
    }
};

function toProductUpdateModel (productLine : ProductLineModel[] | undefined, sourceProducts: ProductModel[]) : ProductLineUpdateModel[] | undefined {
    if (!productLine) return;

    return productLine.map(product => {
        const sourceProduct = sourceProducts.find(p => p.id === product.productId);

        const services: ServiceLineUpdateModel[] = [];
        services.push({
            serviceId: sourceProduct?.writingServiceId,
            description: 'writingServiceLine',
            qty: 1,
            serviceType: ServiceType.Writing
        });
        if ((product.graphicsContent?.length ?? 0) > 0) {
            services.push({
                serviceId: sourceProduct?.printServiceId,
                description: 'printingServiceLine',
                qty: 1,
                serviceType: ServiceType.Printing
            });
        }

        return {
            id: product.id,
            productId: product.productId,
            orientation: product.orientation,
            description: product.description,
            isCarrier: product.isCarrier,
            fields: product.fields,
            graphicsContent: product.graphicsContent,
            writingContent: product.writingContent,
            serviceLines: services,
            parentLineId: product.parentLineId
        };
    });
}

function toServiceUpdateModel (services: ServiceLineModel[] | undefined) : ServiceLineUpdateModel[] | undefined {
    if (!services) return;

    return services.map(service => {
        return {
            id: service.id,
            serviceId: service.serviceId,
            description: service.description,
            qty: 1
        };
    });
}

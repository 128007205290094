import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import ContentSize from 'models/ContentSize';
import { client } from 'api';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { ProductModel } from 'api/api';

export interface ProductChoiceSectionState {
    data: ProductModel[] | null
    initialized: boolean
}

const initialState: ProductChoiceSectionState = {
    data: [],
    initialized: false
};

export const productChoiceSectionSlice = createSlice({
    name: 'productChoiceSection',
    initialState,
    reducers: {
        reset: (state) => {
            state.data = null;
            state.initialized = false;
        },
        setProducts: (state, action: PayloadAction<ProductModel[]>) => {
            state.initialized = true;
            state.data = action.payload;
        }
    }
});

export const initialize = (size: ContentSize): AppThunk => async (dispatch, getState) => {
    const actions = productChoiceSectionSlice.actions;

    dispatch(clearError());
    dispatch(showLoading());

    try {
        const products = (await client().product_GetAll(false, size.sizeId))
            .filter(product => product.isWritable);
        dispatch(actions.setProducts(products));
    } catch (error) {
        dispatch(showError(error));
    }

    dispatch(hideLoading());
};

export const { reset } = productChoiceSectionSlice.actions;

export default productChoiceSectionSlice.reducer;

import { ActionP1 } from 'helpers/functionTypes';
import { useAppDispatch, useAppSelector } from 'hooks';
import ContentSize from 'models/ContentSize';
import { useThemeLoaded } from 'themeLoader';
import style from './StationeryChoice.module.css';
import ProgressBar from '../../ui-components/ProgressBar';
import Loader from '../../ui-components/Loader';
import { useEffect } from 'react';
import { initialize } from './stationeryChoiceSlice';
import ScaledPage from './ScaledPage';

interface Props {
    content: string,
    completeCallback: ActionP1<ContentSize>
}

function handleCarouselInitialization () {
    function updateNavigationButtons () {
        const firstActive = window.$('#stationeryChoiceCarouselControls .carousel-item:first-child').is('.active');
        const lastActive = window.$('#stationeryChoiceCarouselControls .carousel-item:last-child').is('.active');

        if (firstActive) {
            window.$('#stationeryChoiceCarouselControls .carousel-arrow--left').addClass('disabled');
        } else {
            window.$('#stationeryChoiceCarouselControls .carousel-arrow--left').removeClass('disabled');
        }

        if (lastActive) {
            window.$('#stationeryChoiceCarouselControls .carousel-arrow--right').addClass('disabled');
        } else {
            window.$('#stationeryChoiceCarouselControls .carousel-arrow--right').removeClass('disabled');
        }
    }

    window.$('#stationeryChoiceCarouselControls').carousel('pause');
    window.$('#stationeryChoiceCarouselControls').on('slid.bs.carousel', function () {
        updateNavigationButtons();
    });
    updateNavigationButtons();
}

export default function StationeryChoice ({ content, completeCallback }: Props) {
    const {
        isLoading, isValidating, error,
        itemsToValidateCount, validatedItemsCount,
        data
    } = useAppSelector(state => state.stationeryChoiceSection);
    const dispatch = useAppDispatch();

    useThemeLoaded(handleCarouselInitialization, [data]);

    useEffect(() => {
        if (data === null) {
            dispatch(initialize(content));
        }
    }, []);

    const baseSize = 1.4 * (data || [])
        .map(v => Math.max(v.width, v.height))
        .reduce((prev, curr) => Math.max(prev, curr), 0); // get max possible size

    return (
        <>
            {(isLoading) && <div>
                <div>
                    <h5 className="card-title mb-4">Please wait, we&apos;re loading variants of stationery</h5>
                </div>
                <Loader />
            </div>}

            {isValidating && <div>
                <div>
                    <h5 className="card-title mb-5">Please wait, we&apos;re checking what variants of stationery fit your content</h5>
                </div>
                <ProgressBar totalSize={itemsToValidateCount} currentSize={validatedItemsCount} />
            </div>}

            {!(isLoading || isValidating || data === null) && <div>
                <div>
                    <h5 className="card-title mb-4">Your content fits on variants of stationery. Please choose one:</h5>
                </div>
                {data.length > 0
                    ? <div className="row h-100 justify-content-center">
                        <div id="stationeryChoiceCarouselControls" className={`${style.carousel} carousel slide col-10 col-lg-8`} data-wrap="false" data-interval="0">
                            <div className="carousel-inner h-100">
                                {[...Array(Math.ceil(data!.length / 2))].map((e, i) =>
                                    <div key={i} className={`carousel-item h-100 ${i === 0 ? 'active' : ''}`}>
                                        <div className="row h-100" role="listbox">
                                            {data?.slice(i * 2, i * 2 + 2).map((variant, index) =>
                                                <div key={index} className={`${style.pageContainer} col-6 d-flex`}>
                                                    <div className="card mb-2 m-1 align-self-stretch w-100">
                                                        <ScaledPage onClick={completeCallback} variant={variant} baseSize={baseSize}>
                                                            <>
                                                                <div className={style.pageLabel}>{variant.type}</div>
                                                                <div className={style.pageTitle}>{variant.title}</div>
                                                            </>
                                                        </ScaledPage>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <a className="carousel-arrow carousel-arrow--left text-decoration-none"
                                href="#stationeryChoiceCarouselControls" data-slide="prev">
                                <i className="fa fa-chevron-left"></i>
                            </a>
                            <a className="carousel-arrow carousel-arrow--right text-decoration-none"
                                href="#stationeryChoiceCarouselControls" data-slide="next">
                                <i className="fa fa-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                    : <div>No stationery fits the given content</div>}

            </div>}

            {error && <div className="d-flex justify-content-center align-items-center mb-3 mt-4">
                <div className="text-danger">{error}</div>
            </div>}
        </>
    );
}

import { useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { Route } from 'react-router';
import { logIn } from 'redux/user';

interface Props {
    children: any,
    [key: string]: any
}

export default function ProtectedRoute ({ children, ...rest }: Props) {
    const isAuthorized = useAppSelector(store => store.user.hasUser);
    const dispatch = useAppDispatch();
    const { instance } = useMsal();

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(logIn(instance));
        }
    }, [isAuthorized]);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthorized
                    ? (children)
                    : <></>
            }
        />
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isString } from 'helpers/general';
import { AppThunk } from 'helpers/thunkActionTypes';
import { batch } from 'react-redux';

export type ErrorContenet = Array<string | ErrorObject> | ErrorObject

interface ErrorObject {
    [key: string]: string | Array<string>
}

export interface UIState {
    themeLoaded: boolean
    hasHeaderMenu: boolean
    isLoading: boolean
    error?: any
    errorMessage?: string
    errorContent?: ErrorContenet
    showMenu: boolean
}

const initialState: UIState = {
    themeLoaded: false,
    hasHeaderMenu: true,
    isLoading: false,
    showMenu: true
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        notifyThemeLoaded: state => {
            state.themeLoaded = true;
        },
        showLoading: state => {
            state.isLoading = true;
        },
        hideLoading: state => {
            state.isLoading = false;
        },
        setError: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.errorMessage = action.payload.message;
        },
        setErrorContenet: (state, action: PayloadAction<ErrorContenet>) => {
            state.errorContent = action.payload;
        },
        clearError: state => {
            state.error = undefined;
            state.errorMessage = undefined;
            state.errorContent = undefined;
        },
        toggleMenu: state => {
            state.showMenu = !state.showMenu;
        }
    }
});

export const showError = (e: Error): AppThunk => async (dispatch, getState) => {
    const actions = uiSlice.actions;
    const errorContent = await extractError(e);
    batch(() => {
        dispatch(actions.setError(e));
        dispatch(actions.setErrorContenet(errorContent));
    });

    console.error(e);
};

const extractError = async (error: any) => {
    if (error.response) {
        try {
            // try to extract error response, if one exists
            let content = '';
            if (isString(error.response)) {
                content = error.response;
            } else if (error.response instanceof Blob) {
                content = await error.response.text();
            }

            // were unable to extract error response, most likely some generic error
            // that we won't handle here
            if (!content) {
                return [];
            }

            // if we failed the JSON parser, it probably means that response is a plain string
            let data: any = null;
            try {
                data = JSON.parse(content);
            } catch (_) {
                return [content];
            }

            if (Array.isArray(data) && data.length > 0 && isString(data[0])) {
                return data;
            } else if (data.errors) {
                return data.errors;
            } else {
                // most likeyl it is a error object
                return data;
            }
        } catch (e) {
            return [];
        }
    } else if (error.errors && Array.isArray(error.errors)) {
        return error.errors;
    } else {
        return [];
    }
};

// Action creators are generated for each case reducer function
export const {
    notifyThemeLoaded,
    showLoading,
    hideLoading,
    clearError,
    toggleMenu
} = uiSlice.actions;

export default uiSlice.reducer;

interface Props {
    label: string
    type?: string
    errorKey: string
    errors: { [key: string]: any }
    register: { [key: string]: any }
}

export default function TextInput ({ label, type = 'text', errorKey, errors, register }: Props) {
    return (
        <div className="form-group">
            <label className="form-label">{label}</label>
            <input type={type} className={`form-control ${errors[errorKey] ? 'is-invalid' : ''}`} placeholder={label} {...register} />
            {errors[errorKey] && <div className="invalid-feedback">{errors[errorKey].message}</div>}
        </div>
    );
}

import { Orientation, ProductLineModel, ProductModel } from 'api/api';
import { QuoteWizardState } from '..';

export function createCarrierProductLine (carrier: ProductModel): ProductLineModel {
    const model: ProductLineModel = {
        productId: carrier.id,
        description: carrier.name,
        orientation: Orientation.Portrait,
        name: carrier.name,
        isCarrier: true,
        fields: [],
        writingContent: []
    };

    return model;
};

export const createContentProductLine = (state: QuoteWizardState): ProductLineModel => {
    return {
        productId: state.product!.id,
        orientation: state.contentSize!.width > state.contentSize!.height ? Orientation.Landscape : Orientation.Portrait,
        name: state.product!.name,
        description: state.product!.name,
        fields: state.tags,
        isCarrier: false,
        graphicsContent: state.graphicsPlacement.map(graphics => {
            return {
                imageUrl: graphics.path,
                width: Math.round(graphics.width),
                height: Math.round(graphics.height),
                x: Math.round(graphics.x),
                y: Math.round(graphics.y)
            };
        }),
        writingContent: [{
            width: state.contentPlacement?.width || state.contentSize!.width - state.contentSize!.marginX * 2,
            offsetX: state.contentPlacement?.x || state.contentSize!.marginX,
            offsetY: state.contentPlacement?.y || state.contentSize!.marginY,
            text: state.content
        }],
        parentLineId: state.parentLineId
    };
};

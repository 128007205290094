import { FieldModel, FieldType } from 'api/api';
import Tag from 'models/Tag';

export function importFieldsToContent (content: string | undefined, fields: FieldModel[]) {
    const fieldMapping = new Map<string, Tag>();
    (fields || []).forEach(field => fieldMapping.set(field.name!, { title: field.defaultValue!, value: field.name! }));

    const replacer = (substring: string, param1: string): string => {
        const tag = fieldMapping.get(param1);
        return `[[${JSON.stringify(tag)}]]`;
    };

    return (content || '').replace(/{{([^}]+)}}/gi, replacer);
}

export function extractFieldsAndFormatContent (content: string | undefined) {
    if (!content) {
        return;
    }

    const fields = new Map<string, FieldModel>();
    const matches = content.match(/\[\[[^\]]*(?<="title":")([^"]*)[^\]]*]]/gi);
    (matches || []).forEach(match => {
        const data = JSON.parse(match.substring(2, match.length - 2));
        fields.set(data.value, {
            name: data.value,
            defaultValue: data.title,
            type: FieldType.Text
        });
    });

    const escapedContent = content.replace(/\[\[[^\]]*(?<="value":")([^"]*)[^\]]*]]/gi, '{{$1}}');

    return {
        content: escapedContent,
        fields: Array.from(fields.values())
    };
}

export function extractContentForPreview (content: string | undefined) {
    if (!content) {
        return;
    }
    return content.replace(/\[\[[^\]]*(?<="title":")([^"]*)[^\]]*]]/gi, '$1');
}

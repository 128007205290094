import ErrorBlock from 'components/elements/ErrorBlock';
import { ActionP0 } from 'helpers/functionTypes';
import { useAppSelector } from 'hooks';

interface Props {
    previousAction?: ActionP0
    nextAction?: ActionP0
    showPreviousLink?: boolean
    showNextLink?: boolean
    disableNextLink?: boolean
    disablePreviousLink?: boolean
    nextStepText?: string
    previousStepText?: string
    showLoader?: boolean
}

export default function StepFooter ({
    previousAction, nextAction,
    showPreviousLink = true, showNextLink = true,
    disableNextLink = false,
    disablePreviousLink = false,
    previousStepText = 'Previous step',
    nextStepText = 'Next step',
    showLoader = true
}: Props) {
    const { isLoading, errorContent, errorMessage } = useAppSelector(state => state.ui);
    return (
        <div className="d-flex justify-content-end align-items-center mt-5">
            {showPreviousLink &&
                <button
                    onClick={() => { previousAction && previousAction(); }}
                    disabled={disablePreviousLink}
                    className="btn btn-outline-secondary mr-auto">
                    {previousStepText}
                </button>}
            {isLoading && showLoader
                ? (<div className="d-flex">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>)
                : (<div className="d-flex align-items-center">
                    {errorContent && <ErrorBlock errorObject={errorContent} errorMessage={errorMessage} />}
                    {showNextLink &&
                        <button
                            onClick={() => { !isLoading && nextAction && nextAction(); }}
                            className="btn btn-primary"
                            disabled={isLoading || disableNextLink}>
                            {nextStepText}
                        </button>}
                </div>)
            }
        </div>
    );
}

import Joi, { AnySchema, ObjectSchema } from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import ErrorMessages from 'config/validationMessages.json';

export function getJoiValidationSchema (schema: {[key: string]: AnySchema}): ObjectSchema {
    return Joi.object(schema).prefs({
        messages: ErrorMessages
    });
}

export function getJoiResolver (schema: ObjectSchema) {
    return joiResolver(schema);
}

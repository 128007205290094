import { useEffect, useRef } from 'react';

export function isNumber (value: any) {
    return typeof value === 'number' && isFinite(value);
}

export function isString (value: any) {
    return typeof value === 'string' || value instanceof String;
}

export function toMoney (value: number) {
    return (value).toLocaleString(undefined, { minimumFractionDigits: 2 });
}

export function toLongMoney (value: number) {
    return (value).toLocaleString(undefined, { minimumFractionDigits: 4 });
}

export function toDateTime (date: Date) {
    return date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
}

export function toDate (date: Date) {
    return date.toLocaleString('en-gb', { year: 'numeric', month: 'numeric', day: 'numeric' });// .replace(',', '');
}

export function toTime (date: Date) {
    return date.toLocaleString('en-gb', { hour: '2-digit', minute: '2-digit' });// .replace(',', '');
}

export async function confirm (message: string) {
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            if (window.confirm(message)) {
                resolve(true);
            } else {
                resolve(false);
            }
        }, 100);
    });
}

export function useTraceUpdate (props : {[key: string] : any}) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {} as {[key: string] : any});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

export function downloadFile (file: Blob, fileName: string) {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
}

export function getWordsUnit (wordCount: number) {
    return `word${(wordCount > 1 ? 's' : '')}`;
}

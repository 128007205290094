import { useEffect, useState } from 'react';

interface Button {
    title: string,
    value: string
}

interface Props {
    className?: string
    label: string
    values: Button[]
    selectedValue: string,
    onChange: (value: any) => void
    buttonClass?: string
    buttonProps: { [key: string]: any }
}

export default function StateGroup ({ className, label, values, buttonClass, buttonProps, selectedValue, onChange }: Props) {
    const [selected, setSelected] = useState<string>('');

    const changeValue = (value: string) => {
        setSelected(value);
        onChange(value);
    };

    useEffect(() => { 
        setSelected(selectedValue); 
    }, [selectedValue]);

    return (
        <div className={`btn-group ${className || ''}`} role="group" aria-label={label}>
            {values.map(val => (
                <button
                    key={val.value}
                    {...buttonProps}
                    type="button"
                    onClick={() => changeValue(val.value)}
                    className={`btn ${buttonClass || 'btn-primary'}${selected === val.value ? ' active' : ' disabled'}`}>
                    {val.title}
                </button>
            ))}
        </div>
    );
};

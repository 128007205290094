interface Props {
    title: string | null,
    description?: string | undefined,
    children: any,
}

export default function Quote ({ title, description, children }: Props) {
    return (
        <div className="card">
            { (title || description) && <div className="card-header">
                <h5 className={`card-title ${description ? '' : 'mb-0'}`}>{title}</h5>
                {description ? <h6 className="card-subtitle text-muted"></h6> : <></>}
            </div>}
            <div className="card-body">
                {children}
            </div>
        </div>
    );
}

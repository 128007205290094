/* eslint-disable react/jsx-key */
import Card from 'components/elements/Card';
import LoadingContainer from 'components/elements/LoadingContainer';
import Page from 'components/Page';
import { getJoiResolver } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { FormFields, formSchema, initialize, save, reset as resetData } from './accountEditSlice';
import StateGroup from 'components/elements/StateGroup';
import TextInput from 'components/elements/form-fields/TextInput';
import Button from 'components/elements/page-actions/Button';
import { push } from 'connected-react-router';
import LoadingCover from 'components/elements/LoadingCover';
import { isString } from 'helpers/general';
import DropDown from '../../elements/form-fields/DropDown';
import { AccountModel, CountryModel } from '../../../api/api';
import { RootStoreDispatch } from '../../../redux/store';
import { clearError, hideLoading, showError, showLoading } from '../../../redux/ui';
import { client } from '../../../api';

export default function AccountEdit () {
    const { accountId } = useParams<{ accountId?: string }>();
    const { initialized, initialData } = useAppSelector(state => state.accountEdit);
    const errorData = useAppSelector(store => store.ui.errorContent);
    const dispatch = useAppDispatch();
    const [countries, setCountries] = useState<CountryModel[]>([]);
    const [resellers, setResellers] = useState<AccountModel[]>([]);
    const { control, register, reset, handleSubmit, formState: { errors } } = useForm<FormFields>({
        resolver: getJoiResolver(formSchema)
    });

    useEffect(() => {
        dispatch(initialize(accountId));

        getCountries(dispatch)
            .then(countries => setCountries(countries));

        getSellers(dispatch)
            .then(sellers => setResellers(sellers));

        return () => {
            dispatch(resetData());
        };
    }, []);

    useEffect(() => {
        reset({ ...initialData });
    }, [initialData]);

    return (
        <Page title={accountId ? 'Edit Account' : 'Create Account'} actions={[
            <Button title="Cancel" key="cancel" className="btn-secondary" action={() => dispatch(push('/accounts'))} />,
            <Button title="Save" key="save" action={() => handleSubmit(fields => dispatch(save(fields)))()} />
        ]}>
            {!initialized
                ? <Card title=""><LoadingContainer> </LoadingContainer></Card>
                : <>
                    {errorData && <>
                        {(Array.isArray(errorData))
                            ? errorData.map(error => <div className="text-danger mb-2">{error}</div>)
                            : (errorData.title)
                                ? (<div className="text-danger mb-2">{errorData.title}</div>)
                                : Object.entries(errorData)
                                    .map(([key, value]) =>
                                        Array.isArray(value)
                                            ? value.map(val => <div className="text-danger mb-2">{key}: {val}</div>)
                                            : (isString(value)) && <div className="text-danger mb-2">{key}: {value}</div>
                                    )
                        }
                    </>}
                    <div className="d-block">
                        <LoadingCover>
                            <form>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <Card title="General data">
                                            <TextInput label="Name" errorKey="name" errors={errors} register={register('name')} />
                                            <TextInput label="First Name" errorKey="firstName" errors={errors} register={register('firstName')} />
                                            <TextInput label="Last Name" errorKey="lastName" errors={errors} register={register('lastName')} />
                                            <TextInput label="Email" type="email" errorKey="email" errors={errors} register={register('email')} />
                                            <div className="form-group">
                                                <label className="form-label mr-3">Reseller</label>
                                                <Controller
                                                    control={control}
                                                    name="resellerId"
                                                    render={({ field: { value, onChange, ref } }) => {
                                                        return (<DropDown
                                                            className={`d-block ${errors.resellerId ? 'is-invalid' : ''}`}
                                                            values={resellers.map(reseller => ({ title: reseller.name || '', value: `${reseller.id}` }))}
                                                            selectedValue={`${value}`}
                                                            onChange={(value) => { onChange(parseInt(value)); }}
                                                        />);
                                                    }} />
                                                {errors.billingCountryCode && <div className="invalid-feedback">{errors.billingCountryCode.message}</div>}
                                            </div>
                                            
                                            <div className="form-group">
                                                <label className="form-label mr-3">Enforce Balance</label>
                                                <Controller
                                                    control={control}
                                                    name="enforceBalance"
                                                    render={({ field: { value, onChange } }) =>
                                                        <StateGroup
                                                            className={`d-block ${errors.enforceBalance ? 'is-invalid' : ''}`}
                                                            label="Enforce Balance"
                                                            values={[{ title: 'Yes', value: 'true' }, { title: 'No', value: 'false' }]}
                                                            selectedValue={value === true ? 'true' : (value === false ? 'false' : '')}
                                                            onChange={value => onChange(value === 'true')}
                                                            buttonClass="btn-secondary"
                                                            buttonProps={register('enforceBalance')} />
                                                    } />
                                                {errors.enforceBalance && <div className="invalid-feedback">{errors.enforceBalance.message}</div>}
                                            </div>
                                            <TextInput label="Affiliate Comission" errorKey="affiliateComission" errors={errors} register={register('affiliateComission')} />
                                            <div className="form-group">
                                                <label className="form-label mr-3">Is Test</label>
                                                <Controller
                                                    control={control}
                                                    name="isTest"
                                                    render={({ field: { value, onChange } }) =>
                                                        <StateGroup
                                                            className={`d-block ${errors.isTest ? 'is-invalid' : ''}`}
                                                            label="Is Test"
                                                            values={[{ title: 'Yes', value: 'true' }, { title: 'No', value: 'false' }]}
                                                            selectedValue={value === true ? 'true' : (value === false ? 'false' : '')}
                                                            onChange={value => onChange(value === 'true')}
                                                            buttonClass="btn-secondary"
                                                            buttonProps={register('isTest')} />
                                                    } />
                                                {errors.isTest && <div className="invalid-feedback">{errors.isTest.message}</div>}
                                            </div>
                                        </Card>
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <Card title="Billing address">
                                            <TextInput label="First Name" errorKey="billingFirstName" errors={errors} register={register('billingFirstName')} />
                                            <TextInput label="Last Name" errorKey="billingLastName" errors={errors} register={register('billingLastName')} />
                                            <TextInput label="Line 1" errorKey="billingLine1" errors={errors} register={register('billingLine1')} />
                                            <TextInput label="Line 2" errorKey="billingLine2" errors={errors} register={register('billingLine2')} />
                                            <TextInput label="Town" errorKey="billingTown" errors={errors} register={register('billingTown')} />
                                            <TextInput label="Post Code" errorKey="billingPostCode" errors={errors} register={register('billingPostCode')} />
                                            <div className="form-group">
                                                <label className="form-label mr-3">Country</label>
                                                <Controller
                                                    control={control}
                                                    name="billingCountryCode"
                                                    render={({ field: { value, onChange, ref } }) => {
                                                        return (<DropDown
                                                            className={`d-block ${errors.billingCountryCode ? 'is-invalid' : ''}`}
                                                            values={countries.map(country => ({ title: country.name || '', value: `${country.alpha3}` }))}
                                                            selectedValue={`${value}`}
                                                            onChange={(value) => { onChange(value); }}
                                                        />);
                                                    }} />
                                                {errors.billingCountryCode && <div className="invalid-feedback">{errors.billingCountryCode.message}</div>}
                                            </div>
                                        </Card>
                                    </div>

                                </div>
                            </form>
                        </LoadingCover>
                    </div>
                </>
            }
        </Page >);
}

const getCountries = async function (dispatch: RootStoreDispatch) {
    dispatch(showLoading());
    dispatch(clearError());

    let countries: CountryModel[] = [];

    try {
        countries = await client().shippingProfile_GetCountries();
    } catch (error) {
        dispatch(showError(error));
    }

    countries.sort((a, b) => {
        const val1 = a.name || '';
        const val2 = b.name || '';
        return val1 < val2 ? -1 : (val1 > val2 ? 1 : 0);
    });
    dispatch(hideLoading());
    return countries;
};

const getSellers = async function (dispatch: RootStoreDispatch) {
    dispatch(showLoading());
    dispatch(clearError());

    let sellers: CountryModel[] = [];

    try {
        sellers = await client().account_GetAll(null, true);
    } catch (error) {
        dispatch(showError(error));
    }

    sellers.sort((a, b) => {
        const val1 = a.name || '';
        const val2 = b.name || '';
        return val1 < val2 ? -1 : (val1 > val2 ? 1 : 0);
    });
    dispatch(hideLoading());
    return sellers;
};

import { isString } from 'helpers/general';
import { ErrorContenet } from 'redux/ui';

interface Props {
    errorObject?: ErrorContenet
    errorMessage?: string
}

function extractErrorData (errorObject: ErrorContenet | undefined, errorMessage?: string) {
    const isNull = !errorObject;
    const arrayData = Array.isArray(errorObject) ? errorObject : [];
    const objectData = errorObject ? Object.entries(errorObject) : [];

    function extractMessage (): string {
        if (isNull || (arrayData.length === 0 && objectData.length === 0 && !(errorObject as any)?.errorMessage)) {
            return errorMessage || '';
        } else if ((errorObject as any)?.errorMessage) {
            return (errorObject as any)?.errorMessage;
        } else if (arrayData.length === 1) {
            return isString(arrayData[0])
                ? arrayData[0]
                : ((arrayData[0] as any).errorMessage || errorMessage);
        } else if (objectData.length === 1) {
            if (isString(objectData[0][1]) || (objectData[0][1] as any).errorMessage) {
                return objectData.map(val => // val[0] + ': ' +
                    (isString(val[1]) ? val[1] : val[1].errorMessage || 'unidentified error'))[0];
            } else if (Array.isArray(objectData[0][1]) && (objectData[0][1] as any[]).length === 1) {
                return objectData.map(val => // val[0] + ': ' +
                    (isString(val[1][0]) ? val[1][0] : val[1][0].errorMessage || 'unidentified error'))[0];
            } else {
                return errorMessage || '';
            }
        } else {
            return errorMessage || '';
        }
    }

    function extractData () {
        // if single error is found in data, then it is already shown on message
        if (isNull || !(arrayData.length > 1 || (objectData.length > 1 && !(errorObject as any)?.errorMessage))) {
            return [];
        } else if (arrayData.length > 1) {
            return arrayData
                .map(data => isString(data) ? data : (data as any).errorMessage)
                .filter(data => data);
        } else if (objectData.length > 1 && !(errorObject as any)?.errorMessage) {
            return objectData.reduce<string[]>((aggr, val) => {
                if (Array.isArray(val[1])) {
                    (val[1] as any[]).forEach(arrVal => {
                        aggr.push(/* val[0] + ': ' + */(isString(arrVal) ? arrVal : arrVal.errorMessage || 'unidentified error'));
                    });
                } else if (isString(val[1])) {
                    aggr.push(/* val[0] + ': ' + */val[1]);
                } else if (val[1].errorMessage) {
                    aggr.push(/* val[0] + ': ' + */val[1].errorMessage);
                }
                return aggr;
            }, []);
        }
        return [];
    }

    return {
        message: extractMessage(),
        data: extractData()
    };
}

export default function ErrorBlock ({ errorObject, errorMessage }: Props) {
    const errorData = extractErrorData(errorObject, errorMessage);
    const hasData = !!errorData.data.length;
    const multiErrorMessage = 'An error occurred. Please click here to see details.';
    return (
        <>
            <span
                style={{ cursor: hasData ? 'pointer' : 'default' }}
                className="text-danger mr-3"
                data-toggle={hasData ? 'modal' : undefined}
                data-target={hasData ? '#errorModel' : undefined}>
                {hasData ? multiErrorMessage : errorData.message || ''}
                {hasData && <i className="ml-2 fas fa-info-circle"></i>}
            </span>
            {hasData &&
                <div className="modal fade" id="errorModel" tabIndex={-1} aria-labelledby="errorModelLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-danger" id="errorModelLabel">Errors</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body text-danger">
                                <ul>
                                    {(errorData.data || []).map((e, i) => <li key={i}>{e}</li>)}
                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>);
}

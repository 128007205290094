import { ActionP1 } from 'helpers/functionTypes';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import style from './ContentPreview.module.css';

interface Props {
    onDropped: ActionP1<File>
}

export default function DropZone ({ onDropped }: Props) {
    const onDrop = useCallback(acceptedFiles => {
        (acceptedFiles as Array<File>).forEach(file => {
            onDropped(file);
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['image/jpeg', 'image/png'] });

    return (<>
        <label className="form-label w-100">Add graphics / logo</label>
        <div className={`${style.dropZone} mb-4`} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive
                    ? <div className={style.dropZoneText}>Drop the files here ...</div>
                    : <div className={style.dropZoneText}>Drop files to upload <br />(or click)</div>
            }
        </div>
    </>);
};

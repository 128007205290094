import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import { client } from 'api';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { DeliveryType, QuoteListModel, QuoteStatus } from 'api/api';
import { TableData } from './models';
import { push } from 'connected-react-router';
import { isNumber, toMoney } from 'helpers/general';

export interface QuotesListState {
    quotes: TableData[]
}

const initialState: QuotesListState = {
    quotes: []
};

export const quotesListSlice = createSlice({
    name: 'quotesList',
    initialState,
    reducers: {
        reset: () => initialState,
        setQuotes: (state, action: PayloadAction<TableData[]>) => {
            state.quotes = action.payload;
        }
    }
});

export const initialize = (): AppThunk => async (dispatch, getState) => {
    const actions = quotesListSlice.actions;

    dispatch(clearError());
    dispatch(showLoading());

    try {
        const quotes = (await client()
            .quote_GetAll())
            .map(q => toTableData(q));
        dispatch(actions.setQuotes(quotes));
    } catch (error) {
        dispatch(showError(error));
    }

    dispatch(hideLoading());
};

export const openQuote = (id: number): AppThunk => async (dispatch, getState) => {
    dispatch(push('/quotes/' + id));
};

function toTableData (model: QuoteListModel): TableData {
    const company = model.companyName;
    const name = `${model.firstName || ''} ${model.lastName || ''}`;
    return {
        id: model.id || 0,
        quoteNo: model.quoteNo || '',
        prospect: [company, name, model.name].filter(f => f).join(', '),
        copies: model.quantity || 0,
        deliveryType: model.deliveryType ? (model.deliveryType === DeliveryType.MailOut ? 'Mail Out' : 'Return to me') : '',
        status: model.status || '-',
        createdDate: model.created ? new Date(model.created + 'Z') : undefined,
        total: isNumber(model.totalNet) ? '£' + toMoney(model.totalNet || 0) : '-',
        statusColor: model.status ? statusToColor(model.status) : ''
    };
}

function statusToColor (status: QuoteStatus): string {
    switch (status) {
        case QuoteStatus.Sent: return 'success';
        // case QuoteStatus.Rejected // TODO: Find out abuot "rejected" status
        case QuoteStatus.Draft: return '';
        case QuoteStatus.Prepared: return 'light';
        case QuoteStatus.Ordered: return 'secondary';
        case QuoteStatus.Expired: return 'warning';
        case QuoteStatus.Deleted: return '';
    }
}

export const { reset } = quotesListSlice.actions;

export default quotesListSlice.reducer;

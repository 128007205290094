import { useAppSelector } from 'hooks';
import Logo from 'components/SideMenu/Logo';
import MenuHeader from 'components/SideMenu/MenuHeader';
import MenuContainer from 'components/SideMenu/MenuContainer';
import MenuItem from 'components/SideMenu/MenuItem';
import { Link } from 'react-router-dom';
import { useThemeLoaded } from 'themeLoader';

export default function SideMenu () {
    const isAuthenticated = useAppSelector(store => store.user.hasUser);
    const showMenu = useAppSelector(store => store.ui.showMenu);

    // manually implemented same actions for "sidebar collapse" logic from theme app.js file
    useThemeLoaded(() => {
        const sidebar = window.$('.sidebar')[0] as HTMLElement;
        if (sidebar) {
            sidebar.addEventListener('transitionend', function () {
                window.dispatchEvent(new Event('resize'));
            });
        }
    }, []);

    return (
        <nav id="sidebar" className={`sidebar${!showMenu ? ' collapsed' : ''}`}>
            <div className="sidebar-content">
                <Link className="sidebar-brand" to="/">
                    <Logo />
                </Link>

                {isAuthenticated &&
                    <MenuContainer>
                        <MenuHeader title="Sales" />
                        <MenuItem title="Accounts" icon="book-open" link="/accounts" />
                        <MenuItem title="Quotes" icon="book-open" link="/quotes" />
                    </MenuContainer>
                }
            </div>
        </nav>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import { client } from 'api';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { AccountModel } from 'api/api';
import { TableData } from './models';
import { push } from 'connected-react-router';

export interface AccountsListState {
    accounts: TableData[]
}

const initialState: AccountsListState = {
    accounts: []
};

export const accountsListSlice = createSlice({
    name: 'accountsList',
    initialState,
    reducers: {
        reset: () => initialState,
        setAccounts: (state, action: PayloadAction<TableData[]>) => {
            state.accounts = action.payload;
        }
    }
});

export const initialize = (): AppThunk => async (dispatch, getState) => {
    const actions = accountsListSlice.actions;

    dispatch(clearError());
    dispatch(showLoading());

    try {
        const accounts = (await client()
            .account_GetAll(null, null, null, null, null, 10, undefined, undefined))
            .map(q => toTableData(q));
        dispatch(actions.setAccounts(accounts));
    } catch (error) {
        dispatch(showError(error));
    }

    dispatch(hideLoading());
};

export const openAccount = (id: number): AppThunk => async (dispatch, getState) => {
    dispatch(push('/accounts/' + id));
};

function toTableData (model: AccountModel): TableData {
    const contactName = `${model.firstName || ''} ${model.lastName || ''}`;
    return {
        id: model.id || 0,
        name: model.name || '',
        contact: contactName,
        reseller: model.reseller?.name || ''
    };
}

export const { reset } = accountsListSlice.actions;

export default accountsListSlice.reducer;

import { ActionP1 } from 'helpers/functionTypes';
import { StationeryProvider } from 'models/types';
import ElementBlocker from 'components/elements/ElementBlocker';

interface Props {
    completeCallback: ActionP1<StationeryProvider>
}

export default function StationeryProvided ({ completeCallback }: Props) {
    return (
        <div className="row">
            <div className="col-6">
                <a onClick={() => completeCallback('ProvidedByUs')} className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0 text-left"><i className="mr-2 fas fa-fw fa-check"></i>Stationery provided by us</h5>
                    </div>
                </a>
            </div>

            <div className="col-6">
                <a onClick={() => completeCallback('SupplyMyOwn')} className="card position-relative">
                    <div className="card-header">
                        <h5 className="card-title mb-0 text-left"><i className="mr-2 fas fa-fw fa-pencil-alt"></i>I will supply my own</h5>
                    </div>
                    <ElementBlocker />
                </a>
            </div>
        </div>
    );
}

export default class ContentSize {
    constructor (
        readonly sizeId: number,
        readonly type: string,
        readonly title: string,
        readonly width: number,
        readonly height: number,
        readonly marginX: number,
        readonly marginY: number
    ) { }
}

import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

interface Props {
    title: string,
    link: string,
    icon?: string
}

export default ({ title, link, icon }: Props) => {
    const match = useRouteMatch({
        path: link,
        exact: true
    });

    return (
        <li className={`sidebar-item ${match ? 'active' : ''}`}>
            <Link className="sidebar-link" to={link}>
                <i className="align-middle" data-feather={icon}></i>
                <span className="align-middle">{title}</span>
            </Link>
        </li>
    );
};

import React from 'react';

interface Params {
    children?:
        | React.ReactChild
        | React.ReactChild[];
}

export default function ActionsGroup ({ children }: Params) {
    return <div className="actions btn-group">
        <button type="button" className="btn btn-link py-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-ellipsis-h"></i>
        </button>
        <div className="dropdown-menu dropdown-menu-left mt-0">
            {children}
        </div>
    </div>;
}

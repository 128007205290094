import { ActionP1 } from 'helpers/functionTypes';
import { ProductModel } from 'api/api';
import { useAppDispatch, useAppSelector } from 'hooks';
import ContentSize from 'models/ContentSize';
import { useEffect } from 'react';
import { initialize } from './productChoiceSlice';

interface Props {
    contentSize: ContentSize | undefined
    completeCallback: ActionP1<ProductModel>
}

export default function ProductChoice ({ contentSize, completeCallback }: Props) {
    const dispatch = useAppDispatch();
    const products = useAppSelector(state => state.productChoiceSection.data);
    const initialized = useAppSelector(state => state.productChoiceSection.initialized);

    useEffect(() => {
        if (contentSize) {
            dispatch(initialize(contentSize));
        }
    }, []);

    return (
        <>
            <div className="row">
                {(initialized && products && products.length)
                    ? products.map(product => (
                        <div key={product.id!} className="col-6">
                            <a onClick={() => completeCallback(product)} className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0 text-left">{product.name}</h5>
                                </div>
                            </a>
                        </div>
                    ))
                    : <div className="col-12">No Product found</div>}
            </div>
        </>
    );
}

/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import {
    ApiException,
    ModelBase
} from './api';

export class BackendClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor (baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance || axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    searchContact(search?: string | null | undefined, cancelToken?: CancelToken | undefined): Promise<ContactModel[]> {
        let url_ = this.baseUrl + "/pipedrive/contacts/";
        if (search !== undefined && search !== null){
            url_ += encodeURIComponent("" + search);
        }

        let options_ = <AxiosRequestConfig>{
            method: "POST",
            url: url_,
            headers: {
                "Accept": "application/json"
            },
            cancelToken
        };

        return this.instance.request(options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processContact_GetAll(_response);
        });
    }

    protected processContact_GetAll(response: AxiosResponse): Promise<ContactModel[]> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = JSON.parse(resultData200);
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<ContactModel[]>(<any>null);
    }
}

export interface ContactModel extends ModelBase {
    firstName?: string | undefined;
    lastName?: string | undefined;
    companyName?: string | undefined;
    email?: string | undefined;
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}
import DataBlock from './DataBlock';
import style from './QuoteDetails.module.css';
import { QuoteDetailsModel } from './model';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clearNote, deleteProduct, removeService } from './quoteDetailsSlice';
import { isNumber } from 'helpers/general';
import OptionalLink from 'components/elements/OptionalLink';
import ActionItem from './ActionItem';
import { Link } from 'react-router-dom';
import ActionsGroup from './ActionsGroup';
import { quoteWizardSlice } from '../../quoteWizard/redux';
import { client } from '../../../api';
import { toContentSize } from '../../quoteWizard/redux/helpers';
import { Orientation } from '../../../api/api';
import { push } from 'connected-react-router';

interface Props {
    quote: QuoteDetailsModel
    isReadOnly: boolean
}

export default function DataTable ({ quote, isReadOnly }: Props) {
    const dispatch = useAppDispatch();
    const quoteState = useAppSelector(x => x.quote);
    const quoteWizardActions = quoteWizardSlice.actions;

    const tryToRemoveProduct = async (index: number | undefined) => {
        const shouldDelete = isNumber(index) && await confirm('Are you sure you wish to delete this item?');
        if (shouldDelete) {
            dispatch(deleteProduct(index!));
        }
    };

    const tryToClearNote = async () => {
        const shouldClear = await confirm('Are you sure you wish to clear the note?');
        if (shouldClear) {
            dispatch(clearNote());
        }
    };

    const tryToRemoveService = async (serviceId: number | undefined) => {
        const shouldRemove = serviceId && await confirm('Are you sure you wish to remove this service?');
        if (shouldRemove) {
            dispatch(removeService(serviceId!));
        }
    };

    const editQuantityLink = (quoteId: number) => `/quotes/${quoteId}/quantity`;
    const editProductLink = (quoteId: number, productIndex: number) => `/quotes/${quoteId}/editContent/${productIndex}`;
    const secondSideProductLink = (quoteId: number) => `/quotes/${quoteId}/editContent`;
    const addProductLink = (quoteId: number) => `/quotes/${quoteId}/content/`;
    const editEnvelopeLink = (quoteId: number, productIndex: number) => `/quotes/${quoteId}/envelopeChoice/${productIndex}`;
    const addEnvelopeLink = (quoteId: number) => `/quotes/${quoteId}/envelopeChoice`;
    const editServicesLink = (quoteId: number) => `/quotes/${quoteId}/additionalServices`;
    const editNoteLink = (quoteId: number) => `/quotes/${quoteId}/notes`;

    const onParentSelection = async (parentIndex: number) => {
        if (quoteState.quote.productLines && quoteState.quote.productLines[parentIndex]) {
            const parentLine = quoteState.quote.productLines[parentIndex];
            if (parentLine) {
                dispatch(quoteWizardActions.updateParentId(parentLine.id));
                const stationaryProduct = quoteState.products.find(x => x.id === parentLine.productId);
                if (stationaryProduct !== undefined) {
                    dispatch(quoteWizardActions.updateProduct(stationaryProduct));
                    const sizes = await client().product_GetProductSizes();
                    const contentSize = sizes.find(x => x.id === stationaryProduct.productSizeId);
                    if (contentSize) {
                        dispatch(quoteWizardActions.updateContentSize(toContentSize(contentSize, parentLine.orientation === Orientation.Landscape)));
                    }
                }

                dispatch(push(secondSideProductLink(quote.id)));
            }
        }
    };

    return (<div className="mx-n3">
        <table className='w-100'>
            <thead className="">
                <tr>
                    <th className="p-0" style={{ width: '60%' }}></th>
                    <th className="p-0" style={{ width: '10%' }}></th>
                    <th className="p-0" style={{ width: '30%' }}></th>
                </tr>
            </thead>
            <tbody>
                <DataBlock
                    records={[
                        {
                            key: <div style={{ paddingBottom: '6px' }}>
                                <span className={`${style.labelLevel2} ${style.paddingLevel1}`}>Total per</span>
                                <span className={`${style.labelLevel2}`}>
                                </span>
                                <OptionalLink link={editQuantityLink(quote.id)}
                                    isEnabled={!isReadOnly}>
                                    <span className={`${style.labelLevel2} ${style.editAction} ${!isReadOnly ? style.enabled : ''}`}>
                                        &nbsp;x&nbsp;{quote.quantity}&nbsp;
                                        <i className="fa-sm fas fa-pencil-alt"></i>
                                    </span>

                                </OptionalLink>
                            </div>,
                            value: <>
                                <div className={`${style.priceMain} mb-n3`}>{quote.totalPrice}</div>
                                <div className="small text-monospace">All prices are ex-VAT</div>
                            </>
                        },
                        {
                            key: <></>,
                            value: <div className={`${style.labelLevel4} ${quote.pricingLevel ? 'mt-2' : ''}`}>
                                {quote.pricingLevel}
                            </div>
                        }
                    ]} />
                <DataBlock
                    records={[
                        {
                            key: <>
                                <span className={`${style.labelLevel2} ${style.paddingLevel1} mr-2`}>Total per unit</span>
                                <span className="small text-monospace">ex delivery</span>
                            </>,
                            value: <div className={style.priceLevel1}>{quote.pricePerUnit}</div>
                        }
                    ]} />
                { /* Content */}
                <DataBlock noBottomSeperation={!(quote.products.data.length === 0 && isReadOnly)}
                    highlight={true}
                    records={[
                        {
                            key: <div className={`${style.labelLevel2} ${style.paddingLevel2}`}>What to send</div>,
                            value: <div className={style.priceLevel2}>{quote.products.totalPrice}</div>
                        }
                    ]} />
                {quote.products.data.map((product, index) => (
                    <React.Fragment key={index}>
                        <DataBlock noTopSeperation={index === 0} noBottomSeperation={true}
                            highlight={true}
                            records={[
                                {
                                    key: <div className={`${style.paddingLevel2} ${style.editable}`}>
                                        <OptionalLink
                                            callback={ () => { dispatch(quoteWizardActions.updateParentId(product.parentLineId)); } }
                                            link={editProductLink(quote.id, product.originalIndex!)}
                                            isEnabled={!isReadOnly}
                                            className={style.labelLevel3}>
                                            {product.title}
                                            &nbsp;
                                            {!isReadOnly && <i className="edit-icon fas fa-pencil-alt"></i>}
                                        </OptionalLink>
                                        {
                                            /* eslint-disable no-extra-boolean-cast */
                                            !isReadOnly && (!!product.parentLineId
                                                ? (<ActionsGroup>
                                                    <ActionItem action={() => tryToRemoveProduct(product.originalIndex)}
                                                        name={'Delete'}/>
                                                </ActionsGroup>)
                                                : (<ActionsGroup>
                                                    <ActionItem action={() => onParentSelection(product.originalIndex!)} name={'Add second side'}/>
                                                    <ActionItem action={() => tryToRemoveProduct(product.originalIndex)} name={'Delete'}/></ActionsGroup>))}
                                        
                                    </div>,
                                    value: <div className={style.priceLevel3}>{product.totalPrice}</div>
                                }
                            ]} />
                        <DataBlock noTopSeperation={true} noBottomSeperation={index === quote.products.data.length - 1 && !isReadOnly}
                            highlight={true}
                            records={
                                product.parts.map(part => ({
                                    key: <div className={`${style.labelLevel4} ${style.paddingLevel4}`}>{part.title}</div>,
                                    value: <div className={style.priceLevel4}>{part.value}</div>
                                }))
                                // TODO: add preview when api is available
                                // }
                                // preview={
                                //     <div style={{ width: 50, height: 70, background: 'gray' }} />
                            } />
                    </React.Fragment>
                ))}
                {!isReadOnly &&
                    <DataBlock noTopSeperation={true}
                        records={[
                            {
                                key: <Link className="btn btn-link text-monospace" to={addProductLink(quote.id)}><em>+ Add page</em></Link>
                            }
                        ]}
                    />
                }
                { /* Inserts */}
                {/* <DataBlock noBottomSeperation={!(quote.products.data.length === 0 && isReadOnly)}
                    records={[
                        {
                            key: <div className={`${style.labelLevel2} ${style.paddingLevel2}`}>What to insert</div>
                        }
                    ]} />
                <DataBlock noBottomSeperation={true}
                    records={[
                        {
                            key: <div className={`${style.labelLevel4} ${style.paddingLevel2}`}><em>no inserts selected</em></div>
                        }
                    ]} />
                <DataBlock
                    records={[
                        {
                            key: <a className="btn btn-link" onClick={(e) => { e.stopPropagation(); console.log('add insert'); }}><em>+ Add insert</em></a>
                        }
                    ]} /> */}
                { /* Carrier */}
                <DataBlock noBottomSeperation={!(quote.carrier.data.length === 0 && isReadOnly)}
                    highlight={true}
                    records={[
                        {
                            key: <div className={`${style.labelLevel2} ${style.paddingLevel2}`}>Carrier</div>,
                            value: <div className={style.priceLevel2}>{quote.carrier.totalPrice}</div>
                        }
                    ]} />
                {quote.carrier.data.length > 0 && quote.carrier.data.map(carrier =>
                    <React.Fragment key={carrier.originalIndex}>
                        <DataBlock noTopSeperation={true} noBottomSeperation={true}
                            highlight={true}
                            records={[
                                {
                                    key: <div className={`${style.paddingLevel2} ${style.editable}`}>
                                        <OptionalLink
                                            link={editEnvelopeLink(quote.id, carrier.originalIndex!)}
                                            isEnabled={!isReadOnly}
                                            className={style.labelLevel3}>
                                            {carrier.title}
                                            &nbsp;
                                            {!isReadOnly && <i className="edit-icon fas fa-pencil-alt"></i>}
                                        </OptionalLink>
                                        {!isReadOnly && <ActionsGroup><ActionItem name={'Delete'} action={() => tryToRemoveProduct(carrier.originalIndex)} /></ActionsGroup>}
                                    </div>,
                                    value: <div className={style.priceLevel3}>{carrier.totalPrice}</div>
                                }
                            ]} />
                        <DataBlock noTopSeperation={true}
                            highlight={true}
                            records={
                                carrier.parts.map(part => ({
                                    key: <div className={`${style.labelLevel4} ${style.paddingLevel4}`}>{part.title}</div>,
                                    value: <div className={style.priceLevel4}>{part.value}</div>
                                }))
                            } />
                    </React.Fragment>
                )}
                {!isReadOnly && quote.carrier.data.length === 0 &&
                    <DataBlock noTopSeperation={true}
                        records={[
                            {
                                key: <Link className="btn btn-link" to={addEnvelopeLink(quote.id)}><em>+ Add carrier</em></Link>
                            }
                        ]}
                    />
                }
                { /* Services */}
                <DataBlock noBottomSeperation={!(quote.services.parts.length === 0 && isReadOnly)}
                    highlight={true}
                    records={[
                        {
                            key: <div className={`${style.paddingLevel2} ${style.editable}`}>
                                <OptionalLink
                                    link={editServicesLink(quote.id)}
                                    isEnabled={!isReadOnly}
                                    className={style.labelLevel2}>
                                    Services&nbsp;
                                    {!isReadOnly && <i className="edit-icon fa-sm fas fa-pencil-alt"></i>}
                                </OptionalLink>
                            </div>,
                            value: <div className={style.priceLevel2}>{quote.services.totalPrice}</div>
                        }
                    ]} />
                <DataBlock noTopSeperation={true}
                    highlight={true}
                    records={quote.services.parts.length > 0
                        ? quote.services.parts.map(service => (
                            {
                                key: <div className={`${style.labelLevel3} ${style.paddingLevel2} ${style.editable}`}>
                                    {service.title}
                                    {!isReadOnly && !service.isReadOnly && service.id && <ActionsGroup><ActionItem action={() => tryToRemoveService(service.id)} name={'Delete'}/></ActionsGroup>}
                                </div>,
                                value: <div className={style.priceLevel3}>{service.value}</div>
                            }
                        ))
                        : [{ key: <div className={`${style.labelLevel3} ${style.paddingLevel2}`}>-</div> }]
                    } />
                { /* Additional Charges */}
                <DataBlock noBottomSeperation={true}
                    highlight={true}
                    records={[
                        {
                            key: <div className={`${style.labelLevel2} ${style.paddingLevel1}`}>Additional Charges</div>,
                            value: <div className={style.priceLevel2}>{quote.additionalCharges.totalPrice}</div>
                        }
                    ]} />
                <DataBlock noTopSeperation={true}
                    highlight={true}
                    records={quote.additionalCharges.parts.length > 0
                        ? quote.additionalCharges.parts.map(service => (
                            {
                                key: <div className={`${style.labelLevel3} ${style.paddingLevel1} ${style.editable}`}>
                                    {service.title}
                                    {!isReadOnly && !service.isReadOnly && service.id && <ActionsGroup><ActionItem action={() => tryToRemoveService(service.id)} name={'Delete'}/></ActionsGroup>}
                                </div>,
                                value: <div className={style.priceLevel3}>{service.value}</div>
                            }
                        ))
                        : [{ key: <div className={`${style.labelLevel3} ${style.paddingLevel1}`}>-</div> }]
                    } />
                { /* Notes */}
                <DataBlock noBottomSeperation={true}
                    records={[
                        {
                            key: <div className={`${style.paddingLevel1} ${style.editable}`}>
                                <OptionalLink
                                    link={editNoteLink(quote.id)}
                                    isEnabled={!isReadOnly}
                                    className={style.labelLevel2}>
                                    Notes&nbsp;
                                    {!isReadOnly && <i className="edit-icon fa-sm fas fa-pencil-alt"></i>}
                                </OptionalLink>
                                {!isReadOnly && quote.notes && <ActionsGroup><ActionItem action={() => tryToClearNote()} name={'Delete'}/></ActionsGroup>}
                            </div>
                        }
                    ]} />
                <DataBlock noTopSeperation={true} noBottomSeperation={true}
                    records={[
                        {
                            key: <div className={`${style.labelLevel4} ${style.paddingLevel1} ${style.preserveNewLines}`}>
                                {quote.notes || <em>no notes left</em>}
                            </div>
                        }
                    ]} />
            </tbody>
        </table>
    </div>);
}

import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import Card from 'components/elements/Card';
import { showLoading } from 'redux/ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import LoadingContainer from 'components/elements/LoadingContainer';
import Header from './Header';
import DataTable from './DataTable';
import {
    closeDetails,
    deleteQuote,
    downloadPDF,
    downloadProof,
    downloadQuoteTemplate,
    duplicateQuote,
    initialize,
    markAsOrdered,
    markAsPrepared,
    markAsRejected,
    markAsSent,
    reset,
    resyncQuote
} from './quoteDetailsSlice';
import LoadingCover from 'components/elements/LoadingCover';
import Button from 'components/elements/page-actions/Button';
import Page from 'components/Page';
import { RootStoreDispatch } from 'redux/store';
import { QuoteDetailsModel, QuoteStatus } from './model';
import DropDown, { ItemGroup } from 'components/elements/page-actions/DropDown';
import { quoteWizardSlice } from '../../quoteWizard/redux';

export default function QuoteDetails () {
    const dispatch = useAppDispatch();
    const { quote, isReadOnly, isDownloadReady, createdMessage, hasProducts } = useAppSelector(state => state.quoteDetails);
    const { params } = useRouteMatch<{
        quoteId: string
    }>();

    useEffect(() => {
        dispatch(showLoading());
        dispatch(quoteWizardSlice.actions.updateParentId(undefined));
        dispatch(initialize(params.quoteId));
        return () => {
            dispatch(reset());
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isDownloadReady) {
                dispatch(resyncQuote());
            } else {
                clearInterval(interval);
            }
        }, 15 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [quote]);

    return (
        <Page title={'Quote Details'} actions={quote ? getActions(quote, isDownloadReady, hasProducts, dispatch) : []}>
            {!quote
                ? <Card title={null}><LoadingContainer> </LoadingContainer></Card>
                : (<>
                    <Header quote={quote} isReadOnly={isReadOnly} isDownloadReady={isDownloadReady} hasProducts={hasProducts} />
                    <div className="row">
                        <div className="col-12">
                            <LoadingCover>
                                <>
                                    <Card title={null}>
                                        <DataTable
                                            isReadOnly={isReadOnly}
                                            quote={quote} />
                                    </Card>
                                    <em>{createdMessage}</em>
                                </>
                            </LoadingCover>
                        </div>
                    </div>
                </>)}
        </Page>
    );
}

const getActions = (quote: QuoteDetailsModel | null, isDownloadReady: boolean, hasProducts: boolean, dispatch: RootStoreDispatch): JSX.Element[] => {
    const tryToDeleteQuote = async () => {
        const shouldDelete = await confirm('Are you sure you want to delete this quote?');
        if (shouldDelete) {
            dispatch(deleteQuote());
        }
    };

    const tryToDuplicateQuote = async () => {
        const shouldDelete = await confirm('Are you sure you want to duplicate this quote?');
        if (shouldDelete) {
            dispatch(duplicateQuote());
        }
    };

    const actions = [
        (<Button className="btn-outline-secondary" key="close" title="Close" action={() => dispatch(closeDetails())} />)
    ] as JSX.Element[];

    if (quote?.status === QuoteStatus.Draft) {
        actions.push((<Button
            key="mark_prepared"
            title="Mark as Prepared"
            action={() => confirm('Are you sure you want to mark this quote as Prepared?') && dispatch(markAsPrepared())} />
        ));
    }

    if (quote?.status === QuoteStatus.Prepared) {
        actions.push((<Button
            key="mark_sent"
            title="Mark as Sent"
            action={() => confirm('Are you sure you want to mark this quote as Sent?') && dispatch(markAsSent())} />));
    }

    if (quote?.status === QuoteStatus.Sent) {
        actions.push((<Button
            className="btn-danger"
            key="mark_rejected"
            title="Mark as Rejected"
            action={() => confirm('Are you sure you want to mark this quote as Rejected?') && dispatch(markAsRejected())} />));
        actions.push((<Button
            className="btn-success"
            key="mark_ordered"
            title="Mark as Ordered"
            action={() => confirm('Are you sure you want to mark this quote as Ordered?') && dispatch(markAsOrdered())} />));
    }

    const dropDownItems: ItemGroup[] = [{ items: [] }];
    dropDownItems[0].items.push({
        title: 'Print', action: () => dispatch(downloadPDF()), enabled: isDownloadReady && hasProducts
    });

    dropDownItems[0].items.push({
        title: 'Download Proof', action: () => dispatch(downloadProof()), enabled: isDownloadReady && hasProducts
    });

    dropDownItems[0].items.push({
        title: 'Download Production File', action: () => dispatch(downloadQuoteTemplate())
    });

    dropDownItems[0].items.push({
        title: 'Duplicate',
        action: () => tryToDuplicateQuote()
    });

    dropDownItems[0].items.push({
        title: 'Delete', 
        action: () => tryToDeleteQuote(), 
        enabled: (quote?.status === QuoteStatus.Draft || quote?.status === QuoteStatus.Prepared || quote?.status === QuoteStatus.Sent)
    });

    actions.push(<DropDown key="other_options" buttonClassName="btn-transparent" icon="fas fa-ellipsis-h" items={dropDownItems} />);

    return actions;
};

import { ActionP0 } from 'helpers/functionTypes';

interface Params {
    action: ActionP0,
    name: string
}

export default function ActionItem ({ action, name }: Params) {
    return <button onClick={() => action()} className="dropdown-item" type="button">{ name }</button>;
}

import Card from 'components/elements/Card';
import Page from 'components/Page';
import QuoteWizardPath from 'components/quoteWizard/QuoteWizardPath';
import { QuoteWizardState } from 'components/quoteWizard/redux';
import { useAppSelector } from 'hooks';
import { useRouteMatch } from 'react-router';

export default function QuoteEditPage () {
    const { currentStep, initialized } = useAppSelector(state => state.quoteWizard);

    const urlResolver = (state: QuoteWizardState, quoteId: number | undefined) => {
        const { productLineIndex, currentStep } = state;
        const linkParts = [
            'quotes',
            quoteId,
            currentStep!.key,
            productLineIndex
        ].filter(line => line || line === 0);
        return `/${linkParts.join('/')}`;
    };

    const { params } = useRouteMatch<{
        quoteId?: string,
        step?: string,
        productIndex?: string
    }>();

    return (
        <Page title="Quote">
            <div className="row">
                <div className="col-12">
                    <Card title={(initialized && currentStep) ? currentStep.title : ''}>
                        <QuoteWizardPath
                            {...params}
                            sendingOption={'write-letter'}
                            urlResolver={urlResolver}
                            callback={'quoteDetails'} />
                    </Card>
                </div>
            </div>
        </Page>
    );
}

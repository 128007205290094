import { ActionP0 } from 'helpers/functionTypes';
import $ from 'jquery';
import { useAppSelector } from 'hooks';
import { } from 'redux/ui';
import { useEffect } from 'react';

function addBodyAttributes () {
    $(document.body)
        .attr('data-theme', 'default')
        .attr('data-layout', 'fluid')
        .attr('data-sidebar-position', 'left')
        .attr('data-sidebar-behavior', 'sticky');
}

function loadScript (src: string, onComplete?: ActionP0) {
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
    if (onComplete) {
        script.onload = onComplete;
    }
}

function triggerDocumentLoadedEvent (doneCallback: ActionP0) {
    const DOMContentLoadedEvent = document.createEvent('Event');
    DOMContentLoadedEvent.initEvent('DOMContentLoaded', true, true);
    window.document.dispatchEvent(DOMContentLoadedEvent);

    setTimeout(() => {
        doneCallback();
    }, 0);
}

export default function themeLoader (doneCallback: ActionP0) {
    addBodyAttributes();
    loadScript('js/app.js', () => {
        triggerDocumentLoadedEvent(doneCallback);
    });
}

export function useThemeLoaded (action: ActionP0, listenables?: any[] | undefined) {
    const themeLoaded = useAppSelector((state) => state.ui.themeLoaded);

    useEffect(() => {
        if (themeLoaded) {
            return action();
        }
    }, [...(listenables || []), themeLoaded]);
}

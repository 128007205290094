import { ActionP0, ActionP1, ActionP2 } from 'helpers/functionTypes';
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
import { useEffect, useRef, useState } from 'react';
import Tagify, { TagData, TagifySettings } from '@yaireo/tagify';
import style from './EditContent.module.css';
import TagButton from '../../ui-components/TagButton';
import Tag from 'models/Tag';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateTags, triggerDelayedPreview, cancelDelayedPreview, reset, initialize } from './editContentSlice';
import Preview from './Preview';
import { FileResponse, ProductModel } from 'api/api';
import ContentSize from 'models/ContentSize';
import RectData from 'models/RectData';

interface Props {
    initialContent: string
    contentSize: ContentSize
    product: ProductModel
    contentRect: RectData | undefined
    additionalItemsRect: RectData[]
    completeTrigger: ActionP1<ActionP0>
    completeCallback: ActionP2<string, FileResponse | undefined>
    syncCallback: ActionP1<string>
}

function getContent (tagify: Tagify | undefined) {
    return tagify ? tagify.getMixedTagsAsString() : '';
}

function extractTags (content: string | undefined): Tag[] {
    const matches = (content || '').match(/\[\[[^\]]+\]\]/g);

    const tags: Tag[] = (matches || []).map(match => {
        const data = JSON.parse(match.substring(2, match.length - 2));
        return { value: data.value, title: data.title };
    });

    return tags;
}

function joinTags (initialTags: Tag[], contentTags: Tag[]) {
    const initialTagsValues = new Map();
    (initialTags || []).forEach(t => initialTagsValues.set(t.value, t.title));

    const contentTagValues = new Map();
    (contentTags || []).forEach(t => contentTagValues.set(t.value, t.title));

    return [
        ...initialTags.map(tag => {
            return {
                value: tag.value,
                title: contentTagValues.has(tag.value) ? contentTagValues.get(tag.value) : tag.title
            };
        }),
        ...contentTags.filter(tag => !initialTagsValues.has(tag.value))
    ];
}

export default function EditContent ({ initialContent, contentSize, product, contentRect, additionalItemsRect, completeTrigger, completeCallback, syncCallback }: Props) {
    const contentTags = extractTags(initialContent);
    const [content] = useState<string>(initialContent);
    const initialTags = useAppSelector(state => state.editContentSection.tags);
    const preview = useAppSelector(state => state.editContentSection.preview);
    const [contentError, setContentError] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const tags = joinTags(initialTags, contentTags);
    const tagifyRef = useRef<Tagify>();

    const settings: TagifySettings = {
        pattern: /@/,
        dropdown: {
            enabled: 1,
            position: 'text',
            mapValueTo: 'value',
            highlightFirst: true
        },
        enforceWhitelist: true,
        duplicates: true,
        tagTextProp: 'title'
    };

    useEffect(() => {
        completeTrigger(() => {
            if (tagifyRef.current) {
                const content = getContent(tagifyRef.current);
                if (content) {
                    completeCallback(content, preview);
                } else {
                    setContentError('field must contain a value');
                }
            }
        });

        const interval = setInterval(() => syncCurrentContent(), 60 * 1000);
        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        dispatch(initialize({ contentSize, product, contentRect, additionalItemsRect }, content));
        return () => {
            dispatch(cancelDelayedPreview());
            dispatch(reset());
        };
    }, []);

    function syncCurrentContent () {
        if (tagifyRef.current) {
            const content = getContent(tagifyRef.current);
            syncCallback(content);
        }
    }

    function restartPreviewTimer () {
        const content = getContent(tagifyRef.current);
        dispatch(triggerDelayedPreview(content));
    }

    function insertTagInContent (tag: TagData, tagify: Tagify) {
        const tagElm = tagify.createTagElem({ value: tag.value, title: tag.title });
        try {
            tagify.injectAtCaret('');
            tagify.injectAtCaret(tagElm);
            tagify.placeCaretAfterNode(tagElm);
            restartPreviewTimer();
        } catch (e) {
            console.log(e);
        }
    }

    function removeTagFromContent (value: string, tagify: Tagify) {
        tagify.removeTags(
            tagify.getTagElms().filter(el => el.getAttribute('value') === value)
        );
        restartPreviewTimer();
    }

    function replaceTagInContent (original: string, value: string, title: string, tagify: Tagify) {
        tagify.getTagElms()
            .filter(el => el.getAttribute('value') === original)
            .forEach(el => tagify.replaceTag(el, { value, title }));
        restartPreviewTimer();
    }

    function addTag (title: string, value: string) {
        dispatch(updateTags([...tags, { title, value }]));
    }

    function deleteTag (value: string) {
        dispatch(updateTags(tags.filter(tag => {
            return tag.value !== value;
        })));

        if (tagifyRef.current) {
            removeTagFromContent(value, tagifyRef.current);
        }
    }

    function updateTag (originalValue: string, title: string, value: string) {
        dispatch(updateTags(tags.map(tag => {
            if (tag.value === originalValue) {
                return { value, title };
            } else {
                return tag;
            }
        })));

        if (tagifyRef.current) {
            replaceTagInContent(originalValue, value, title, tagifyRef.current);
        }
    }

    return (<>
        <div className="row mb-3">
            <div className="col-12">
                <TagButton
                    buttonClassName="btn btn-sm btn-outline-primary mt-1"
                    buttonIconClassName="fas fa-fw fa-plus"
                    onAccept={(title, value) => { addTag(title, value); }} />

                {tags.map((tag, index) => (
                    <div className="tag-wrapper btn-group btn-group-sm" key={index}>
                        <button className="btn btn-outline-primary border-0 m-1 add-text-tag"
                            onClick={() => {
                                if (tagifyRef.current) {
                                    insertTagInContent(tag, tagifyRef.current);
                                }
                            }}
                            type="button">{tag.value}</button>

                        <TagButton
                            initialTitle={tag.title}
                            initialValue={tag.value}
                            onAccept={(title, value) => { updateTag(tag.value, title, value); }}
                            onDelete={() => { deleteTag(tag.value); }}
                            buttonClassName="btn btn-outline-primary border-top-0 border-left-0 border-bottom-0 mt-1 mr-1"
                            buttonIconClassName="fas fa-pencil-alt" />
                    </div>
                ))}
            </div>
        </div>
        <div className="row">
            <div className="col-6">Put the cursor anywhere on the text and click on the field to add it or use @ character.</div>
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <MixedTags
                                    tagifyRef={tagifyRef}
                                    settings={settings}
                                    className={style.content}
                                    value={`${content}`}
                                    whitelist={tags}
                                    onInput={() => restartPreviewTimer()}
                                    onRemove={() => restartPreviewTimer()}
                                />
                            </div>
                            {contentError && <div className="col-12 text-danger">{contentError}</div>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row h-100">
                            <div className={`${contentSize.width > contentSize.height ? 'col-7' : 'col-6'} pl-4`}>
                                <div className="row h-100">
                                    <Preview width={contentSize.width} heigth={contentSize.height} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

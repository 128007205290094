/* eslint-disable no-unused-vars */
import { SendingOptions, SendingOptionsValidator } from 'models/types';
import { goToStep, quoteWizardSlice, QuoteWizardState, StepKey, STEPS } from './../index';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { client } from 'api';
import { AppThunk } from 'helpers/thunkActionTypes';
import { Orientation } from 'api/api';
import { toContentSize } from '../helpers';
import RectData from 'models/RectData';
import { v4 as uuidv4 } from 'uuid';
import { batch } from 'react-redux';
import { FunctionP2 } from 'helpers/functionTypes';
import { reset as resetQuote, loadQuote } from 'redux/quote';
import { Callback } from 'components/quoteWizard/QuoteWizardPath';

const initialize = (urlResolver: FunctionP2<QuoteWizardState, number | undefined, string>, quoteId?: string, step?: string, sendingOption?: string, productIndex?: string, callback?: Callback): AppThunk => async (dispatch, getState) => {
    const initialStep = STEPS.prospect;
    const quoteActions = quoteWizardSlice.actions;
    const currentQuote = getState().quote.quote;
    const currentProductIndex = getState().quoteWizard.productLineIndex;

    batch(() => {
        dispatch(quoteActions.setUrlResolver(urlResolver));
        dispatch(quoteActions.setCallback(callback));
    });

    if (quoteId) {
        if (currentQuote?.id !== parseInt(quoteId)) {
            batch(() => {
                dispatch(quoteActions.reset());
                dispatch(quoteActions.resetContent());
            });
            await dispatch(loadQuotaData(parseInt(quoteId)));
            if (sendingOption && SendingOptionsValidator.guard(sendingOption)) {
                dispatch(quoteActions.updateSendingChoice(<SendingOptions>sendingOption));
            }
        }
        if (productIndex) {
            const index = parseInt(productIndex);
            if (currentProductIndex !== index) {
                await dispatch(quoteActions.setProductLineIndex(index));
                await dispatch(loadProductData(productIndex));
            }
        }
    } else {
        // cache prospect data to keep from reset. Will be saved by delivery step 
        const prospect = getState().quoteWizard.prospect;
        batch(() => {
            if (!prospect) {
                // if prospect is available, we have a temporary quote, no point to reset quote store
                dispatch(resetQuote());
            }
            dispatch(quoteActions.reset());
            dispatch(quoteActions.resetContent());
            dispatch(quoteActions.setProspectData(prospect));
        });
        await dispatch(loadQuotaData());
    }

    const currentStep = getState().quoteWizard.currentStep;
    let nextStep = step ? (STEPS[step as StepKey] || initialStep) : initialStep;

    if (!quoteId && nextStep !== initialStep && nextStep !== STEPS.delivery) {
        nextStep = initialStep;
    }

    if ((!currentStep || !step || currentStep !== nextStep)) {
        await dispatch(goToStep(nextStep, true));
    }

    await dispatch(quoteActions.setInitialized(true));
};

const loadQuotaData = (quoteId?: number): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    try {
        dispatch(clearError());
        dispatch(showLoading());

        let products = getState().quote.products;
        if (!products.length) {
            products = await client().product_GetAll();
        }
        dispatch(quoteActions.setProducts(products));
        await dispatch(loadQuote(quoteId, products));
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
};

const loadProductData = (productIndex: string): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    const quote = getState().quote.quote;
    const products = getState().quoteWizard.products;
    const productItem = quote.productLines && quote.productLines[parseInt(productIndex)];
    if (!productItem?.productId) {
        return;
    }
    const product = products.find(product => product.id === productItem.productId);
    if (!product) { // product is not attached to existing product - don't know what type of product it is
        return;
    }

    if (!product.isCarrier) {
        const writingContent = (productItem.writingContent?.length || 0) > 0
            ? productItem.writingContent && productItem.writingContent[0]
            : null;
        const contentSize = (await client().product_GetProductSizes())
            .filter(size => product.productSizeId === size.id)[0];

        if (!writingContent || !contentSize) {
            return;
        }

        const graphics = (productItem.graphicsContent || []).map(content => {
            return {
                id: uuidv4(),
                x: content.x || 0,
                y: content.y || 0,
                width: content.width,
                height: content.height,
                path: content.imageUrl
            } as RectData;
        });

        const contentRect: RectData = {
            id: 'content',
            x: writingContent?.offsetX || contentSize.marginx || 0,
            y: writingContent?.offsetY || contentSize.marginy || 0,
            width: writingContent?.width || (contentSize.width! - (contentSize.marginx! * 2)),
            height: 0,
            path: ''
        };

        batch(() => {
            dispatch(quoteActions.updateContent(writingContent?.text || ''));
            dispatch(quoteActions.updateContentTags(productItem.fields || []));
            dispatch(quoteActions.updateContentSize(toContentSize(contentSize, productItem.orientation?.valueOf() === Orientation.Landscape.valueOf())));
            dispatch(quoteActions.updateStationeryProvider('ProvidedByUs'));
            dispatch(quoteActions.updateProduct(product));
            dispatch(quoteActions.updateGraphicsPlacement(graphics));
            dispatch(quoteActions.updateContentPlacement(contentRect));
        });
    }
};

export default initialize;

/* eslint-disable react/jsx-key */
import Card from 'components/elements/Card';
import { useEffect, useMemo } from 'react';
import Table, { TableProps } from 'components/elements/Table';
import Page from 'components/Page';
import { useAppDispatch, useAppSelector } from 'hooks';
import LoadingContainer from 'components/elements/LoadingContainer';
import { initialize, reset, openQuote } from './quotesListSlice';
import { TableData } from './models';
import Button from 'components/elements/page-actions/Button';
import { push } from 'connected-react-router';
import DPD from 'res/img/branding/DPD.svg';
import RoyalMail from 'res/img/branding/Royal_Mail.svg';
import style from './QuotesList.module.css';
import { toDateTime } from 'helpers/general';

export default function QuotesList () {
    const quotes = useAppSelector(state => state.quotesList.quotes);
    const dispatch = useAppDispatch();

    const actions = [
        (<Button key="create_quote" icon="fas fa-plus" title="Add Quote" action={() => dispatch(push('/quote-wizard/prospect'))} />)
    ] as JSX.Element[];

    const imageRender = (data: any, type: any, row: any, meta: any): string => {
        return (type === 'display' && data)
            ? `<div><img title="${data}" class="my-n1 ${style.deliveryIcon}" src="${data === 'Mail Out' ? RoyalMail : DPD}"></div>`
            : data;
    };

    const createdRenderer = (data: any, type: any, row: any, meta: any): string => {
        return (type === 'display' && data) 
            ? toDateTime(data)
            : data;
    };

    const quoteRenderer = (data: any, type: any, row: any, meta: any) => {
        return (type === 'display')
            ? `<div class="d-flex flex-row align-items-center">
                <div>${data}</div>
                <h5 class="m-0">
                    <span class="ml-3 ${row.statusColor ? 'badge badge-pill badge-' + row.statusColor : 'font-italic small'}">
                        ${row.status}
                    </span>
                </h5>
            </div>`
            : data;
    };

    const props = useMemo<TableProps>(() => ({
        onRowClicked: (data) => {
            dispatch(openQuote((data as TableData).id));
        },
        columns: [
            { title: 'Quote No', field: 'quoteNo', render: quoteRenderer },
            { title: 'Prospect', field: 'prospect' },
            { title: 'Copies', field: 'copies' },
            { title: 'Delivery Type', field: 'deliveryType', render: imageRender, alignment: 'CENTER' },
            { title: 'Created', field: 'createdDate', render: createdRenderer },
            { title: 'Total', field: 'total', alignment: 'RIGHT' }
        ],
        order: { column: 4, order: 'desc' }
    }), []);

    useEffect(() => {
        dispatch(initialize());
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <Page title="Quotes" actions={actions}>
            <div className="row">
                <div className="col-12">
                    <Card title={null}>
                        <LoadingContainer>
                            <Table tableProps={props} data={quotes} />
                        </LoadingContainer>
                    </Card >
                </div>
            </div>
        </Page >);
}

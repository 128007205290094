import { useEffect, useState } from 'react';
import Select, { OptionTypeBase } from 'react-select';

interface Item {
    title: string,
    value: string
}

interface Props {
    className?: string
    values: Item[]
    selectedValue: string,
    onChange: (value: any) => void
    buttonClass?: string
}

export default function DropDown ({ className, values, selectedValue, onChange }: Props) {
    const [selectedOption, setSelectedOption] = useState<OptionTypeBase | null>(null);

    const options = values.map(val => ({ label: val.title, value: val.value }));

    useEffect(() => {
        const newOption = options.find((o :any) => o.value === selectedValue) || null;
        if (newOption?.value !== selectedOption?.value) {
            setSelectedOption(newOption);
        }
    }, [selectedValue, values]);
    
    return (
        <Select
            options={options}
            value={selectedOption}
            onChange={(e) => { onChange(e.value); }}
        />
    );
};

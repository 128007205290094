/* eslint-disable no-unused-vars */
import { useAppSelector } from 'hooks';
import style from './EditContent.module.css';
import blankImage from 'res/img/preview.png';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import LoadingCover from 'components/elements/LoadingCover';

interface Props {
    width: number
    heigth: number
}

export default function Preview ({ width, heigth }: Props) {
    const preview = useAppSelector(state => state.editContentSection.preview);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [container, setcontainer] = useState<HTMLElement>();

    const handleRect = useCallback((node) => {
        setcontainer(node);
    }, []);

    const containerWidth = () => container ? container.offsetWidth : 0;
    const contentAspectRation = () => width / heigth;

    useEffect(() => {
        window.addEventListener('resize', forceUpdate);
        return () => {
            window.removeEventListener('resize', forceUpdate);
        };
    });

    return (
        <div className={style.previewWrapper} ref={handleRect}>
            <LoadingCover style={{
                width: width > heigth ? containerWidth() : containerWidth() * contentAspectRation(),
                height: width > heigth ? containerWidth() / contentAspectRation() : containerWidth()
            }} className="position-relative">
                <div className={style.preview}>
                    {preview
                        ? (<img className={`${style.previewImg} w-100 h-100`} src={URL.createObjectURL(preview.data)} />)
                        : (<img className={style.blankImage} src={blankImage} />)
                    }
                </div>
            </LoadingCover>
        </div >
    );
}

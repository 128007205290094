import { AnyAction, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import ContentSize from 'models/ContentSize';
import { SendingOptions, StationeryProvider } from 'models/types';
import { FormFields as ProspectFormFields } from '../sections/Prospect';
import { AppThunk } from 'helpers/thunkActionTypes';
import { reset as resetValidSizes } from '../sections/StationeryChoice/stationeryChoiceSlice';
import { reset as resetEditContent } from '../sections/EditContent/editContentSlice';
import { reset as resetProducts } from '../sections/ProductChoice/productChoiceSlice';
import { reset as resetContentPreview } from '../sections/ContentPreview/contentPreviewSlice';
import { reset as resetEnvelopes } from '../sections/EnvelopeChoice/envelopeChoiceSlice';
import { DeliveryType, FieldModel, ProductModel } from 'api/api';
import RectData from 'models/RectData';
import { push, replace } from 'connected-react-router';
import initializeHandler from './handlers/initializeHandler';
import goBackHandler from './handlers/goBackHandler';
import { createCarrierProductLine, createContentProductLine } from './handlers/productLineHandler';
import { readContentFromQuote } from './helpers';
import { extractFieldsAndFormatContent } from 'helpers/tagifyHelper';
import { RootStore } from 'redux/store';
import { batch } from 'react-redux';
import { ServiceData } from 'models/ServiceData';
import { FunctionP2 } from 'helpers/functionTypes';
import { updateQuoteProspect, updateQuoteDeliveryType, updateQuoteQuantity, updateQuoteNotes, updateQuoteAdditionalServices, updateProductLine, removeProductLine, markQuoteAsPrepared, Result, reset as resetQuote } from 'redux/quote';
import { Callback } from '../QuoteWizardPath';
import { clearError, hideLoading, showError } from 'redux/ui';
import { isNumber } from 'helpers/general';

export interface QuoteWizardState {
    parentLineId: number | undefined;
    initialized: boolean
    urlResolver: FunctionP2<QuoteWizardState, number | undefined, string>
    callback: Callback | undefined
    currentStep: Step | null
    sendingOption: SendingOptions | null
    stationeryProvider: StationeryProvider | undefined
    content: string
    tags: FieldModel[]
    contentSize?: ContentSize
    product?: ProductModel
    contentPlacement: RectData | undefined
    graphicsPlacement: RectData[]
    productLineIndex: number | null
    products: ProductModel[] // temporary will use given product to check if is carrier
    prospect?: ProspectFormFields // temporary keep prospect data for actually saving in next step
}

export interface Step {
    key: string
    title: string | null
}

export type StepKey =
    'prospect' | 'delivery' | 'quantity' | 'sendingChoice' | 'content' | 'stationeryChoice' |
    'stationeryProvided' | 'productChoice' | 'editContent' | 'contentPreview' |
    'envelopeChoice' | 'additionalServices' | 'notes' | 'finalStep'

// eslint-disable-next-line no-unused-vars
export const STEPS: { [key in StepKey]: Step } = {
    prospect: { key: 'prospect', title: 'Data' },
    delivery: { key: 'delivery', title: 'Choose delivery type' },
    quantity: { key: 'quantity', title: 'Provide the number of copies' },
    sendingChoice: { key: 'sendingChoice', title: null },
    content: { key: 'content', title: 'Type or paste your content here' },
    stationeryChoice: { key: 'stationeryChoice', title: null },
    stationeryProvided: { key: 'stationeryProvided', title: 'Stationery provided' },
    productChoice: { key: 'productChoice', title: 'Product' },
    editContent: { key: 'editContent', title: 'Content' },
    contentPreview: { key: 'contentPreview', title: 'Preview' },
    envelopeChoice: { key: 'envelopeChoice', title: 'What envelopes you would like to use?' },
    additionalServices: { key: 'additionalServices', title: 'Other services required' },
    notes: { key: 'notes', title: 'Notes and other instructions' },
    finalStep: { key: 'finalStep', title: 'Save Quote' }
};

const initialState: QuoteWizardState = {
    initialized: false,
    urlResolver: () => '',
    callback: undefined,
    currentStep: null,
    sendingOption: null,
    content: '',
    tags: [],
    contentPlacement: undefined,
    graphicsPlacement: [],
    stationeryProvider: undefined,
    productLineIndex: null,
    products: [],
    parentLineId: undefined
};

export const quoteWizardSlice = createSlice({
    name: 'quoteWizard',
    initialState,
    reducers: {
        setUrlResolver: (state, action: PayloadAction<FunctionP2<QuoteWizardState, number | undefined, string>>) => {
            state.urlResolver = action.payload;
        },
        setCallback: (state, action: PayloadAction<Callback | undefined>) => {
            state.callback = action.payload;
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        setProspectData: (state, action: PayloadAction<ProspectFormFields | undefined>) => {
            state.prospect = action.payload;
        },
        reset: (state) => {
            state.productLineIndex = null;
            state.sendingOption = null;
            state.stationeryProvider = undefined;
            state.initialized = false;
            state.prospect = undefined;
        },
        resetContent: (state) => {
            state.content = initialState.content;
            state.tags = initialState.tags;
            if (!state.parentLineId) {
                state.contentSize = initialState.contentSize;
            }
            state.contentPlacement = initialState.contentPlacement;
            state.graphicsPlacement = initialState.graphicsPlacement;
        },
        setStep: (state, action: PayloadAction<Step>) => {
            state.currentStep = action.payload;
        },
        setProducts: (state, action: PayloadAction<ProductModel[]>) => {
            state.products = action.payload;
        },
        setProductLineIndex: (state, action: PayloadAction<number | null>) => {
            state.productLineIndex = action.payload;
        },
        updateSendingChoice: (state, action: PayloadAction<SendingOptions | null>) => {
            state.sendingOption = action.payload;
        },
        updateContent: (state, action: PayloadAction<string>) => {
            state.content = action.payload;
        },
        updateContentTags: (state, action: PayloadAction<FieldModel[]>) => {
            state.tags = action.payload;
        },
        updateContentSize: (state, action: PayloadAction<ContentSize>) => {
            state.contentSize = action.payload;
            state.graphicsPlacement = [];
            state.contentPlacement = undefined;
        },
        updateStationeryProvider: (state, action: PayloadAction<StationeryProvider>) => {
            state.stationeryProvider = action.payload;
        },
        updateProduct: (state, action: PayloadAction<ProductModel>) => {
            state.product = action.payload;
        },
        updateContentPlacement: (state, action: PayloadAction<RectData>) => {
            state.contentPlacement = action.payload;
        },
        updateGraphicsPlacement: (state, action: PayloadAction<RectData[]>) => {
            state.graphicsPlacement = action.payload;
        },
        updateParentId: (state, action: PayloadAction<number|undefined>) => {
            state.parentLineId = action.payload;
        }
    }
});

export const goToStep = (step: Step | undefined, replaceLink?: boolean): AppThunk => async (dispatch, getState) => {
    const urlResolver = getState().quoteWizard.urlResolver;
    const quoteId = getState().quote.quote?.id;
    const quoteActions = quoteWizardSlice.actions;
    if (step) {
        batch(() => {
            dispatch(quoteActions.setStep(step));
            dispatch(quoteActions.setInitialized(false));
            dispatch(hideLoading());
            dispatch(clearError()); // clear all errors from previous steps
            const nextLink = urlResolver(getState().quoteWizard, quoteId);
            if (replaceLink) {
                dispatch(replace(nextLink));
            } else {
                dispatch(push(nextLink));
            }
        });
    }
};

export const initialize = initializeHandler;

export const goToPreviousStep = goBackHandler;

export const updateProspectData = (fields: ProspectFormFields): AppThunk => async (dispatch, getState) => {
    const callback = getState().quoteWizard.callback;
    const actions = quoteWizardSlice.actions;

    const quote = { ...getState().quote.quote };

    let result : Result | null = null;
    if (quote.id) {
        // quote has an id - most likely it has already been saved and we are just updating it
        result = await dispatch(updateQuoteProspect(fields));
    } else {
        dispatch(actions.setProspectData(fields));
    }

    if (!result || result.isSuccess) {
        const handled = callback && dispatch(handleCompleteWithCallback());
        if (!handled) {
            dispatch(goToStep(STEPS.delivery));
        }
    }
};

export const fullReset = (): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    batch(() => {
        dispatch(resetQuote());
        dispatch(quoteActions.reset());
        dispatch(quoteActions.resetContent());
    });
};

export const updateDeliveryType = (type: DeliveryType): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    const prospect = getState().quoteWizard.prospect;
    const result = await dispatch(updateQuoteDeliveryType(type, prospect));
    if (result.isSuccess) {
        dispatch(quoteActions.setProspectData(undefined));
        dispatch(goToStep(STEPS.quantity));
    }
};

export const updateQuantity = (quantity: number): AppThunk => async (dispatch, getState) => {
    const callback = getState().quoteWizard.callback;
    const { quote } = getState().quote;
    const result = await dispatch(updateQuoteQuantity(quantity));
    if (result.isSuccess) {
        const handled = callback && dispatch(handleCompleteWithCallback());
        if (!handled) {
            // For now we have decided to redirect to quote details when user has 
            // provided the quantity in quote wizard
            dispatch(push('/quotes/' + quote.id));
            // dispatch(goToStep(STEPS.sendingChoice));
        }
    }
};

export const updateSendingChoice = (sendingOption: SendingOptions | null): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    dispatch(quoteActions.updateSendingChoice(sendingOption));
    if (sendingOption === 'write-letter') {
        batch(() => {
            const state = getState();
            const content = readContentFromQuote(state.quoteWizard.productLineIndex, state.quote.quote?.productLines);
            batch(() => {
                if (!content) {
                    dispatch(quoteActions.resetContent());
                }
                dispatch(setInitialContent(content));
                dispatch(goToStep(STEPS.content));
            });
        });
    } else if (sendingOption === 'write-envelope') {
        dispatch(goToStep(STEPS.envelopeChoice));
    } else if (!sendingOption) {
        if (await dispatch(validateQuote())) {
            dispatch(goToStep(STEPS.additionalServices));
        }
    }
};

export const setInitialContent = (content: string): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    batch(() => {
        dispatch(resetValidSizes());
        dispatch(quoteActions.updateContent(content));
        dispatch(goToStep(STEPS.stationeryChoice));
    });
};

export const updateContentSize = (size: ContentSize): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    batch(() => {
        dispatch(resetProducts());
        dispatch(quoteActions.updateContentSize(size));
        dispatch(goToStep(STEPS.stationeryProvided));
    });
};

export const updateStationeryProvider = (provider: StationeryProvider): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    batch(() => {
        dispatch(quoteActions.updateStationeryProvider(provider));
        dispatch(goToStep(STEPS.productChoice));
    });
};

export const updateProduct = (product: ProductModel): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    batch(() => {
        dispatch(quoteActions.updateProduct(product));
        dispatch(resetEditContent());
        dispatch(resetContentPreview());
        dispatch(resetEnvelopes());
        dispatch(goToStep(STEPS.editContent));
    });
};

const updateWizardContent = async (content: string, dispatch: ThunkDispatch<RootStore, unknown, AnyAction>, getStore: () => RootStore) => {
    const quoteActions = quoteWizardSlice.actions;
    const extracted = extractFieldsAndFormatContent(content);

    batch(() => {
        dispatch(quoteActions.updateContent(extracted?.content || ''));
        dispatch(quoteActions.updateContentTags(extracted?.fields || []));
    });
};

const syncContent = (): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    const state = getState().quoteWizard;
    const index = state.productLineIndex;

    const item = createContentProductLine(state);
    const result = await dispatch(updateProductLine(item, index));

    const productLines = getState().quote.quote.productLines;

    // attach new product index, if previously none was set
    if (!isNumber(index)) {
        const newIndex = productLines ? productLines.length - 1 : 0;
        dispatch(quoteActions.setProductLineIndex(newIndex));
    }
    return result;
};

const handleEnvelope = async (envelope: ProductModel, dispatch: ThunkDispatch<RootStore, unknown, AnyAction>, getStore: () => RootStore) => {
    const state = getStore().quoteWizard;
    const index = state.productLineIndex;

    const item = createCarrierProductLine(envelope);
    return await dispatch(updateProductLine(item, index));
};

export const updateContent = (formattedContent: string, silent: boolean): AppThunk => async (dispatch, getState) => {
    if (!getState().ui.error) {
        await updateWizardContent(formattedContent, dispatch, getState);
        const result = await dispatch(syncContent());
        if (!silent) {
            dispatch(resetContentPreview());
            if (result.isSuccess) {
                dispatch(goToStep(STEPS.contentPreview));
            }
        }
    }
};

export const updateContentPlacementAndGoBack = (content: RectData | null, graphics: RectData[]): AppThunk => async (dispatch, getState) => {
    await dispatch(updateContentPlacement(content, graphics, false));
    dispatch(goToStep(STEPS.editContent));
};

export const updateContentPlacement = (content: RectData | null, graphics: RectData[], withRedirect: boolean = false): AppThunk => async (dispatch, getState) => {
    const quoteActions = quoteWizardSlice.actions;
    if (content) {
        dispatch(quoteActions.updateContentPlacement(content));
    }
    await dispatch(quoteActions.updateGraphicsPlacement(graphics));
    const result = await dispatch(syncContent());
    const isSuccess = result.isSuccess;
    const callback = getState().quoteWizard.callback;

    if (isSuccess && withRedirect) {
        batch(() => {
            dispatch(quoteActions.updateSendingChoice(null));
            dispatch(quoteActions.setProductLineIndex(null));
            dispatch(quoteActions.resetContent());
            const handled = callback && dispatch(handleCompleteWithCallback());
            if (!handled) {
                dispatch(goToStep(STEPS.sendingChoice));
            }
        });
    }
};

export const updateEnvelopeOption = (envelope: ProductModel): AppThunk => async (dispatch, getState) => {
    const callback = getState().quoteWizard.callback;
    const quoteActions = quoteWizardSlice.actions;
    const result = await handleEnvelope(envelope, dispatch, getState);
    if (result.isSuccess) {
        batch(() => {
            dispatch(quoteActions.updateSendingChoice(null));
            dispatch(quoteActions.setProductLineIndex(null));
            const handled = callback && dispatch(handleCompleteWithCallback());
            if (!handled) {
                dispatch(goToStep(STEPS.sendingChoice));
            }
        });
    }
};

export const updateAdditionalServices = (additionalServices: ServiceData[]): AppThunk => async (dispatch, getState) => {
    const callback = getState().quoteWizard.callback;
    const result = await dispatch(updateQuoteAdditionalServices(additionalServices));
    if (result.isSuccess) {
        const handled = callback && dispatch(handleCompleteWithCallback());
        if (!handled) {
            dispatch(goToStep(STEPS.notes));
        }
    }
};

export const updateNotes = (notes: string): AppThunk => async (dispatch, getState) => {
    const callback = getState().quoteWizard.callback;
    const result = await dispatch(updateQuoteNotes(notes));
    if (result.isSuccess) {
        const handled = callback && dispatch(handleCompleteWithCallback());
        if (handled) { return; }

        const markResult = await dispatch(markQuoteAsPrepared());
        if (markResult.isSuccess) {
            dispatch(goToStep(STEPS.finalStep));
        }
    }
};

export const finishQuote = (): AppThunk => async (dispatch, getState) => {
    const { quote } = getState().quote;
    dispatch(push('/quotes/' + quote.id));
};

export const handleBackWithCallback = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const { callback, currentStep } = getState().quoteWizard;
    const { quote } = getState().quote;
    const quoteDetailsSteps = [STEPS.content, STEPS.quantity, STEPS.editContent, STEPS.envelopeChoice, STEPS.additionalServices, STEPS.notes];
    if (callback === 'quoteDetails' && currentStep && quoteDetailsSteps.indexOf(currentStep) >= 0) {
        dispatch(push('/quotes/' + quote.id));
        return true;
    } else if (currentStep === STEPS.prospect) {
        if (quote?.id) {
            dispatch(push('/quotes/' + quote.id));
        } else {
            dispatch(push('/quotes'));
        }
        return true;
    }
    return false;
};

export const handleCompleteWithCallback = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const { callback, currentStep } = getState().quoteWizard;
    const { quote } = getState().quote;
    const quoteDetailsSteps = [STEPS.prospect, STEPS.quantity, STEPS.contentPreview, STEPS.additionalServices, STEPS.envelopeChoice, STEPS.notes];
    if (callback === 'quoteDetails' && currentStep && quoteDetailsSteps.indexOf(currentStep) >= 0) {
        dispatch(push('/quotes/' + quote.id));
        return true;
    }
    return false;
};

export const removeProduct = (index: number): AppThunk => async (dispatch, getState) => {
    await dispatch(removeProductLine(index));
};

const validateQuote = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const quote = getState().quote.quote;
    const delivyType = quote.deliveryType;
    const hasCarrier = !!(quote.productLines || []).find(p => p.isCarrier);

    // Carrier is required if deliver type id Mail Out
    if (delivyType === DeliveryType.MailOut && !hasCarrier) {
        dispatch(showError(new Error('Envelope (Carrier) should be specified when Delivery Type is Royal Mail')));
        return false;
    }

    return true;
};

export default quoteWizardSlice.reducer;

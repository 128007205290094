import React from 'react';

interface PageProps {
    title: string
    actions?: JSX.Element[]
    children: any
}

export default function Page ({ title, actions, children }: PageProps) {
    return (
        <>
            <div className="row mb-3 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                    <h3>{title}</h3>
                </div>

                <div className="col-auto ml-auto text-right mt-n1">
                    {(actions || []).map((action) => (action))}
                </div>
            </div>
            {children}
        </>
    );
}

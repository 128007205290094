import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import {
    showLoading,
    hideLoading,
    showError,
    clearError
} from 'redux/ui';
import { batch } from 'react-redux';
import { ProductLineModel } from 'api/api';

export interface ProductData {
    title: string,
    actualIndex: number
}

export interface SendingChoiceSectionState {
    products: ProductData[],
    envelopes: ProductData[],
    handwrittenLetterButtonVisible: boolean
}

const initialState: SendingChoiceSectionState = {
    products: [],
    envelopes: [],
    handwrittenLetterButtonVisible: true
};

export const sendingChoiceSectionSlice = createSlice({
    name: 'sendingChoiceSection',
    initialState,
    reducers: {
        setProducts: (state, action: PayloadAction<ProductData[]>) => {
            state.products = action.payload;
            state.handwrittenLetterButtonVisible = state.products.length === 0;
        },
        setEnvelopes: (state, action: PayloadAction<ProductData[]>) => {
            state.envelopes = action.payload;
        }
    }
});

export const initialize = (currentProducts: ProductLineModel[] | undefined): AppThunk => async (dispatch, getState) => {
    const actions = sendingChoiceSectionSlice.actions;

    batch(() => {
        dispatch(showLoading);
        dispatch(clearError);
    });

    try {
        const envelopes = (currentProducts || [])
            .map((value, index) => { return { value: value, index: index }; })
            .filter(value => value.value.isCarrier)
            .map(envelope => {
                return {
                    title: envelope.value.description || '',
                    actualIndex: envelope.index
                };
            });

        const products = (currentProducts || [])
            .map((value, index) => { return { value: value, index: index }; })
            .filter(item => !item.value.isCarrier)
            .map((product, index) => {
                return {
                    title: `Item ${index + 1}. ${product.value.description}`,
                    actualIndex: product.index
                };
            });
        batch(() => {
            dispatch(actions.setProducts(products));
            dispatch(actions.setEnvelopes(envelopes));
        });
    } catch (error) {
        dispatch(showError(error.message));
        return;
    }
    dispatch(hideLoading);
};

export default sendingChoiceSectionSlice.reducer;

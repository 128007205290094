import { ActionP1 } from 'helpers/functionTypes';
import { useRef } from 'react';
import style from './Table.module.css';
import { useThemeLoaded } from 'themeLoader';

interface Props {
    tableProps: TableProps,
    data: any[],
    hideSearch?: boolean
}

type Alignment = 'LEFT' | 'CENTER' | 'RIGHT' | 'JUSTIFY' | 'NOWRAP'

export interface TableProps {
    order?: { column: number, order: string }
    columns: ColumnProps[],
    onRowClicked?: ActionP1<any>;
}

export interface ColumnProps {
    field: string,
    title?: string,
    alignment?: Alignment,
    width?: string,
    render?: (data: any, type: any, row: any, meta: any) => string
}

export default function Table ({ tableProps, data, hideSearch }: Props) {
    const tableRef = useRef(null);

    useThemeLoaded(() => {
        // If the theme is already loaded, we might come in a bit to early and cause an exception, will wait for 1 frame - it seems to solve the problem
        const timeout = setTimeout(() => {
            if ((window.$ as any).fn.dataTable.isDataTable(window.$(tableRef.current))) {
                (window.$(tableRef.current).DataTable() as any).destroy();
            }
            const table: any = window.$(tableRef.current!).DataTable({
                order: tableProps.order ? [[tableProps.order.column, tableProps.order.order]] : undefined,
                data: data,
                columns: tableProps.columns.map(col => {
                    return {
                        data: col.field,
                        title: col.title || col.field,
                        className: alignmentToClass(col.alignment),
                        width: col.width || undefined,
                        render: col.render
                    };
                }),
                bFilter: !hideSearch
            });
            if (tableProps.onRowClicked) {
                window.$(tableRef.current!).off('click', 'tbody tr');
                window.$(tableRef.current!).on('click', 'tbody tr', function () {
                    const data = table.row(this)?.data();
                    tableProps.onRowClicked!(data);
                });
            }

            return () => {
                if ((window.$ as any).fn.dataTable.isDataTable(window.$(tableRef.current))) {
                    (window.$(tableRef.current).DataTable() as any).destroy();
                }
            };
        }, 0);
        return () => {
            clearTimeout(timeout);
        };
    }, [data]);

    return (
        <table className={`table ${tableProps.onRowClicked ? style.clickableRow : ''}`} ref={tableRef} style={{ width: '100%' }} />
    );
}

function alignmentToClass (alignment?: Alignment) {
    if (!alignment) {
        return undefined;
    }

    switch (alignment) {
        case 'LEFT': return 'text-left';
        case 'CENTER': return 'text-center';
        case 'RIGHT': return 'text-right';
        case 'JUSTIFY': return 'text-justify';
        case 'NOWRAP': return 'text-nowrap';
    }
}

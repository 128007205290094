import { Link } from 'react-router-dom';

interface Props {
    link: string | undefined
    isEnabled: boolean
    children: any
    [key: string]: any
    callback?: () => void
}

export default function OptionalLink ({ link, isEnabled, children, callback = () => {}, ...other }: Props) {
    return ((link && isEnabled)
        ? <Link to={link} onClick={() => callback()} {...other}>{children}</Link>
        : <span {...other}>{children}</span>
    );
};

import style from './ContentPreview.module.css';
import { Layer, Stage } from 'react-konva';
import { ActionP1 } from 'helpers/functionTypes';
import Konva from 'konva';
import { useState } from 'react';
import ImageRectData from './ImageRectData';
import InteractableImage from './InteractableImage';
import LoadingCover from 'components/elements/LoadingCover';
import ContentSize from 'models/ContentSize';

export interface ItemConfig {
    id: string | undefined,
    x: number,
    y: number,
    marginX: number,
    marginY: number,
    width: number,
    height: number,
    image: HTMLImageElement
}

interface Props {
    width: number
    height: number
    scale: number // screen scale to actual size
    pageParams: ContentSize
    content: ImageRectData | null
    additionalItems: ImageRectData[]
    onContentChanged: ActionP1<ImageRectData>
    onAdditionalItemChanged: ActionP1<ImageRectData>
    onSelectedChanged: ActionP1<string | null>
}

const extractImageConfig = (scale: number, data: ImageRectData, withOffset: boolean, pageSize: ContentSize) => {
    const image = new Image(data.width, data.height);
    image.src = data.imageURL;

    const config: ItemConfig = {
        id: data.id,
        x: data.x * scale,
        y: data.y * scale,
        marginX: withOffset ? pageSize.marginX * scale : 0,
        marginY: withOffset ? pageSize.marginY * scale : 0,
        width: data.width * scale,
        height: data.height * scale,
        image: image
    };

    return config;
};

export default function ContentBlock (props: Props) {
    const contentConfig = props.content ? extractImageConfig(props.scale, props.content, true, props.pageParams) : null;
    const additionalItemsConfig = (props.additionalItems || []).map(c => extractImageConfig(props.scale, c, false, props.pageParams));
    const [selectedId, setSelectedId] = useState<string | null>(null);

    const selectShape = (id: string | null) => {
        setSelectedId(id);
        props.onSelectedChanged(id);
    };

    const checkDeselect = (e: any) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };

    const { marginX, marginY, width, height } = props.pageParams;
    const validArea = {
        minX: marginX * props.scale,
        minY: marginY * props.scale,
        maxX: (width - marginX) * props.scale,
        maxY: (height - marginY) * props.scale
    };

    const notifyContentChanged = (contentConfig: Konva.ImageConfig) => {
        props.onContentChanged({
            ...props.content!,
            ...{
                x: (contentConfig.x || 0) / props.scale,
                y: (contentConfig.y || 0) / props.scale,
                width: (contentConfig.width || 0) / props.scale,
                height: (contentConfig.height || 0) / props.scale
            }
        });
    };

    const notifyAdditionalItemChanged = (itemConfig: Konva.ImageConfig) => {
        const item = props.additionalItems.find(i => i.id === itemConfig.id);

        props.onAdditionalItemChanged({
            ...item!,
            ...{
                x: (itemConfig.x || 0) / props.scale,
                y: (itemConfig.y || 0) / props.scale,
                width: (itemConfig.width || 0) / props.scale,
                height: (itemConfig.height || 0) / props.scale
            }
        });
    };

    return (
        <LoadingCover
            style={{
                width: props.width,
                height: props.height
            }}>
            <Stage
                width={props.width}
                height={props.height}
                className={style.preview}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
            >
                <Layer>
                    {contentConfig &&
                        <InteractableImage
                            shapeProps={contentConfig}
                            isSelected={contentConfig.id === selectedId}
                            horizontalResizeOnly={true}
                            liveResize={false}
                            fixedAspect={false}
                            validArea={validArea}
                            onSelect={() => {
                                selectShape(contentConfig.id || null);
                            }}
                            onChange={(newAttrs) => {
                                notifyContentChanged(newAttrs);
                            }}
                        />}
                    {additionalItemsConfig.map((rect, i) => {
                        return (
                            <InteractableImage
                                key={i}
                                shapeProps={rect}
                                isSelected={rect.id === selectedId}
                                horizontalResizeOnly={false}
                                liveResize={true}
                                fixedAspect={true}
                                validArea={validArea}
                                onSelect={() => {
                                    selectShape(rect.id || null);
                                }}
                                onChange={(newAttrs) => {
                                    notifyAdditionalItemChanged(newAttrs);
                                }}
                            />
                        );
                    })}
                </Layer>
            </Stage>
        </LoadingCover>
    );
}

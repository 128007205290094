import { DeliveryType } from 'api/api';
import { ActionP1 } from 'helpers/functionTypes';
import DPD from 'res/img/branding/DPD.svg';
import RoyalMail from 'res/img/branding/Royal_Mail.svg';
interface Props {
    completeCallback: ActionP1<DeliveryType>
}

export default function Delivery ({ completeCallback }: Props) {
    return (
        <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
                <div className="row">
                    <div className="col-6 d-flex">
                        <a onClick={() => completeCallback(DeliveryType.Return)} className="card flex-grow-1 position-relative">
                            <div className="p-1 py-5 d-flex justify-content-center align-items-center flex-grow-1">
                                <img src={DPD} alt="Return to Me" className="w-50" />
                            </div>
                            <div className="card-header">
                                <h5 className="card-title mb-0 text-center">Return to me</h5>
                            </div>
                        </a>
                    </div>

                    <div className="col-6 d-flex">
                        <a onClick={() => completeCallback(DeliveryType.MailOut)} className="card flex-grow-1">
                            <div className="p-1 py-5 d-flex justify-content-center align-items-center">
                                <img src={RoyalMail} alt="Mail Out" className="w-50" />
                            </div>
                            <div className="card-header">
                                <h5 className="card-title mb-0 text-center">Mail Out</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

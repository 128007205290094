import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import { clearError, hideLoading, showError, showLoading } from 'redux/ui';
import { Product, Products, QuoteDetailsModel, QuoteStatus, Row } from './model';
import {
    DeliveryType,
    ProductLineModel,
    QuoteModel,
    QuoteStatus as ApiQuoteSatus,
    ServiceLineModel,
    ServiceType
} from 'api/api';
import { downloadFile, getWordsUnit, toDate, toLongMoney, toMoney, toTime } from 'helpers/general';
import {
    loadQuote,
    markQuoteAsOrdered,
    markQuoteAsPrepared,
    markQuoteAsSent,
    removeProductLine,
    reset as resetQuote,
    updateQuoteAdditionalServices,
    updateQuoteNotes
} from 'redux/quote';
import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import { client } from 'api';
import { openQuote } from '../QuotesList/quotesListSlice';

export interface QuoteDetailsState {
    quote: QuoteDetailsModel | null
    isReadOnly: boolean
    isDownloadReady: boolean
    createdMessage: string
    hasProducts: boolean
}

const initialState: QuoteDetailsState = {
    quote: null,
    isReadOnly: false,
    isDownloadReady: true,
    createdMessage: '',
    hasProducts: false
};

export const quoteDetailsSlice = createSlice({
    name: 'quoteDetails',
    initialState,
    reducers: {
        reset: (state) => initialState,
        setQuote: (state, action: PayloadAction<QuoteDetailsModel>) => {
            state.quote = action.payload;
        },
        setReadOnly: (state, action: PayloadAction<boolean>) => {
            state.isReadOnly = action.payload;
        },
        setIsDownloadReady: (state, action: PayloadAction<boolean>) => {
            state.isDownloadReady = action.payload;
        },
        setCreatedMessage: (state, action: PayloadAction<string>) => {
            state.createdMessage = action.payload;
        },
        setHasProducts: (state, action: PayloadAction<boolean>) => {
            state.hasProducts = action.payload;
        }
    }
});

export const initialize = (quoteId: string): AppThunk => async (dispatch, getState) => {
    dispatch(clearError());
    dispatch(showLoading());

    const quoteIdValue = parseInt(quoteId);
    try {
        const quote = await dispatch(loadQuote(quoteIdValue));
        await dispatch(updateQuoteData(quote));
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
};

export const resyncQuote = (): AppThunk => async (dispatch, getState) => {
    const quoteId = getState().quoteDetails.quote?.id;
    if (!quoteId) {
        return;
    }

    try {
        const products = getState().quote.products;
        const quote = await dispatch(loadQuote(quoteId, products));
        await dispatch(updateQuoteData(quote));
    } catch (error) {
        dispatch(showError(error));
    }
};

export const reset = (): AppThunk => async (dispatch, getState) => {
    const actions = quoteDetailsSlice.actions;
    batch(() => {
        dispatch(actions.reset());
        dispatch(resetQuote());
    });
};

export const deleteProduct = (index: number): AppThunk => async (dispatch, getState) => {
    const response = await dispatch(removeProductLine(index));

    if (response.isSuccess) {
        dispatch(updateQuoteData(response.data));
    }
};

export const removeService = (serviceId: number): AppThunk => async (dispatch, getState) => {
    const currentServices = getState().quote.quote.serviceLines;
    const response = await dispatch(updateQuoteAdditionalServices(
        (currentServices || [])
            .filter(service => service.id !== serviceId)
            .map(service => ({
                attachedServiceId: service.id,
                id: service.serviceId || 0,
                description: service.description || '',
                name: service.description || '',
                sku: service.sku || ''
            }))
    ));

    if (response.isSuccess) {
        dispatch(updateQuoteData(response.data));
    }
};

export const clearNote = (): AppThunk => async (dispatch, getState) => {
    const response = await dispatch(updateQuoteNotes(undefined));

    if (response.isSuccess) {
        dispatch(updateQuoteData(response.data));
    }
};

export const markAsPrepared = (): AppThunk => async (dispatch, getState) => {
    const response = await dispatch(markQuoteAsPrepared());

    if (response.isSuccess) {
        await dispatch(updateQuoteData(response.data));
    }
};

export const markAsSent = (): AppThunk => async (dispatch, getState) => {
    const response = await dispatch(markQuoteAsSent());

    if (response.isSuccess) {
        await dispatch(updateQuoteData(response.data));
    }
};

export const markAsRejected = (): AppThunk => async (dispatch, getState) => {
    //
};

export const markAsOrdered = (): AppThunk => async (dispatch, getState) => {
    const response = await dispatch(markQuoteAsOrdered());

    if (response.isSuccess) {
        await dispatch(updateQuoteData(response.data));
    }
};

export const closeDetails = (): AppThunk => async (dispatch, getState) => {
    dispatch(push('/quotes'));
};

export const downloadPDF = (): AppThunk => async (dispatch, getState) => {
    const quote = getState().quote.quote;
    dispatch(showLoading());
    try {
        const response = await client().quote_Download(quote.id!);
        downloadFile(response.data, `quote_${quote.quoteNo}.pdf`);
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
};

export const downloadProof = (): AppThunk => async (dispatch, getState) => {
    const quote = getState().quote.quote;
    dispatch(showLoading());
    try {
        const response = await client().quote_DownloadProofs(quote.id!);
        downloadFile(response.data, `quote_${quote.quoteNo}_proof.pdf`);
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
};

export const downloadQuoteTemplate = (): AppThunk => async (dispatch, getState) => {
    const quote = getState().quote.quote;
    dispatch(showLoading());
    try {
        const response = await client().production_GetQuoteTemplate(quote.id!);
        downloadFile(response.data, `${quote.quoteNo}.zip`);
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
};

export const deleteQuote = (): AppThunk => async (dispatch, getState) => {
    const quote = getState().quote.quote;
    const name = getState().user.user?.name;
    dispatch(showLoading());
    try {
        const response = await client().quote_Delete({
            id: quote.id,
            requestedBy: name
        });
        await dispatch(updateQuoteData(response));
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
    dispatch(closeDetails());
};

export const duplicateQuote = (): AppThunk => async (dispatch, getState) => {
    const quote = getState().quote.quote;
    const name = getState().user.user?.name;
    dispatch(showLoading());
    try {
        const response = await client().quote_Duplicate({
            id: quote.id,
            requestedBy: name
        });
        dispatch(openQuote(Number(response.id)));
    } catch (error) {
        dispatch(showError(error));
    }
    dispatch(hideLoading());
};

const updateQuoteData = (quote: QuoteModel): AppThunk => async (dispatch, getState) => {
    const actions = quoteDetailsSlice.actions;
    const createdMessage = extractCreatedInfo(quote);
    // All the proof links must exist for quote to be downloadable
    const isDownloadable = (quote.productLines || []).reduce<boolean>((aggr, item) => aggr && !!item.proofUrl, true);
    batch(() => {
        dispatch(actions.setQuote(toModel(quote)));
        dispatch(actions.setCreatedMessage(createdMessage));
        const editableStatuses = [ApiQuoteSatus.Draft, ApiQuoteSatus.Prepared];
        dispatch(actions.setReadOnly(!!quote.status && editableStatuses.indexOf(quote.status) === -1));
        dispatch(actions.setIsDownloadReady(isDownloadable));
        dispatch(actions.setHasProducts((quote.productLines || []).length > 0));
    });
};

const extractCreatedInfo = (quote: QuoteModel) => {
    const createdAt = quote.created ? new Date((quote.created as any).replace(/([^Z])$/, '$1Z')) : null;
    const modifiedAt = quote.updated ? new Date((quote.updated as any).replace(/([^Z])$/, '$1Z')) : null;

    return 'Created' +
        (quote.createdBy ? ' by ' + quote.createdBy : '') +
        (createdAt ? ' on ' + toDate(createdAt) + ' at ' + toTime(createdAt) : '') +
        '. Modified' +
        (quote.modifiedBy ? ' by ' + quote.modifiedBy : '') +
        (modifiedAt ? ' on ' + toDate(modifiedAt) + ' at ' + toTime(modifiedAt) : '') +
        '.';
};

function toModel (quote: QuoteModel): QuoteDetailsModel {
    return {
        id: quote.id || 0,
        name: quote.name || '',
        quoteNo: quote.quoteNo || '',
        companyName: quote.companyName || '',
        firstName: quote.firstName || '',
        lastName: quote.lastName || '',
        email: quote.email || '',
        quantity: quote.quantity || 0,
        deliveryType: quote.deliveryType === DeliveryType.MailOut ? 'MailOut' : 'Return',
        carrierType: 'Letter', // TODO: Read carrier type from quote
        totalPrice: `£${toMoney(quote.totalNet || 0)}`,
        pricePerUnit: `£${toLongMoney(quote.unitPriceNet || 0)}`,
        products: extractProducts(quote.productLines),
        carrier: extractCarrier(quote.productLines),
        services: extractServices(quote.serviceLines, false),
        additionalCharges: extractServices(quote.serviceLines, true),
        notes: quote.notes,
        status: quote.status ? mapStatus(quote.status) : QuoteStatus.Draft,
        statusColor: quote.status ? statusToColor(quote.status) : '',
        pricingLevel: quote.priceLevel?.name || ''
    };
}

function extractProducts (products: ProductLineModel[] | undefined): Products {
    let total = 0;

    const data: Product[] = (products || [])
        .map((product, index) => ({ product, index }))
        .filter(p => !p.product.isCarrier)
        .map((p, index) => {
            const product = p.product;
            total += product.lineNetPrice || 0;
            return {
                title: 'Item ' + (index + 1) + ': ' + product.description,
                parts: [
                    {
                        id: 0,
                        title: 'Letterbot Stationery',
                        value: '£' + toLongMoney(product.productPriceNet || 0)
                    },
                    ...(product.serviceLines || []).map(service => {
                        let title = '';
                        if (service.serviceType === ServiceType.Writing) {
                            title += `${service.description} (Content length is ${p.product.wordCount} ${getWordsUnit(p.product.wordCount || 1)})`;
                        } else {
                            title = service.description || '';
                        }

                        return ({
                            id: service.id || 0,
                            title: title,
                            value: '£' + toLongMoney(service.lineNetPrice || 0)
                        });
                    })
                ],
                totalPrice: '£' + toLongMoney(product.lineNetPrice || 0),
                originalIndex: p.index,
                parentLineId: p.product.parentLineId
            };
        });

    return { totalPrice: '£' + toLongMoney(total), data };
}

function extractCarrier (products: ProductLineModel[] | undefined): Products {
    let total = 0;
    const data: Product[] = (products || [])
        .map((product, index) => ({ product, index }))
        .filter(p => p.product.isCarrier)
        .map(p => {
            const product = p.product;
            total += product.lineNetPrice || 0;
            return {
                title: product.description || '',
                parts: [
                    {
                        id: 0,
                        title: 'Letterbot Stationery',
                        value: '£' + toLongMoney(product.productPriceNet || 0)
                    },
                    ...(product.serviceLines || []).map(service => ({
                        id: service.id || 0,
                        title: service.description || '',
                        value: '£' + toLongMoney(service.lineNetPrice || 0)
                    }))
                ],
                totalPrice: '£' + toLongMoney(product.lineNetPrice || 0),
                originalIndex: p.index
            };
        });
    return { totalPrice: '£' + toLongMoney(total), data };
}

function extractServices (services: ServiceLineModel[] | undefined, isGlobal: boolean): Product {
    let total = 0;
    const parts: Row[] = (services || [])
        .filter(service => service.isGlobalService === isGlobal)
        .map((service) => {
            total += service.lineNetPrice || 0;
            return {
                id: service.id || 0,
                title: service.description || '',
                value: '£' + toLongMoney(service.lineNetPrice || 0),
                isReadOnly: service.isAutomatic
            };
        });

    return {
        totalPrice: '£' + toLongMoney(total),
        title: '',
        parts
    };
}

const mapStatus = (status: ApiQuoteSatus) => {
    switch (status) {
        case ApiQuoteSatus.Draft: return QuoteStatus.Draft;
        case ApiQuoteSatus.Expired: return QuoteStatus.Expired;
        case ApiQuoteSatus.Prepared: return QuoteStatus.Prepared;
        case ApiQuoteSatus.Ordered: return QuoteStatus.Ordered;
        case ApiQuoteSatus.Sent: return QuoteStatus.Sent;
        case ApiQuoteSatus.Deleted: return QuoteStatus.Deleted;
    }
};

function statusToColor (status: ApiQuoteSatus): string {
    switch (status) {
        case ApiQuoteSatus.Sent: return 'success';
        // case QuoteStatus.Rejected // TODO: Find out abuot "rejected" status
        case ApiQuoteSatus.Draft: return '';
        case ApiQuoteSatus.Prepared: return 'light';
        case ApiQuoteSatus.Ordered: return 'secondary';
        case ApiQuoteSatus.Expired: return 'warning';
        case ApiQuoteSatus.Deleted: return '';
    }
}

export default quoteDetailsSlice.reducer;

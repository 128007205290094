import style from './QuoteDetails.module.css';

interface Pair {
    key: JSX.Element | null
    value?: JSX.Element | null
}

interface Props {
    records: Array<Pair>
    preview?: JSX.Element | null
    noBottomSeperation?: boolean | null
    noTopSeperation?: boolean | null
    highlight?: boolean
}

export default function DataBlock ({ records, preview = null, noBottomSeperation = false, noTopSeperation = false, highlight = false }: Props) {
    return (<>
        {!noTopSeperation && <tr className={style.header}><td colSpan={3}></td></tr>}
        {records.map((record, index) => (
            <tr key={index} className={highlight ? style.highlight : ''} >
                <td
                    colSpan={!(preview || record.value) ? 3 : (preview ? 1 : 2)}
                    className={`${style.keyCell}`}>
                    {record.key}
                </td>
                {index === 0 && preview &&
                    <td className={`${style.previewCell}`}
                        rowSpan={records.length}
                        style={{ verticalAlign: 'top' }}>
                        {preview}
                    </td>
                }
                {record.value &&
                    <td
                        className={`${style.valueCell}`}
                        style={{ verticalAlign: 'middle', textAlign: 'end' }}>
                        {record.value}
                    </td>
                }
            </tr>
        ))}
        {!noBottomSeperation && <tr className={style.footer}><td colSpan={3}></td></tr>}

    </>);
}

import Konva from 'konva';
import { Image, Transformer, Rect } from 'react-konva';
import { ActionP0, ActionP1 } from 'helpers/functionTypes';
import { useEffect, useRef } from 'react';
import { Stage } from 'konva/lib/Stage';
import { Shape } from 'konva/lib/Shape';
import { ItemConfig } from './ContentBlock';

interface ValidArea {
    minX: number,
    minY: number,
    maxX: number,
    maxY: number
}

interface Props {
    shapeProps: ItemConfig
    isSelected: Boolean
    minWidth?: number
    minHeight?: number
    horizontalResizeOnly: boolean
    liveResize: boolean
    fixedAspect: boolean
    validArea: ValidArea
    onSelect: ActionP0
    onChange: ActionP1<Konva.ImageConfig>
}

export default function InteractableImage ({ shapeProps, isSelected, minWidth = 5, minHeight = minWidth, horizontalResizeOnly, liveResize, fixedAspect, validArea, onSelect, onChange }: Props) {
    const shapeRef = useRef<Konva.Rect>(null);
    const imageRef = useRef<Konva.Image>(null);
    const trRef = useRef<Konva.Transformer>(null);

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current!.nodes([shapeRef.current!]);
            trRef.current!.getLayer()!.batchDraw();
        }
    }, [isSelected]);

    const transformedShapeProps = () => {
        return {
            ...shapeProps,
            x: shapeProps.x - shapeProps.marginX,
            y: shapeProps.y - shapeProps.marginY//,
            // width: shapeProps.width + (shapeProps.marginX * 2),
            // height: shapeProps.height + (shapeProps.marginY * 2)
        };
    };

    const fitInArea = (target: Shape | Stage) => {
        const clamp = (num: number, min: number, max: number) => Math.max(Math.min(num, max), min);

        const x = target.x();
        const y = target.y();
        const width = target.width() * target.scaleX();
        const height = target.height() * target.scaleY();

        const maxX = (validArea.maxX - validArea.minX) / target.width();
        const maxY = (validArea.maxY - validArea.minY) / target.height();

        const clampedX = clamp(target.scaleX(), 0, maxX);
        const clampedY = clamp(target.scaleY(), 0, maxY);
        const minClamped = Math.min(clampedX, clampedY);

        target.scale(fixedAspect ? { x: minClamped, y: minClamped } : { x: clampedX, y: clampedY });
        target.x(clamp(x, validArea.minX, validArea.maxX - width));
        target.y(clamp(y, validArea.minY, validArea.maxY - height));
    };

    return (
        <>
            <Image
                {...transformedShapeProps()}
                ref={imageRef} />
            <Rect
                ref={shapeRef}
                fill={'rgba(0, 0, 0, 0)'}
                onClick={onSelect}
                onTap={onSelect}
                onDragStart={onSelect}
                onTransformStart={onSelect}
                {...shapeProps}
                draggable
                onDragMove={(e) => {
                    fitInArea(e.target);
                    const image = imageRef.current;
                    if (image) {
                        image.x(e.target.x() - shapeProps.marginX);
                        image.y(e.target.y() - shapeProps.marginY);
                    }
                }}
                onTransform={(e) => {
                    fitInArea(e.target);
                    const image = imageRef.current;
                    if (image && liveResize) {
                        image.x(e.target.x() - shapeProps.marginX);
                        image.y(e.target.y() - shapeProps.marginY);
                        image.width(e.target.width() * e.target.scaleX()/* + shapeProps.marginX * 2 */);
                        image.height(e.target.height() * e.target.scaleY()/* + shapeProps.marginY * 2 */);
                    }
                }}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node!.scaleX();
                    const scaleY = node!.scaleY();

                    // we will reset it back
                    node!.scaleX(1);
                    node!.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node!.x(),
                        y: node!.y(),
                        // set minimal value
                        width: Math.max(minWidth, node!.width() * scaleX),
                        height: Math.max(minHeight, node!.height() * scaleY)
                    });
                }} />
            {isSelected && (
                <Transformer
                    rotateEnabled={false}
                    enabledAnchors={horizontalResizeOnly
                        ? ['middle-right', 'middle-left']
                        : ['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < minWidth || newBox.height < minHeight) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
}

import { useAppSelector } from 'hooks';

interface Props {
    children: any
    [key: string]: any
}

export default function LoadingCover ({ children, ...other }: Props): JSX.Element {
    const { isLoading } = useAppSelector(state => state.ui);
    
    return <div {...{ ...other, className: undefined }} className={`${other.className || ''} position-relative`} >
        {children}
        {
            (isLoading) &&
            <div style={{
                inset: 0,
                position: 'absolute',
                background: '#ffffffaa',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            >
                {isLoading && <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem' }}>
                    <span className="sr-only">Loading...</span>
                </div>}
            </div>
        }
    </div >;
}

import { ActionP0, ActionP1 } from 'helpers/functionTypes';
import { getJoiValidationSchema, getJoiResolver } from 'helpers/validation';
import Joi from 'joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
    initialNote: string
    completeTrigger: ActionP1<ActionP0>
    completeCallback: ActionP1<string>
}

const schema = getJoiValidationSchema({
    note: Joi.string().allow('')
});

interface FormFields {
    note: string
}

export default function Notes ({ initialNote, completeTrigger, completeCallback }: Props) {
    const { watch, register, handleSubmit, formState: { errors } } = useForm<FormFields>({
        defaultValues: { note: initialNote },
        resolver: getJoiResolver(schema)
    });
    const noteContent = watch('note');

    useEffect(() => {
        const onSubmit = (data: FormFields) => {
            completeCallback(data.note);
        };

        completeTrigger(() => {
            handleSubmit(onSubmit)();
        });
    });

    return (
        <form>
            <div className="form-group">
                <textarea
                    className={`form-control mh-100 ${(errors.note) ? 'is-invalid' : ''}`}
                    rows={15}
                    {...register('note')}>
                </textarea>
                <div className="d-flex flex-row justify-content-between mt-2">
                    <div>{errors.note && <div className="d-inline invalid-feedback">{errors.note.message}</div>}</div>
                    <div>500/{noteContent.length}</div>
                </div>
            </div>
        </form >
    );
}

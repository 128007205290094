import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'helpers/thunkActionTypes';
import ContentSize from 'models/ContentSize';
import { client } from 'api';
import { toContentSize } from 'components/quoteWizard/redux/helpers';

export interface StationeryChoiceSectionState {
    isLoading: boolean
    isValidating: boolean
    itemsToValidateCount: number
    validatedItemsCount: number
    error: string | null
    data: ContentSize[] | null
}

const initialState: StationeryChoiceSectionState = {
    isLoading: false,
    isValidating: false,
    itemsToValidateCount: 0,
    validatedItemsCount: 0,
    error: null,
    data: null
};

export const stationeryChoiceSectionSlice = createSlice({
    name: 'stationeryChoiceSection',
    initialState,
    reducers: {
        reset: (state) => {
            state.data = null;
        },
        setLoading: (state) => {
            state.isLoading = true;
            state.isValidating = false;
            state.error = null;
        },
        setValidating: (state, action: PayloadAction<number>) => {
            state.isLoading = false;
            state.isValidating = true;
            state.error = null;
            state.itemsToValidateCount = action.payload;
            state.validatedItemsCount = 0;
        },
        updateValidatingState: (state, action: PayloadAction<number>) => {
            state.validatedItemsCount = action.payload;
        },
        setCompleted: (state, action: PayloadAction<{ data: ContentSize[], error: string | null }>) => {
            state.isLoading = false;
            state.isValidating = false;
            state.data = action.payload.data;
            state.error = action.payload.error;
        },
        setFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.isValidating = false;
            state.error = action.payload;
        }
    }
});

export const initialize = (content: string): AppThunk => async (dispatch, getState) => {
    const actions = stationeryChoiceSectionSlice.actions;

    dispatch(actions.setLoading());

    let allSizes: ContentSize[];

    try {
        const products = await client().product_GetAll(false);
        const rawSizes = (await client().product_GetProductSizes())
            .filter(size => products.find(product => product.productSizeId === size.id));

        allSizes = rawSizes.reduce<ContentSize[]>((container, current) => {
            if (current.isOrientable) {
                container.push(toContentSize(current, false));
                container.push(toContentSize(current, true));
                return container;
            } else {
                container.push(toContentSize(current, null));
                return container;
            }
        }, []);
    } catch (error) {
        dispatch(actions.setFailed(error.message));
        return;
    }

    dispatch(actions.setValidating(allSizes.length));

    const finalSizes = [] as ContentSize[];
    let hadFailedRequests = false;

    for (let i = 0; i < allSizes.length; i++) {
        const size = allSizes[i];
        let isValid = false;
        try {
            const response = await client().render_Post2({
                width: size.width,
                height: size.height,
                marginX: size.marginX,
                marginY: size.marginY,
                content: content
            });
            isValid = (response.isWithinBounds || false) && !response.errorMessage;
        } catch (error) {
            hadFailedRequests = true;
        }

        if (isValid) {
            finalSizes.push(allSizes[i]);
        }

        dispatch(actions.updateValidatingState(i + 1));
    }

    const error = hadFailedRequests 
        ? 'Some size validations have failed. Please consider to try again'
        : null;
    dispatch(actions.setCompleted({ data: finalSizes, error: error }));
};

export const { reset } = stationeryChoiceSectionSlice.actions;

export default stationeryChoiceSectionSlice.reducer;

import OptionalLink from 'components/elements/OptionalLink';
import { useAppDispatch, useAppSelector } from 'hooks';
import { QuoteDetailsModel } from './model';
import style from './QuoteDetails.module.css';
import DPD from 'res/img/branding/DPD.svg';
import RoyalMail from 'res/img/branding/Royal_Mail.svg';
import { downloadPDF, downloadQuoteTemplate } from './quoteDetailsSlice';
import ErrorBlock from 'components/elements/ErrorBlock';

interface Props {
    quote: QuoteDetailsModel
    isReadOnly: boolean
    isDownloadReady: boolean
    hasProducts: boolean
}

export default function Header ({ quote, isReadOnly, isDownloadReady, hasProducts }: Props) {
    const { errorMessage, errorContent } = useAppSelector(state => state.ui);
    const dispatch = useAppDispatch();

    const editProspectLink = (quoteId: number) => `/quotes/${quoteId}/prospect`;

    return (<>
        <div className="row mb-3 mb-xl-3" >
            <div className="col-auto d-flex align-items-center">
                <span className="mr-2 pr-1">
                    {quote.deliveryType === 'MailOut'
                        ? <img src={RoyalMail} alt="Mail Out" style={{ height: 32, objectFit: 'contain' }} />
                        : <img src={DPD} alt="Return to Me" style={{ height: 24, objectFit: 'contain' }} />
                    }
                </span>
                <h4 className='mb-0'>{quote.quoteNo}</h4>
                <button onClick={() => isDownloadReady && dispatch(downloadPDF())} disabled={!isDownloadReady || !hasProducts} className={`btn px-2 py-1 mx-2 ${style.printButton}`}><i className="fas fa-print fa-sm"></i></button>
                <OptionalLink link={editProspectLink(quote.id)} isEnabled={!isReadOnly}>
                    <h4 className={`mb-0 ${style.editAction} ${!isReadOnly ? style.enabled : ''}`}>
                        { [quote.companyName, `${quote.firstName} ${quote.lastName}`, quote.name].filter(x => x).join(' | ') }
                        &nbsp;&nbsp;
                        <i className="fas fa-pencil-alt">&nbsp;&nbsp;</i>
                    </h4>
                </OptionalLink>
                <span className={`mb-0 ${style.editable}`}><a className="h4" href={`mailto://${quote.email}`}>({quote.email})</a></span>
                <h4 className='m-0'>
                    <span className={`mx-2 my-0 ${quote.statusColor ? 'badge badge-pill badge-' + quote.statusColor : 'font-italic small'}`}>
                        {quote.statusColor ? quote.status : `(${quote.status})`}
                    </span>
                </h4>
                <button onClick={() => dispatch(downloadQuoteTemplate())} className={`btn px-2 py-1 mx-2 ${style.printButton}`}><i className="fas fa-file-archive fa-md"></i></button>
            </div>
        </div>
        {!isDownloadReady && <div className="row">
            <div className="col-12 mb-0">
                <div className="alert alert-warning alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="alert-message">
                        Proof not ready yet.
                    </div>
                </div>
            </div>
        </div>}
        {errorMessage && <div className="row mb-2">
            <div className="col-12">
                <ErrorBlock errorMessage={errorMessage} errorObject={errorContent} />
            </div>
        </div>}
    </>
    );
}

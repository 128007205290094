import { AnyAction, configureStore, EnhancedStore, combineReducers, ThunkDispatch } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import uiReducer, { UIState } from './ui';
import userReducer, { UserState } from './user';
import quoteReducer, { QuoteState } from './quote'; 
import quoteWizardReducer, { QuoteWizardState } from 'components/quoteWizard/redux';
import sendingChoiceSectionReducer, { SendingChoiceSectionState } from 'components/quoteWizard/sections/SendingChoice/sendingChoiceSlice';
import editContentSectionReducer, { EditContentSectionState } from 'components/quoteWizard/sections/EditContent/editContentSlice';
import contentPreviewSectionReducer, { ContentPreviewSectionState } from 'components/quoteWizard/sections/ContentPreview/contentPreviewSlice';
import stationeryChoiceSectionReducer, { StationeryChoiceSectionState } from 'components/quoteWizard/sections/StationeryChoice/stationeryChoiceSlice';
import productChoiceSectionReducer, { ProductChoiceSectionState } from 'components/quoteWizard/sections/ProductChoice/productChoiceSlice';
import additionalServicesSectionReducer, { AdditionalServicesSectionState } from 'components/quoteWizard/sections/AdditionalServices/additionalServicesSlice';
import selectEnvelopeSectionReducer, { SelectEnvelopeSectionState } from 'components/quoteWizard/sections/EnvelopeChoice/envelopeChoiceSlice';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import quoteDetailsReducer, { QuoteDetailsState } from 'components/pages/QuoteDetails/quoteDetailsSlice';
import quotesListReducer, { QuotesListState } from 'components/pages/QuotesList/quotesListSlice';
import acccountsListReducer, { AccountsListState } from '../components/pages/AccountsList/accountsListSlice';
import accountEditReducer, { AccountEditState } from 'components/pages/AccountEdit/accountEditSlice';

export interface RootStore {
    router: RouterState,
    ui: UIState
    user: UserState
    quote: QuoteState

    quoteWizard: QuoteWizardState
    sendingChoice: SendingChoiceSectionState,
    editContentSection: EditContentSectionState
    contentPreviewSection: ContentPreviewSectionState
    stationeryChoiceSection: StationeryChoiceSectionState
    productChoiceSection: ProductChoiceSectionState
    additionalServicesSection: AdditionalServicesSectionState
    selectEnvelopeSection: SelectEnvelopeSectionState
    quotesList: QuotesListState
    quoteDetails: QuoteDetailsState,
    accountsList: AccountsListState,
    accountEdit: AccountEditState
}

export interface RootStoreDispatch extends ThunkDispatch<RootStore, any, AnyAction> { }

export const history = createBrowserHistory();

export default function configureAppStore (initialState?: {}): EnhancedStore {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    return configureStore({
        reducer: combineReducers<RootStore>({
            router: connectRouter(history),
            ui: uiReducer,
            user: userReducer,
            quote: quoteReducer,
            quoteWizard: quoteWizardReducer,
            sendingChoice: sendingChoiceSectionReducer,
            editContentSection: editContentSectionReducer,
            contentPreviewSection: contentPreviewSectionReducer,
            stationeryChoiceSection: stationeryChoiceSectionReducer,
            productChoiceSection: productChoiceSectionReducer,
            additionalServicesSection: additionalServicesSectionReducer,
            selectEnvelopeSection: selectEnvelopeSectionReducer, 
            quotesList: quotesListReducer,
            quoteDetails: quoteDetailsReducer,
            accountsList: acccountsListReducer,
            accountEdit: accountEditReducer
        }),
        middleware
    });
}

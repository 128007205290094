import { ActionP0, ActionP2 } from 'helpers/functionTypes';
import { Alignment } from './models';

interface Props {
    show: boolean
    allowRemove: boolean
    onAligned: ActionP2<Alignment, Alignment>
    onRemove: ActionP0
}

export default function AlignmentBlock ({ show, allowRemove, onAligned, onRemove }: Props) {
    return (
        <div className={`text-center ${!show ? 'd-none' : ''}`} data-position-place data-id>
            <h4 className="h4 mt-2">Please choose position</h4>
            <div className="d-flex flex-column">
                <div className="btn-group justify-content-between" role="group" aria-label="Show">
                    <button type="button" onClick={() => onAligned('start', 'start')} className="btn btn-outline-secondary m-1 p-2 w-25">↖</button>
                    <button type="button" onClick={() => onAligned('center', 'start')} className="btn btn-outline-secondary m-1 p-2 w-25">↑</button>
                    <button type="button" onClick={() => onAligned('end', 'start')} className="btn btn-outline-secondary m-1 p-2 w-25">↗</button>
                </div>
                <div className="btn-group" role="group" aria-label="Show">
                    <button type="button" onClick={() => onAligned('start', 'center')} className="btn btn-outline-secondary m-1 p-2 w-25">←</button>
                    <button type="button" onClick={() => onAligned('center', 'center')} className="btn btn-outline-secondary m-1 p-2 w-25">⬥</button>
                    <button type="button" onClick={() => onAligned('end', 'center')} className="btn btn-outline-secondary m-1 p-2 w-25">→</button>
                </div>
                <div className="btn-group" role="group" aria-label="Show">
                    <button type="button" onClick={() => onAligned('start', 'end')} className="btn btn-outline-secondary m-1 p-2 w-25">↙</button>
                    <button type="button" onClick={() => onAligned('center', 'end')} className="btn btn-outline-secondary m-1 p-2 w-25">↓</button>
                    <button type="button" onClick={() => onAligned('end', 'end')} className="btn btn-outline-secondary m-1 p-2 w-25">↘</button>
                </div>
            </div>

            {(allowRemove) && <div>
                <button id="remove-file" type="button" onClick={() => onRemove()} className="btn btn-outline-danger m-2">Remove image</button>
            </div>}
        </div>
    );
};

import { ActionP0, ActionP1 } from 'helpers/functionTypes';
import { useEffect } from 'react';
import { initialize, reset, selectService, unselectService, updateDesription } from './additionalServicesSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ServiceData } from 'models/ServiceData';
import { PricingLevelModel, ServiceLineModel } from 'api/api';

interface Props {
    selectedServices: ServiceLineModel[],
    priceLevel: PricingLevelModel | undefined,
    quantity: number
    completeTrigger: ActionP1<ActionP0>
    completeCallback: ActionP1<ServiceData[]>
}

export default function AdditionalServices ({ selectedServices, priceLevel, quantity, completeTrigger, completeCallback }: Props) {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(state => state.additionalServicesSection);

    useEffect(() => {
        dispatch(initialize(selectedServices, priceLevel, quantity));
        return () => {
            dispatch(reset());
        };
    }, []);

    useEffect(() => {
        completeTrigger(() => {
            if (areInputValid()) {
                completeCallback((data || []).filter(s => s.isSelected));
            }
        });
    });

    function areInputValid () {
        return !(data || []).find(s => s.isSelected && !(s.description.trim()));
    }

    return (
        <TwoColumnList>
            {(data || []).map((service, index) => (
                <div key={service.id} className="card">
                    <div className="card-body">
                        <div className="d-flex flex-wrap justify-content-between">
                            <a className="custom-control custom-checkbox pr-2"
                                onClick={() => !service.isDisabled && 
                                    (service.isSelected
                                        ? dispatch(unselectService(index))
                                        : dispatch(selectService(index)))}>
                                <input type="checkbox"
                                    className="custom-control-input"
                                    checked={service.isSelected}
                                    onChange={() => {}}
                                    disabled={service.isDisabled} />
                                <span className="card-title custom-control-label checkbox-valigned">{service.name}</span>
                            </a>
                            {service.price &&
                                <span>
                                    {service.price} / <small>{service.isGlobal ? 'per quote' : 'per unit'}</small>
                                </span>
                            }
                        </div>
                        {service.isSelected && <div className="pl-4 pr-0 pt-3">
                            <textarea
                                className={`form-control ${!service.description.trim() ? 'is-invalid' : ''}`}
                                placeholder="Description"
                                defaultValue={service.description}
                                onChange={input => dispatch(updateDesription(index, input.target.value))}
                                rows={2}>
                            </textarea>
                            {(!service.description.trim()) && <div className="invalid-feedback">
                                Description cannot be empty
                            </div>}
                        </div>}

                    </div>
                </div>
            ))}
        </TwoColumnList>
    );
}

function TwoColumnList (props: { children: any[] }) {
    return <div className="row">
        <div className="col-6">
            <div className="row">
                {props.children
                    .filter((_, i) => i % 2 === 0)
                    .map((child) => (<div key={child.key} className="col-12">{child}</div>))}
            </div>
        </div>
        <div className="col-6">
            <div className="row">
                {props.children
                    .filter((_, i) => i % 2 !== 0)
                    .map((child) => (<div key={child.key} className="col-12">{child}</div>))}
            </div>
        </div>
    </div>;
}

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionP0, ActionP1 } from 'helpers/functionTypes';
import { getJoiValidationSchema, getJoiResolver } from 'helpers/validation';
import Joi from 'joi';

interface Props {
    initialQuantity: number | undefined
    completeTrigger: ActionP1<ActionP0>
    completeCallback: ActionP1<number>
}

const schema = getJoiValidationSchema({
    quantity: Joi.number().required().min(1)
});

interface FormFields {
    quantity: number | undefined
}

export default function Quantity ({ initialQuantity, completeTrigger, completeCallback }: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<FormFields>({
        defaultValues: { quantity: initialQuantity }, 
        resolver: getJoiResolver(schema)
    });

    useEffect(() => {
        const onSubmit = (data: FormFields) => {
            completeCallback(data.quantity!);
        };

        completeTrigger(() => {
            handleSubmit(onSubmit)();
        });
    });

    return (
        <form>
            <div className="form-group">
                <label className="form-label">How many do you need?</label>
                <input type="number" min="1" className={`form-control ${(errors.quantity) ? 'is-invalid' : ''}`} placeholder="0" {...register('quantity')} />
                {errors.quantity && <div className="invalid-feedback">{errors.quantity.message}</div>}
            </div>
        </form>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'api';
import { DeliveryType, ProductLineModel, ProductModel, QuoteModel } from 'api/api';
import { AppThunk } from 'helpers/thunkActionTypes';
import { FormFields as ProspectFormFields } from 'components/quoteWizard/sections/Prospect';
import { syncQuote } from './sync';
import { FunctionP1 } from 'helpers/functionTypes';
import { ServiceData } from 'models/ServiceData';
import { isNumber } from 'helpers/general';
import { batch } from 'react-redux';
import { clearError, hideLoading, showError, showLoading } from 'redux/ui';

export interface QuoteState {
    products: ProductModel[]
    quote: QuoteModel
}

export interface Result {
    isSuccess: boolean,
    data: QuoteModel
}

const initialState: QuoteState = {
    products: [],
    quote: {}
};

export const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: {
        setQuote: (state, action: PayloadAction<QuoteModel>) => {
            state.quote = action.payload;
        },
        setProducts: (state, action: PayloadAction<ProductModel[]>) => {
            state.products = action.payload;
        },
        reset: state => initialState
    }
});

export const loadQuote = (quoteId?: number, products?: ProductModel[]): AppThunk<Promise<QuoteModel>> => async (dispatch, getState) => {
    const actions = quoteSlice.actions;

    let quote: QuoteModel | null = null;
    if (quoteId) {
        quote = await client().quote_Get(quoteId);
    }

    if (!products || !products.length) {
        products = await client().product_GetAll();
    }

    batch(() => {
        if (quote) {
            dispatch(actions.setQuote(quote));
        }
        dispatch(actions.setProducts(products!));
    });

    return { ...quote };
};

const setProspectFieds = (quote: QuoteModel, fields: ProspectFormFields): QuoteModel => {
    quote.companyName = fields.companyName;
    quote.firstName = fields.firstName;
    quote.lastName = fields.lastName;
    quote.email = fields.email;
    quote.name = fields.name;
    // a bit hacky, but should allow to update price level value without introducing new redux state param,
    // quote price level will be replaced with actual value on next synchronization
    quote.priceLevel = { id: fields.priceLevel };

    return quote;
};

export const updateQuoteProspect = (fields: ProspectFormFields): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => setProspectFieds(quote, fields)));
};

export const updateQuoteDeliveryType = (type: DeliveryType, fields?: ProspectFormFields): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => {
        if (fields) {
            quote = setProspectFieds(quote, fields);
        }
        quote.deliveryType = type;
        return quote;
    }));
};

export const updateQuoteQuantity = (quantity: number): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => {
        quote.quantity = quantity;
        return quote;
    }));
};

export const updateProductLine = (productLine: ProductLineModel, index: number | null): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => {
        const products = [...(quote.productLines || [])];
        if (isNumber(index) && products.length > 0) {
            const old = products[index!];
            products[index!] = productLine;
            products[index!].id = old.id;
        } else {
            products.push(productLine);
        }
        quote.productLines = products;
        return quote;
    }));
};

export const removeProductLine = (productLineIndex: number): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => {
        const products = [...(quote.productLines || [])];
        products?.splice(productLineIndex, 1);
        quote.productLines = products;
        return quote;
    }));
};

export const updateQuoteAdditionalServices = (additionalServices: ServiceData[]): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => {
        quote.serviceLines = additionalServices.map(service => {
            return {
                id: service.attachedServiceId,
                serviceId: service.id,
                description: service.description,
                qty: 1
            };
        });
        return quote;
    }));
};

export const updateQuoteNotes = (notes: string | undefined): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return await dispatch(updateQuoteData(quote => {
        quote.notes = notes;
        return quote;
    }));
};

const updateQuoteData = (callback: FunctionP1<QuoteModel, QuoteModel>): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    const quote = { ...getState().quote.quote };
    const products = getState().quote.products;
    const newQuote = callback(quote);
    const isSuccess = await dispatch(syncQuote(newQuote, products));

    return {
        isSuccess,
        data: { ...getState().quote.quote }
    };
};

export const markQuoteAsPrepared = (): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return dispatch(markQuote(async (quoteId) => {
        return await client().quote_Prepare({ id: quoteId });
    }));
};

export const markQuoteAsSent = (): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return dispatch(markQuote(async (quoteId) => {
        return await client().quote_Sent({ id: quoteId });
    }));
};

export const markQuoteAsOrdered = (): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    return dispatch(markQuote(async (quoteId) => {
        return await client().quote_Ordered({ id: quoteId });
    }));
};

const markQuote = (markAction: (quoteId: number) => Promise<QuoteModel>): AppThunk<Promise<Result>> => async (dispatch, getState) => {
    const quote = { ...getState().quote.quote };

    try {
        dispatch(showLoading());
        dispatch(clearError());

        const newQuote = await markAction(quote.id!);

        dispatch(hideLoading());

        return {
            isSuccess: true,
            data: newQuote
        };
    } catch (e) {
        dispatch(hideLoading());
        dispatch(showError(e));

        return {
            isSuccess: false,
            data: quote
        };
    }
};

export const { reset } = quoteSlice.actions;

export default quoteSlice.reducer;
